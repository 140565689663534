<div class="col-1of1 compact">

    <div class="uc-card-actions">
        <button (click)="close()" type="button" class="uf-action tertiary">
            <uf-icon name="close" />
        </button>
    </div>

    <h3>Problem?</h3>
    <p>If you are having difficulties please contact your administrator.</p>

    <uf-description-list *ngIf="contactDetails.length" [items]="contactDetails" />

    <br />


</div>