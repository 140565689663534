import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { DataPropertyInfoService, ModalService, UfControlArray, UfControlGroup } from '@unifii/library/common';
import { Schema, SchemaField, SchemaTransition } from '@unifii/sdk';

import { DefinitionInfo } from 'client';
import { transitionName } from 'helpers/transition-helper-functions';
import { DialogsService } from 'services/dialogs.service';

import { FormEditorCache } from '../form-editor-cache';
import { FieldMappingControlKeys, TransitionTargetControlKeys } from '../form-editor-control-keys';
import { FormEditorFormCtrl } from '../form-editor-form-ctrl';
import { FormEditorFunctions } from '../form-editor-functions';

import { FieldMapperComponent, FieldMappingData } from './field-mapper.component';

interface FieldMappingControlValue {
    parentField: SchemaField;
    childField: SchemaField;
}

@Component({
    selector: 'uc-form-field-transition-target',
    templateUrl: './form-field-transition-target.html',
})
export class FormFieldTransitionTargetComponent implements OnInit {

    @Input({ required: true }) control: UfControlGroup;
    @Input({ required: true }) forms: DefinitionInfo[];
    @Input({ required: true }) parentSchema: Schema | null; // for data mapping

    @Output() remove = new EventEmitter<void>();

    protected readonly targetKeys = TransitionTargetControlKeys;
    protected readonly fieldMappingKeys = FieldMappingControlKeys;
    protected readonly transitionName = transitionName;

    protected fieldMappings: UfControlArray;
    protected noParentSchemaMessage: string;
    protected formsResult: DefinitionInfo[] = [];
    protected transitionsResult: SchemaTransition[] = [];
    protected parentFields: SchemaField[];
    protected childFields: SchemaField[];

    private dataPropertyInfoService = inject(DataPropertyInfoService);
    private cache = inject(FormEditorCache);
    private formCtrl = inject(FormEditorFormCtrl);
    private modalService = inject(ModalService);
    private dialogs = inject(DialogsService);

    ngOnInit() {
        if (!this.parentSchema) {
            this.noParentSchemaMessage = 'Schema for this form not found, please save your form before configuring the workflow';

            return;
        }

        this.fieldMappings = this.control.get(TransitionTargetControlKeys.FieldMappings) as UfControlArray;
        this.parentFields = FormEditorFunctions.getTransitionMappableFields(this.parentSchema, this.dataPropertyInfoService, true);
        void this.updateChildFormResults();
    }

    protected async onTargetFormChange() {
        this.removeTransition();
        await this.updateChildFormResults();

        while (this.fieldMappings.controls.length) {
            void this.removeMappedFields(0);
        }
    }

    protected findForms(query: string) {

        if (!query.trim().length) {
            this.formsResult = [...this.forms];
        } else {
            this.formsResult = this.forms.filter((info) => info.name.toLocaleLowerCase().includes(query.toLocaleLowerCase()));
        }
    }

    protected async onRemove(event: MouseEvent) {
        event.stopPropagation();
        if (!await this.dialogs.confirmDelete()) {
            return;
        }
        this.remove.next();
    }

    // Add/Update field mapping form & patch back result
    protected async mapFields(control?: UfControlGroup) {
        const previousValue: FieldMappingControlValue = control?.value;
        const mappedFields = (this.fieldMappings.value as Pick<FieldMappingControlValue, 'childField'>[]) ?? [];
        const selectedIdentifiers = mappedFields
            .map((v) => v.childField.identifier)
            // Remove its own value so it can be reselected
            .filter((i) => previousValue?.childField?.identifier !== i);

        let newControl: UfControlGroup | null = null;

        if (!control) {
            newControl = this.formCtrl.buildFieldMapping({});
            this.fieldMappings.push(newControl);
            this.fieldMappings.updateDependencies();
        }

        const data: FieldMappingData = {
            sourceFields: this.parentFields,
            targetFields: this.childFields.filter((f) => !selectedIdentifiers.includes(f.identifier)),
            mappingGroup: {
                control: control ?? newControl as UfControlGroup,
                sourceKey: FieldMappingControlKeys.ParentField,
                targetKey: FieldMappingControlKeys.ChildField,
            },
            sourceFieldLabel: 'Field in Primary Form',
            targetFieldLabel: 'Field in Triggered Form',
        };

        const result = await this.modalService.openMedium(FieldMapperComponent, data);

        // The dialog apply the changes meanwhile it is open, hence on confirm return
        if (result) {
            return;
        }

        // Otherwise re-apply the old value or remove the new added control
        if (control) {
            control.patchValue(previousValue);
        } else {
            this.fieldMappings.removeAt(this.fieldMappings.controls.length - 1);
        }
    }

    protected async removeMappedFields(i: number, event?: MouseEvent) {

        event?.stopPropagation();

        if (event && !await this.dialogs.confirmDelete()) {
            return;
        }
        this.fieldMappings.removeAt(i);
    }

    protected namePropertyFunc = (field: SchemaField) => `${field.shortLabel ?? field.label} (${field.identifier})`;

    private removeTransition() {
        this.control.get(TransitionTargetControlKeys.Transition)?.setValue(null, { onlySelf: true, emitEvent: false });
    }

    private async updateChildFormResults() {
        const form = this.control.get(TransitionTargetControlKeys.Form)?.value as DefinitionInfo | undefined;
        const childSchema = await this.cache.getSchema(form?.bucket);

        this.transitionsResult = childSchema?.transitions ?? [];
        this.childFields = childSchema ? FormEditorFunctions.getTransitionMappableFields(childSchema, this.dataPropertyInfoService, false) : [];
    }

}
