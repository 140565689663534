<div class="row">
    <uf-message *ngIf="noParentSchemaMessage; else ready" class="grow x-small warning" icon="warningSolid">
        <p>{{noParentSchemaMessage}}</p>
    </uf-message>
    <a (click)="onRemove($event)" title="Delete" class="uf-action tertiary">
        <uf-icon name="delete" />
    </a>
</div>

<ng-template #ready>
    <div [formGroup]="control" class="uf-grid grow pad">
        <uf-text [formControlName]="targetKeys.Label" label="Label" class="col-6" />
        <uf-autocomplete [formControlName]="targetKeys.Form" [options]="formsResult"
            (valueChange)="onTargetFormChange()" (searchChange)="findForms($event)" label="Form" placeholder="Form"
            nameProperty="name" class="col-6" />
        <uf-select *ngIf="control.get(targetKeys.Form)?.value != null" [formControlName]="targetKeys.Transition"
            [options]="transitionsResult" [nameProperty]="transitionName" label="Transition"
            placeholder="Select a transition" class="col-12" />
        <div *ngIf="fieldMappings.length" class="col-12">
            <h4>Data Mapping</h4>
            <table class="uf-table inputs accent clickable">
                <thead>
                    <tr>
                        <th>Field in Primary Form</th>
                        <th>Field in Triggered Form</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let fieldMap of fieldMappings.controls | asUfControlsGroups; index as i"
                        (click)="mapFields(fieldMap)" class="body-row">
                        <td>{{ fieldMap.get(fieldMappingKeys.ParentField)?.value
                            | nameProperty:namePropertyFunc }} <span
                                *ngIf="fieldMap.get(fieldMappingKeys.ParentField)?.invalid"
                                class="uf-lozenge error">Invalid</span>
                        </td>
                        <td>{{ fieldMap.get(fieldMappingKeys.ChildField)?.value
                            | nameProperty:namePropertyFunc }} <span
                                *ngIf="fieldMap.get(fieldMappingKeys.ChildField)?.invalid"
                                class="uf-lozenge error">Invalid</span>
                        </td>
                        <td class="input-cell">
                            <button (click)="removeMappedFields(i, $event)" type="button" title="Delete"
                                class="uf-action tertiary right gap-sm-right">
                                <uf-icon name="delete" />
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-12">
            <button (click)="mapFields()" type="button" class="uf-button right">
                Add Data
            </button>
        </div>
    </div>
</ng-template>