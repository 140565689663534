import { SortStatus, TableDataSource, TableDataSourceQueryOptions } from '@unifii/library/common';
import { RequestAnalyticsOrigin } from '@unifii/sdk';

import { CompoundInfo, UcCollectionTranslation } from 'client';

export class LanguageCollectionCompoundsDataSource extends TableDataSource<CompoundInfo> {

    constructor(
        private ucCollectionTranslation: UcCollectionTranslation,
        private search?: string,
        private sort?: SortStatus,
        private status?: string,
    ) {
        super();
    }

    get filtered() {
        return (this.search && this.search.trim().length > 0) || this.status != null;
    }

    get sorted() {
        return this.sort != null;
    }

    load(options?: TableDataSourceQueryOptions) {
        this.ucCollectionTranslation.get({
            params: {
                q: this.search,
                sort: this.sort?.toString(),
                status: this.status,
                offset: options?.offset,
                limit: options?.limit,
            },
            analytics: { origin: RequestAnalyticsOrigin.Table },
        }).then(
            (compounds) => this.stream.next({ data: compounds }),
            (error) => this.stream.next({ error }),
        );
    }

}
