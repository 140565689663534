import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { DataPropertyDescriptor, DescriptionListItem, FilterEntry, FilterValue, ModalService, ToastService, UfControl, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';
import { stringsCaseInsensitiveLocalCompare } from '@unifii/library/smart-forms';
import { TableSourceType } from '@unifii/sdk';
import { Subscription, merge } from 'rxjs';

import { TableInfo, UcProject, UcRoles, UcTable } from 'client';
import { EditData, SaveOption, SaveOptionType } from 'components';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { ArrayHelper, ContentDefinitionIdentifierValidators, IdentifierFunctions } from 'helpers/helpers';
import { TableSourceTypeLabelPipe } from 'pipes/table-source-type-label.pipe';
import { ContextService } from 'services/context.service';

import { TableComponent } from '../table.component';

@Component({
    templateUrl: 'table-configuration.html',
    styleUrls: ['table-configuration.less'],
})
export class TableConfigurationComponent implements OnInit, AfterViewInit, OnDestroy, EditData {

    protected readonly sourceTypes = TableSourceType;
    protected readonly identifierMaxLength = IdentifierFunctions.WARNING_IDENTIFIER_MAX_LENGTH;

    // Controls
    protected cRoot = new UfControlGroup({});
    protected cExport: UfControl;
    protected cTitle: UfControl;
    protected cIdentifier: UfControl;
    protected cDescription: UfControl;
    protected cFilter: UfControlGroup;
    protected cAdvancedFilter: UfControl;
    protected cHelpText: UfControl;
    protected cExportRoles: UfControl;
    protected cShowCount: UfControl;

    protected ready: boolean;
    protected table: UcTable;

    // keyValue infos
    protected detailsKeyValue: DescriptionListItem[];

    protected availableColumns: DataPropertyDescriptor[] = [];
    protected sortableColumns: DataPropertyDescriptor[] = [];
    protected availableVisibleFilters: DataPropertyDescriptor[];
    protected availableHiddenFilters: DataPropertyDescriptor[];

    protected isAdvancedFilter: boolean;
    protected roleResults: string[] = [];
    protected export: boolean;
    protected filteredRoles: string[];

    private _edited: boolean;
    private subscriptions = new Subscription();
    private roles: string[];

    constructor(
        public context: ContextService,
        private tableComponent: TableComponent,
        private project: UcProject,
        private toastService: ToastService,
        private modalService: ModalService,
        private tableSourceTypeLabelPipe: TableSourceTypeLabelPipe,
        private ucRoles: UcRoles,
        private builderHeaderService: BuilderHeaderService,
        @Inject(TableContainerManager) private tableManager: TableContainerManager<TableInfo, FilterValue, FilterEntry>,
        private router: Router,
        private route: ActivatedRoute,
    ) { }

    get edited(): boolean {
        return this._edited;
    }

    set edited(v: boolean) {
        this._edited = v;
        this.tableComponent.edited = v;
    }

    protected get showRecordCount() {
        return this.context.tenantSettings?.features.indexing && this.table.sourceType === TableSourceType.Bucket;
    }

    ngOnInit() {
        this.table = Object.assign({}, this.tableComponent.info.table);

        const descriptor = this.tableComponent.info.dataDescriptor;

        this.availableColumns = descriptor.propertyDescriptors.filter((pd) => pd.asDisplay);
        this.sortableColumns = descriptor.propertyDescriptors.filter((pd) => pd.asSort);
        this.availableVisibleFilters = descriptor.propertyDescriptors.filter((pd) => pd.asInputFilter);
        this.availableHiddenFilters = descriptor.propertyDescriptors.filter((pd) => pd.asStaticFilter);

        this.setup();
    }

    ngAfterViewInit() {
        this.subscriptions.add(
            merge(
                this.cRoot.valueChanges,
                this.cHelpText.valueChanges,
                this.cShowCount.valueChanges,
                this.cExportRoles.valueChanges,
            ).subscribe(() => (this.edited = true)),
        );
    }

    ngOnDestroy() {
        this.tableComponent.edited = false;
        this.subscriptions.unsubscribe();
    }

    protected async switchFilter() {

        const prompt = (this.isAdvancedFilter && this.table.advancedFilter) ??
            (!this.isAdvancedFilter && this.table.filter);

        if (prompt) {
            const decision = await this.modalService.openConfirm({
                title: 'Switch Filter Mode',
                message: 'Configuration for the current filter will be lost.',
                confirmLabel: 'Switch',
                cancelLabel: `Don't Switch`,
            });

            if (!decision) {
                return;
            }
        }

        this.edited = true;

        this.isAdvancedFilter = !this.isAdvancedFilter;

        if (this.isAdvancedFilter) {
            delete this.table.advancedFilter;
        } else {
            delete this.table.filter;
        }
    }

    protected filterRoles(query: string | null) {
        this.filteredRoles = ArrayHelper.filterList(this.roles, query ?? undefined);
    }

    private async save(saveOption?: SaveOption) {

        try {

            this.cRoot.updateValueAndValidity();
            this.cRoot.setSubmitted(true);

            if (this.cRoot.invalid) {
                return;
            }

            if (!this.table.defaultSort) {
                delete this.table.defaultSort;
            }

            if (this.table.columns && this.table.columns.length === 0) {
                delete this.table.columns;
            }

            if ((this.table.visibleFilters?.length ?? 0) === 0) {
                delete this.table.visibleFilters;
            }

            if (this.isAdvancedFilter) {
                delete this.table.filter;
            } else {
                delete this.table.advancedFilter;
            }

            this.table.hideExport = !this.export;

            if (this.table.hideExport) {
                delete this.table.exportVisibleTo;
            } else {
                this.table.exportVisibleTo = this.cExportRoles.value;
            }

            if (this.showRecordCount) {
                this.table.showCount = this.cShowCount.value;
            } else {
                delete this.table.showCount;
            }

            // Save
            let updatedTable = await this.project.saveTable(this.table);

            if (this.tableComponent.info.duplicateTableDetails) {
                // Duplicating an existing Table with TableDetail, save also the tableDetail
                await this.project.saveTableDetail(updatedTable.id as string, this.tableComponent.info.duplicateTableDetails);
            }

            if (saveOption?.id === SaveOptionType.Approve && updatedTable.id) {
                updatedTable = await this.project.approveTable(updatedTable.id);
            }

            this.updateTable(updatedTable);
            this.edited = false;
            this.toastService.success('Table saved!');

            if (saveOption && this.builderHeaderService.config.cancelRoute) {
                this.router.navigate(this.builderHeaderService.config.cancelRoute, { relativeTo: this.route });
            }

            if (!saveOption) {
                this.router.navigate(['..', updatedTable.id], { relativeTo: this.route });

                return;
            }

        } catch (e) {
            this.toastService.error(`Error save: ${(e as Error).message}`);
        }
    }

    private setup() {

        this.cExport = new UfControl();
        this.cTitle = new UfControl(ValidatorFunctions.required('A title is required'));
        this.cIdentifier = new UfControl(ContentDefinitionIdentifierValidators);

        if (this.table.lastPublishedAt != null) {
            this.cIdentifier.disable();
        } else {
            if (!this.table.id) {
                this.subscriptions.add(this.cTitle.valueChanges.subscribe((value) => {
                    let identifier = '';

                    if (value != null && value !== '') {
                        identifier = IdentifierFunctions.kebabize(value || '')
                            .substring(0, IdentifierFunctions.IDENTIFIER_MAX_LENGTH);
                    }
                    this.cIdentifier.setValue(identifier, { onlySelf: false, emitEvent: true });
                }));
            } else {
                this.subscriptions.add(this.cIdentifier.valueChanges.subscribe(() => {
                    this.toastService.warning('Editing the identifier may cause problems, because the identifier could be linked elsewhere');
                }));
            }
        }

        this.cDescription = new UfControl();
        this.cFilter = new UfControlGroup({});
        this.cAdvancedFilter = new UfControl();
        this.cExportRoles = new UfControl(undefined, undefined, undefined, this.table.exportVisibleTo?.sort(stringsCaseInsensitiveLocalCompare ));
        this.cShowCount = new UfControl(undefined, undefined, undefined, this.table.showCount);
        this.cHelpText = new UfControl(undefined, undefined, undefined, this.table.help);

        this.subscriptions.add(this.cExport.valueChanges.subscribe((exportValue: boolean) => {
            if (!exportValue) {
                this.cExportRoles.setValue(undefined);
            }
        }));

        this.subscriptions.add(this.builderHeaderService.saveClicked.subscribe((saveOption) => this.save(saveOption)));

        this.cRoot.setControl('export', this.cExport);
        this.cRoot.setControl('title', this.cTitle);
        this.cRoot.setControl('identifier', this.cIdentifier);
        this.cRoot.setControl('description', this.cDescription);
        this.cRoot.setControl('filter', this.cFilter);
        this.cRoot.setControl('advancedFilter', this.cAdvancedFilter);
        this.cRoot.setControl('exportedRoles', this.cExportRoles);
        this.cRoot.setControl('showCount', this.cShowCount);

        this.detailsKeyValue = [{ term: 'Source Type', description: this.tableSourceTypeLabelPipe.transform(this.table.sourceType) }];
        if (this.table.sourceType === TableSourceType.Bucket) {
            this.detailsKeyValue.push({ term: 'Source', description: this.table.source });
        }

        this.isAdvancedFilter = this.table.advancedFilter != null;

        this.export = !this.table.hideExport;

        this.loadRoles();

        this.ready = true;
    }

    private async loadRoles() {
        this.roles = (await this.ucRoles.get(undefined, undefined, { params: { limit: 1000, offset: 0 } })).map((role) => role.name);
    }

    private updateTable(table: UcTable) {
        const isNewTable = table.id == null;

        if (isNewTable) {
            this.tableManager.reload?.next();
        } else {
            this.tableManager.updateItem?.next(table as TableInfo);
        }

        this.tableComponent.updateTable(table);
        this.table.id = table.id;
    }

}
