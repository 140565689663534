import { Injectable } from '@angular/core';
import { DataLoaderFactory, DataPropertyDescriptor, HierarchyUnitProvider, SourceConfig, UfControlArray, UfControlGroup, UfFormBuilder, ValidatorFunctions } from '@unifii/library/common';
import { AstNode, DataSeed, DataSourceType, FieldType, HierarchyUnit, NodeType, OperatorComparison, QueryOperators, UsersClient, Operator as sdkOperator } from '@unifii/sdk';

export enum FilterNodeControlKeys {
    Field = 'field',
    Type = 'type',
    Operator = 'operator',
    ValueType = 'valueType',
    Value = 'value'
}

export interface FilterEditorNode {
    field?: DataPropertyDescriptor;
    type: NodeType;
    operator?: OperatorComparison;
    valueType?: NodeType;
    value?: any;
}

@Injectable()
export class FilterEditorFormCtrl {

    readonly queryComparisonOperators: sdkOperator[] = [
        QueryOperators.Equal,
        QueryOperators.NotEqual,
        QueryOperators.LowerThan,
        QueryOperators.LowerEqual,
        QueryOperators.GreaterThan,
        QueryOperators.GreaterEqual,
        QueryOperators.In,
        QueryOperators.Contains,
        QueryOperators.Descendants,
    ];

    constructor(
        private fb: UfFormBuilder,
        private dataLoaderFactory: DataLoaderFactory,
        private usersClient: UsersClient,
        private hierarchyProvider: HierarchyUnitProvider,
    ) { }

    mapFilterToFilterNodes(filter: AstNode, dataProperties: DataPropertyDescriptor[]): Promise<FilterEditorNode[]> {
        return Promise.all((filter.args ?? []).map((arg) => this.mapAstNodeToFilterNode(arg, dataProperties)));
    }

    async mapAstNodeToFilterNode(ast: AstNode, dataProperties: DataPropertyDescriptor[]): Promise<FilterEditorNode> {

        const leftNode = ast.args?.[0] != null ? ast.args[0] : null;
        const rightNode = ast.args?.[1] != null ? ast.args[1] : null;
        const fieldIdentifier = leftNode?.value as string | undefined;

        // Field
        const matchField = !fieldIdentifier ? undefined : dataProperties.find((dp) => {
            // AstNode for a ZonedDateTime is save with <identifier>.value
            if (dp.type === FieldType.ZonedDateTime) {
                return `${dp.identifier}.value` === fieldIdentifier;
            }

            if (dp.type === FieldType.Hierarchy) {
                return `${dp.identifier}.id` === fieldIdentifier;
            }

            // Exclude the 'artificial' datasource like _lastModifiedBy that are of type FieldType.Text
            if ([FieldType.Choice, FieldType.Lookup].includes(dp.type) && dp.sourceConfig) {
                // AstNode for a DS based field is saved with <identifier>._id
                if (fieldIdentifier === dp.identifier && !fieldIdentifier.endsWith('._id')) {
                    // The direct <identifier> entry is not valid
                    return false;
                }
                if (`${dp.identifier}._id` === fieldIdentifier && dp.sourceConfig && fieldIdentifier.endsWith('._id')) {
                    // The entry <identifier>._id match the DS based field data property
                    return true;
                }
            }

            // Standard
            return fieldIdentifier === dp.identifier;

        });

        const field = !fieldIdentifier ? undefined : matchField ?? {
            identifier: fieldIdentifier,
            type: FieldType.Text,
            label: fieldIdentifier,
            display: fieldIdentifier,
        } as DataPropertyDescriptor;

        // Operator
        const operator = ast.op as OperatorComparison | undefined;

        // ValueType
        const valueType = rightNode?.type ?? NodeType.Value;

        // Value
        let value = rightNode?.value; // Any transformation here?

        if (valueType === NodeType.Value && field && value) {
            if (field.sourceConfig) {
                value = await this.getSeeds(value, field.sourceConfig);
            }
            if (field.type === FieldType.Hierarchy) {
                value = await this.hierarchyProvider.getUnit(value);
            }
        }

        return { field, type: ast.type, operator, valueType, value };
    }

    mapFilterNodesToFilter(nodes: FilterEditorNode[]): AstNode | undefined {

        if (!nodes.length) {
            return;
        }

        const args = nodes.map((node) => this.mapFilterNodeToAstNode(node)).filter((e) => e != null) as AstNode[];

        return { type: NodeType.Combinator, op: QueryOperators.And, args };
    }

    mapFilterNodeToAstNode(node: FilterEditorNode): AstNode | undefined {

        if (!node.field || node.value == null) {
            return;
        }

        let identifier = node.field.identifier;

        // AstNode for a ZonedDateTime is save with <identifier>.value
        if (node.field.type === FieldType.ZonedDateTime) {
            identifier = `${node.field.identifier}.value`;
        }
        // AstNode for a DS based field is saved with <identifier>._id
        if (node.field.sourceConfig && [FieldType.Choice, FieldType.Lookup].includes(node.field.type)) {
            identifier = `${node.field.identifier}._id`;
        }

        let value = node.value;

        if (node.field.sourceConfig && node.field.sourceConfig.type !== DataSourceType.Named) {
            value = Array.isArray(value) ? value.map((v) => v._id ?? v) : value._id ?? value;
        }

        if (node.field.type === FieldType.Hierarchy) {
            identifier = `${node.field.identifier}.id`;

            if (node.valueType === NodeType.Value) {
                value = (value as HierarchyUnit).id;
            }
        }

        return {
            type: node.type,
            op: node.operator,
            args: [
                {
                    type: NodeType.Identifier,
                    value: identifier,
                },
                {
                    type: node.valueType ?? NodeType.Value,
                    value,
                },
            ],
        };
    }

    buildRootControl(nodes: FilterEditorNode[]): UfControlArray {
        return this.fb.array(nodes.map((arg) => this.buildNodeControl(arg)));
    }

    buildNodeControl(node: FilterEditorNode): UfControlGroup {

        const typeControl = this.fb.control(node.type, ValidatorFunctions.compose([
            ValidatorFunctions.required('A type is required'),
            // To extends to NodeType.Combinator when editor will manage deeper AstNode levels
            ValidatorFunctions.custom((v) => v === NodeType.Operator, 'Only Operator node are allowed'),
        ]));

        const control = this.fb.group({
            [FilterNodeControlKeys.Field]: [node.field, ValidatorFunctions.required('A field is required')],
            [FilterNodeControlKeys.Type]: typeControl,
            [FilterNodeControlKeys.Operator]: [node.operator, ValidatorFunctions.compose([
                ValidatorFunctions.required('An operator is required'),
                // To extends to NodeType.Combinator and Combinators check when editor will manager deeper AstNode levels
                ValidatorFunctions.custom((v) => this.queryComparisonOperators.includes(v), 'Only Comparison operators are allowed for a Comparison node'),
            ])],
            [FilterNodeControlKeys.ValueType]: [node.valueType, ValidatorFunctions.compose([
                ValidatorFunctions.required('A value type is required'),
                ValidatorFunctions.custom((v) => [NodeType.Value, NodeType.Expression].includes(v), `Only 'Value' or 'Expression' are allowed`),
            ])],
            [FilterNodeControlKeys.Value]: [node.value, ValidatorFunctions.required('A value is required')],
        }, {
            // Not a staticFilter field validation and flag ad touched
            validators: ValidatorFunctions.custom((v) => {
                const controlNode = v as FilterEditorNode;

                if (!controlNode.field) {
                    return true;
                }

                return controlNode.field?.asStaticFilter === true;
            }, `Field '${node.field?.identifier ?? ''}' not available`),
        });

        // Force error to be visible for non mapped fields
        if (node.field && !node.field.asStaticFilter) {
            control.markAsTouched();
        }

        return control;
    }

    isValid(filter: AstNode): boolean {

        // First level attributes check
        if (!filter.args || !filter.op || filter.type == null) {
            return false;
        }

        // Check args are only 2 and valids
        for (const node of filter.args) {
            if (!node.args) {
                return false;
            }

            return node.args.filter((arg) => arg.type != null && arg.value != null).length === 2;
        }

        return true;
    }

    private async getSeeds(value: string | string[], sourceConfig: SourceConfig): Promise<DataSeed | null | DataSeed[]> {

        const getSeed = async(key: string, sc: SourceConfig): Promise<DataSeed | null> => {
            switch (sc.type) {
                case DataSourceType.Users:
                    try {
                        const user = await this.usersClient.getByUsername(key);

                        if (user.lastName == null && user.firstName == null) {
                            return { _id: key, _display: 'First and Last name undefined' };
                        } else {
                            return { _id: key, _display: `${user.firstName ?? ''} ${user.lastName ?? ''}` };
                        }
                    } catch (e) {
                        return null;
                    }
                case DataSourceType.Named:
                    return { _id: key, _display: key };
                default:
                    const dataSourceLoader = this.dataLoaderFactory.create(sourceConfig);

                    if (!dataSourceLoader) {
                        return null;
                    }

                    return dataSourceLoader.get(key);
            }
        };

        if (Array.isArray(value)) {
            const seeds: DataSeed[] = [];

            for (const v of value) {
                const seed = await getSeed(v, sourceConfig);

                if (seed) {
                    seeds.push(seed);
                }
            }

            return seeds;
        }

        return getSeed(value, sourceConfig);
    }

}
