<div *ngIf="managerControl.enabled || managerInfo" [ngClass]="cssClasses" class="uf-box">
    <div class="uf-app-bar flat accent">
        <div class="title">
            Manager
        </div>
        <button *ngIf="managerControl.enabled && managerInfo" (click)="selectManager()" type="button" title="Delete"
            class="uf-action">
            <uf-icon name="delete" />
        </button>
    </div>
    <div class="uf-grid pad">
        <uf-search-result *ngIf="managerControl.enabled && !managerInfo" [options]="managerOptions"
            (searchChange)="searchUsers($event)" (valueChange)="selectManager($event)" placeholder="Select manager"
            nameProperty="_display" class="col-12 default" />
        <uf-description-list *ngIf="managerInfo" [items]="managerInfo" class="col-12" />
    </div>
</div>