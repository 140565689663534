import { Component, Input, OnInit } from '@angular/core';
import { DescriptionListItem, UfControlArray, UfControlGroup } from '@unifii/library/common';
import { StructureNodeBucketOptions, TableSourceType } from '@unifii/sdk';

import { TableInfo, UcProject } from 'client';
import { DialogsService } from 'services/dialogs.service';

import { StructureNodeBucketOptionsControlKeys } from '../structure-control-keys';
import { StructureFormCtrl } from '../structure-form-ctrl';

@Component({
    selector: 'uc-node-dashboard-tables',
    templateUrl: './node-dashboard-tables.html',
})
export class NodeDashboardTablesComponent implements OnInit {

    @Input() control: UfControlArray;

    readonly formKeys = StructureNodeBucketOptionsControlKeys;

    protected tables: TableInfo[];
    protected searchResults: TableInfo[] = [];

    constructor(
        private project: UcProject,
        private sfb: StructureFormCtrl,
        private dialogs: DialogsService,
    ) { }

    get bucketOptions(): StructureNodeBucketOptions[] {
        return this.control.getRawValue();
    }

    async ngOnInit() {
        // if list is empty warn the user that bucket tables need to be defined
        this.tables = await this.project.getTables({ params: { sort: 'title', type: TableSourceType.Bucket } });
    }

    async search(q?: string) {
        if (q && q.trim().length > 0) {
            q = q.toLowerCase().trim();
        }

        const results = await this.project.getTables({ params: { sort: 'title', type: TableSourceType.Bucket, q } });

        this.searchResults = results.filter((t) => this.bucketOptions.find((bo) => bo.identifier === t.identifier) == null);
    }

    add(table?: TableInfo) {

        if (!table) {
            return;
        }

        this.control.push(this.sfb.buildBucketOptionControl({
            identifier: table.identifier,
            pageSize: 5,
        }));
    }

    async remove(index: number) {
        if (!await this.dialogs.confirmDelete()) {
            return;
        }

        this.control.removeAt(index);
    }

    getLabel(control: UfControlGroup): string | undefined {
        return this.findTableDefinition(control)?.title;
    }

    getTableLink(control: UfControlGroup): any[] | undefined {
        const tableInfo = this.findTableDefinition(control);

        if (!tableInfo) {
            return;
        }

        return ['..', 'tables', tableInfo.id];
    }

    getTableDescription(control: UfControlGroup): DescriptionListItem[] | undefined {

        const tableInfo = this.findTableDefinition(control);

        if (tableInfo) {
            return [
                { term: 'Identifier', description: tableInfo.identifier },
            ];
        }

        return;
    }

    getTableIdentifier(control: UfControlGroup): string | undefined {
        return control.get(StructureNodeBucketOptionsControlKeys.Identifier)?.value;
    }

    findTableDefinition(control: UfControlGroup): TableInfo | undefined {
        const identifier = this.getTableIdentifier(control);

        return identifier ? this.tables.find((t) => t.identifier === identifier) : undefined;
    }

    protected mapDescription(table: TableInfo): string {
        return `${table.title} (${table.identifier})`;
    }

}
