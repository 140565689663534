<div *ngIf="userInfo.isExternal || (authProviders.length && isMyAccount)" [ngClass]="cssClasses" class="uf-box">
    <div class="uf-app-bar flat accent">
        <div class="title">
            External Authentication
        </div>
    </div>
    <div class="uc-group-content pad-sides pad-bottom">
        <ng-container *ngFor="let provider of userAuthProviders">
            <ng-template [ngIf]="authProviderInfo[provider.id]" let-providerInfo>
                <div class="body-copy">
                    <h5>{{provider.type}} - {{provider.tenant}}</h5>
                    <uf-description-list>
                        <ng-container *ngIf="providerInfo.systemRoles.length">
                            <dt>System Roles:</dt>
                            <dd>{{ providerInfo.systemRoles.join(', ') }}</dd>
                        </ng-container>
                        <ng-container *ngIf="providerInfo.roles.length">
                            <dt>Tenant Roles:</dt>
                            <dd>{{ getRolesDisplays(providerInfo.roles).join(', ') }}</dd>
                        </ng-container>
                        <ng-container *ngIf="providerInfo.claims.length">
                            <dt>Claims:</dt>
                            <dd>
                                <span *ngFor="let claim of providerInfo.claims">
                                    {{ claim }}<br></span>
                            </dd>
                        </ng-container>
                        <ng-container *ngIf="providerInfo.units?.length">
                            <dt>Units:</dt>
                            <dd>
                                <span *ngFor="let unit of providerInfo.units">
                                    {{ unit._display }}<br /></span>
                            </dd>
                        </ng-container>
                    </uf-description-list>
                </div>
            </ng-template>
        </ng-container>


        <ng-container *ngIf="authProviders.length && isMyAccount && !userInfo.isExternal">

            <uf-message *ngIf="error" [content]="error.message || 'Failed to link to provider'" icon="error"
                class="error col-12" />

            <uf-checkbox [(value)]="connectToExternal" label="Connect your Unifii account to an Identity Provider"
                class="col-12" />

            <ng-container *ngIf="connectToExternal">
                <uf-blockquote icon="infoSolid" heading="Connect your Unifii account to an Identity Provider"
                    content="If you’re currently logged in to the selected provider in the browser, this account will be selected automatically. Otherwise you will be required to log in."
                    class="info col-12" />

                <div class="col-12">
                    <ng-template [ngForOf]="authProviders" ngFor let-provider>
                        <label *ngIf="provider.providerLoginLabel" class="small">{{provider.providerLoginLabel}}</label>
                        <button (click)="linkToProvider(provider)" type="button"
                            class="authentication-provider gap-md-bottom">
                            <img [src]="provider.loginIcon" />
                            <p class="small">{{provider.loginLabel}}</p>
                        </button>
                    </ng-template>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>