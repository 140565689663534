<div class="uf-form-card">
    <div class="uf-app-bar">
        <button (click)="runtime.close()" type="button" class="uf-action tertiary" title="Close">
            <uf-icon name="close" />
        </button>
        <h3 style="text-align: center;">{{data.title}}</h3>
    </div>
    <div class="grow">
        <div class="uf-grid pad gaps">
            <div *ngIf="data.publishState" [ngClass]="publishStateClassDictionary[data.publishState]"
                class="col-12 uf-lozenge state-lozenge left right">
                {{data.publishState}}
            </div>
            <uf-description-list *ngIf="modifiedPublishedDescriptionList" [items]="modifiedPublishedDescriptionList"
                class="col-12 stacked-center left right" />
        </div>
    </div>
</div>