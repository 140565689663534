"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UsersClient = void 0;
const client_models_1 = require("../client-models");
const constants_1 = require("../constants");
const functions_1 = require("../functions");
/** Client for v0/users APIs */
class UsersClient {
    constructor(client) {
        this.client = client;
    }
    query(query, options) {
        return this.client.get(this.url(), { ...options, query: (0, functions_1.stringifyQuery)(query) });
    }
    get(id, options) {
        return this.client.get(this.url(id), options);
    }
    getByUsername(username, options) {
        return this.client.get(this.url(constants_1.APIPath.UsersUsername, username), options);
    }
    async checkEmail(email, options) {
        try {
            await this.client.head(this.url(constants_1.APIPath.UsersEmails, email), options);
            return true;
        }
        catch (e) {
            if (e.type !== client_models_1.ErrorType.NotFound) {
                throw e;
            }
        }
        return false;
    }
    getAuthProviders(id, options) {
        return this.client.get(this.url(id, constants_1.APIPath.UsersAuthProviders), options);
    }
    bulkAdd(users, options) {
        return this.client.post(this.url(constants_1.APIPath.UsersBulk), { ...options, body: users });
    }
    bulkAddCsv(file, additionalData, options) {
        const formData = this.getCSVBulkData(file, additionalData);
        return this.client.post(this.url(constants_1.APIPath.UsersBulk), { ...options, body: formData, multipart: true });
    }
    bulkInvite(userInvites, options) {
        return this.client.post(this.url(constants_1.APIPath.UsersBulkInvite), { ...options, body: userInvites });
    }
    bulkInviteCsv(file, additionalData, options) {
        const formData = this.getCSVBulkData(file, additionalData);
        return this.client.post(this.url(constants_1.APIPath.UsersBulkInvite), { ...options, body: formData, multipart: true });
    }
    save(user, options) {
        if (!user.id) {
            return this.client.post(this.url(), { ...options, body: user });
        }
        return this.client.put(this.url(user.id), user, options);
    }
    delete(id, options) {
        return this.client.delete(this.url(id), options);
    }
    resetPassword(username, options) {
        return this.client.post(this.url(constants_1.APIPath.UsersUsername, username, constants_1.APIPath.ResetPassword), { ...options, anonymous: true });
    }
    uploadAttachment(userId, file, options) {
        return this.client.upload(file, this.url(userId, constants_1.APIPath.UsersFiles), options);
    }
    getDownloadUrl(query, format = client_models_1.Formats.Csv) {
        return (0, functions_1.composeDownloadUrl)(this.url(), format, query);
    }
    getAttachmentUrl(userId, attachmentId) {
        return `${this.url(userId, constants_1.APIPath.UsersFiles, attachmentId)}?${constants_1.QueryParams.AccessToken}=${this.client.token ?? ''}`;
    }
    url(...extra) {
        return this.client.buildUrl(constants_1.APIPath.Users, ...extra);
    }
    getCSVBulkData(file, additionalData) {
        const formData = new FormData();
        formData.append(client_models_1.Formats.Csv, file);
        if (additionalData) {
            formData.append(client_models_1.Formats.Json, new Blob([JSON.stringify(additionalData)], {
                type: client_models_1.ClientContentType.ApplicationJson,
            }));
        }
        return formData;
    }
}
exports.UsersClient = UsersClient;
