import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData, ChartDataset } from 'chart.js';
import Chart from 'chart.js/auto'; // chartjs 3 is treeshakable by default, but since ours is driven by back end config, we import everything from 'auto' to be safe

@Component({
    selector: 'uc-chart',
    templateUrl: 'chart.html',
})
export class ChartComponent implements AfterViewInit, OnChanges {

    @ViewChild('canvas', { static: true }) canvas: ElementRef;

    @Input() config: ChartConfiguration;
    @Input() data: ChartData;
    @Input() datasets: ChartDataset[];

    private chart: Chart;

    ngAfterViewInit() {
        this.init();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.config) {
            this.init();
        }

        if (changes.data) {
            if (this.chart && this.data) {
                this.chart.data = this.data;
                this.chart.update();
            }
        }

        if (changes.datasets) {
            if (this.chart && this.data) {
                this.chart.data.datasets = this.datasets;
                this.chart.update();
            }
        }
    }

    private init() {

        if (this.chart) {
            this.chart.destroy();
        }

        if (!this.config) {
            console.warn('Chart config must be supplied');

            return;
        }

        if (this.datasets) {
            this.config.data.datasets = this.datasets;
        }

        this.chart = new Chart(this.canvas.nativeElement, this.config);
    }

}
