<uf-expander class="uf-box flat">
    <div expanderHeader class="uf-app-bar flat">
        <div class="title primary">Workflow</div>
        <uf-icon *ngIf="transitions.invalid" name="error" class="error" />
        <a *ngIf="transitions.valid" (click)="pasteTransition()" title="Paste transition" class="uf-action tertiary">
            <uf-icon name="paste" />
        </a>
    </div>

    <div expanderBody class="pad-sm">

        <uf-blockquote *ngIf="!hasBucket" class="warning col-1of1" icon="warningSolid"
            content="The Form Data Repository is necessary in order to configure the workflow" />

        <uc-drag-list *ngIf="transitions.length" [parent]="transitions" [items]="transitions.controls" canDrop="false"
            canReorder="true">
            <ng-template [ngForOf]="formEditorTransitions" ngFor let-transition let-i="index">
                <ng-container *ngTemplateOutlet="transitionTemplate; context: {
                    disabled: transitions.at(i).disabled,
                    invalid: transitions.at(i).invalid,
                    transition,
                    i
                }" />
            </ng-template>
        </uc-drag-list>

        <uf-error [control]="transitions" class="wrap-text" />

        <div class="row">
            <button *ngIf="!transitions.disabled" [disabled]="!hasBucket" (click)="addTransition()" type="button"
                class="uf-button x-small right">
                Add Transition
            </button>
        </div>

    </div>
</uf-expander>

<ng-template #transitionTemplate let-disabled="disabled" let-invalid="invalid" let-transition="transition" let-i="i">
    <div [dragDisabled]="disabled" dragItem class="uf-app-bar uf-box flat draggable gap-sm-bottom">
        <div class="row grow center-all">
            <div class="col grow">
                <div class="grow pad-sm-left">
                    <div>{{getTransitionLabel(transition)}}</div>
                    <!-- TODO Wait for 2.1 release to actually show the deep error -->
                    <!-- <uf-error-deep [control]="transition"></uf-error-deep> -->
                </div>
                <div class="grow pad-sm-left">
                    {{getResultAndRolesLabel(transition)}}
                </div>
            </div>
            <uf-icon *ngIf="invalid" name="error" class="error" />
            <uf-icon *ngIf="transition.validate" name="required" title="validate required fields"
                class="gap-sm-sides primary" />
            <uf-icon *ngIf="transition.showIf" name="showIf" title="show if" class="gap-sm-sides primary" />
            <uf-icon *ngIf="transition.tags?.length" name="tagged" title="tags" class="gap-sm-sides primary" />
            <uf-icon *ngIf="transition.spawns?.length" name="trigger" title="triggers" class="gap-sm-sides primary" />
            <uf-icon *ngIf="transition.description" name="notes" title="description" class="gap-sm-sides primary" />
            <uf-icon *ngIf="transition.hasPersistentVisibility" name="view" title="always visible"
                class="gap-sm-sides primary" />
            <uf-icon *ngIf="transition.keepOpen" name="next" title="keep open" class="gap-sm-sides primary" />
            <button *ngIf="!invalid" [disabled]="!hasBucket" (click)="copyTransition(i)" type="button" title="Copy"
                class="uf-action">
                <uf-icon name="copy" />
            </button>
            <button *ngIf="!disabled" [disabled]="!hasBucket" (click)="editTransition(i)" type="button" title="Edit"
                class="uf-action">
                <uf-icon name="edit" />
            </button>
            <button *ngIf="!disabled" (click)="removeTransition(i)" title="Delete" type="button" class="uf-action">
                <uf-icon name="delete" />
            </button>
        </div>

    </div>
</ng-template>