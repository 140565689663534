import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { DataDescriptor, DataPropertyDescriptor, DataPropertyInfoService, DescriptionListItem, FormDefinitionMetadataIdentifiers, Modal, ModalData, ModalRuntime } from '@unifii/library/common';
import { FormData } from '@unifii/sdk';

export interface MetadataModalData {
    formData: FormData;
    bucketDataDescriptor: DataDescriptor;
}

@Component({
    templateUrl: './metadata-modal.html',
})
export class MetadataModalComponent implements Modal<MetadataModalData, void>, OnInit {

    @HostBinding('class.uc-form-card') class = true;

    runtime = inject(ModalRuntime<MetadataModalData, void>);
    data = inject(ModalData) as MetadataModalData;
        
    protected items: DescriptionListItem[];

    private metadataProperties = Object.values(inject(DataPropertyInfoService).formDefinitionReferences);

    ngOnInit() {

        this.items = (this.metadataProperties
            .filter((property) => ![FormDefinitionMetadataIdentifiers.Parent, FormDefinitionMetadataIdentifiers.History].includes(property.identifier as FormDefinitionMetadataIdentifiers))
            .map((property) => this.data.bucketDataDescriptor.propertyDescriptorsMap.get(property.identifier))
            .filter((descriptor) => !!descriptor) as DataPropertyDescriptor[])
            .map((descriptor) => ({
                term: descriptor.label,
                description: descriptor.identifier.split('.').reduce((data, prop) => data ? data[prop] : undefined, this.data.formData) as any,
            }));
    }

    close() {
        this.runtime.close();
    }

}
