import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { Breadcrumb, ClipboardService, ToastService, UfControl, UfControlArray, UfControlGroup, UfFormBuilder, ValidatorFunctions } from '@unifii/library/common';
import { FieldType, Option, UfError, ensureUfError } from '@unifii/sdk';
import { UserProvisioningCache } from '@unifii/user-provisioning';
import { Subscription } from 'rxjs';

import { UcClaimConfig, UcUserClaims } from 'client';
import { EditData, SaveAndClose, SaveOption, SaveOptionType, useDefaultErrorMessage } from 'components';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { DefinitionIdentifierValidCharactersValidator, IdentifierNoEmptySpacesValidator, camelize, pascalize } from 'helpers/field-identifier-helper';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { DialogsService } from 'services/dialogs.service';

import { ClaimTableManager } from './claim-table-manager';

enum ClaimControlKeys {
    Id = 'id',
    Type = 'type',
    ValueType = 'valueType',
    Label = 'label',
    IsRequired = 'isRequired',
    Options = 'options',
    Searchable = 'isSearchable'
}

enum ClaimOptionControlKeys {
    Id = 'id',
    Display = 'display'
}

const SearchableFieldTypes = [
    FieldType.Text,
    FieldType.TextArray,
    FieldType.Phone,
    FieldType.Email,
    FieldType.Website,
];

@Component({
    selector: 'uc-claim-detail',
    templateUrl: './claim-detail.html',
})
export class ClaimDetailComponent implements OnInit, OnDestroy, EditData {

    protected readonly claimControlKeys = ClaimControlKeys;
    protected readonly claimOptionControlKeys = ClaimOptionControlKeys;
    protected readonly fieldType = FieldType;
    protected readonly valueTypeOptions: Option[] = [
        { identifier: FieldType.Text, name: 'Text' },
        { identifier: FieldType.Number, name: 'Number' },
        { identifier: FieldType.Phone, name: 'Phone' },
        { identifier: FieldType.Email, name: 'Email' },
        { identifier: FieldType.Website, name: 'Website' },
        { identifier: FieldType.Date, name: 'Date' },
        { identifier: FieldType.DateTime, name: 'Date Time' },
        { identifier: FieldType.Bool, name: 'Bool' },
        { identifier: FieldType.Choice, name: 'Choice' },
        { identifier: FieldType.MultiChoice, name: 'Multi Choice' },
        { identifier: FieldType.TextArray, name: 'Text Array' },
    ];

    protected form: UfControlGroup;
    protected error?: UfError;

    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private ufb = inject(UfFormBuilder);
    private toast = inject(ToastService);
    private clipboard = inject(ClipboardService);
    private dialogs = inject(DialogsService);
    private breadcrumbService = inject(BreadcrumbService);
    private userProvisioningCache = inject(UserProvisioningCache);
    private tableManager = inject<ClaimTableManager>(TableContainerManager);
    private claimClient = inject(UcUserClaims);
    private builderHeaderService = inject(BuilderHeaderService);

    private readonly duplicate = this.route.snapshot.params.duplicate === 'true';
    private breadcrumbs: Breadcrumb[];
    private claimId?: string;
    private unsavedOptions: UfControlGroup[] = []; // References of newly added options so id's can be autogenerated
    private claims: UcClaimConfig[];
    private subscriptions = new Subscription();

    async ngOnInit() {

        try {

            this.claimId = this.getClaimId(this.route);
            let initialClaim = await this.getClaim(this.claimId);

            if (this.duplicate) {
                initialClaim = this.duplicateClaim(initialClaim);
            }

            this.form = this.getRootControl(initialClaim);
            this.breadcrumbs = this.getBreadcrumbs(initialClaim);
            if (!this.claimId || this.duplicate) {
                this.claims = await this.claimClient.list();
            }

            this.subscriptions.add(this.builderHeaderService.saveClicked.subscribe((saveOption) => { void this.save(saveOption); }));

            this.subscriptions.add(this.form.statusChanges.subscribe(() => {
                this.edited = !this.form.pristine;
                this.breadcrumbs = this.getBreadcrumbs(Object.assign(initialClaim, this.claim));
            }));

            this.buildHeaderConfig(initialClaim);

        } catch (e) {
            this.error = useDefaultErrorMessage(e);
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    get edited() {
        return !!this.builderHeaderService.config.edited;
    }

    set edited(edited: boolean) {
        this.builderHeaderService.config.edited = edited;
    }

    protected addOption() {
        const controlGroup = this.getOptionControl({ id: '', display: '' });

        this.unsavedOptions.push(controlGroup);
        this.options.push(controlGroup);
        this.form.markAsDirty();
    }

    protected async removeOption(i: number, option: UfControlGroup) {
        if (!await this.dialogs.confirmDelete()) {
            return;
        }
        this.options.removeAt(i);
        this.form.markAsDirty();

        this.unsavedOptions = this.unsavedOptions.filter((o) => o !== option);
    }

    protected async paste() {
        const text = await this.clipboard.getText() ?? '';

        try {
            const json = JSON.parse(text);

            delete json.id;
            // the options need to be added separately so the values can be patched.
            if (json.options) {
                this.options.controls.push(...json.options.map((option: any) => this.getOptionControl(option)));
            }
            this.form.patchValue(json);
        } catch (e) {
            console.error(e);
        }
    }

    protected valueTypeChange(type: FieldType) {
        if (this.claim.valueType !== type) {
            this.options.reset();
            this.options.clear();
        }

        if (!SearchableFieldTypes.includes(type)) {
            this.searchableControl?.setValue(null);
            this.searchableControl?.disable();
        } else {
            this.searchableControl.enable();
        }
    }

    protected changeIdentifier(value: string) {
        const typeControl = this.form.get(ClaimControlKeys.Type);

        if (!this.claim.id && typeControl?.untouched) {
            typeControl.setValue(camelize(value));
        }
    }

    protected optionLabelChange(value: string, controlGroup: UfControlGroup) {
        if (this.unsavedOptions.includes(controlGroup) && controlGroup.get(ClaimOptionControlKeys.Id)?.untouched) {
            controlGroup.get(ClaimControlKeys.Id)?.setValue(pascalize(value));
        }
    }

    protected get claim(): UcClaimConfig {
        return this.form.getRawValue() as UcClaimConfig;
    }

    protected get typeControl(): UfControl {
        return this.form.get(ClaimControlKeys.Type) as UfControl;
    }

    protected get valueTypeControl(): UfControl {
        return this.form.get(ClaimControlKeys.ValueType) as UfControl;
    }

    protected get searchableControl(): UfControl {
        return this.form.get(ClaimControlKeys.Searchable) as UfControl;
    }

    protected get options(): UfControlArray {
        return this.form.get(ClaimControlKeys.Options) as UfControlArray;
    }

    protected get optionControls(): UfControlGroup[] {
        return (this.form.get(ClaimControlKeys.Options) as UfControlArray).controls as UfControlGroup[];
    }

    private getBreadcrumbs(claim: UcClaimConfig): Breadcrumb[] {
        return this.breadcrumbService.getBreadcrumbs(this.route, ['User Claims', claim.type ?? 'New claim']);
    }

    private getRootControl(claim: UcClaimConfig): UfControlGroup {
        return this.ufb.group({
            [ClaimControlKeys.Id]: [{ value: claim.id, disabled: true }],
            [ClaimControlKeys.Type]: [{ value: claim.type, disabled: claim.id != null }, ValidatorFunctions.compose([
                ValidatorFunctions.required('Identifier is required'),
                DefinitionIdentifierValidCharactersValidator,
                IdentifierNoEmptySpacesValidator,
                ValidatorFunctions.custom(this.validateExistingIdentifier.bind(this), 'Identifier must be unique'),
            ])],
            [ClaimControlKeys.ValueType]: [{ value: claim.valueType ?? FieldType.Text, disabled: claim.id != null }, ValidatorFunctions.required('Type is required')],
            [ClaimControlKeys.Label]: [claim.label, ValidatorFunctions.required('Label is required')],
            [ClaimControlKeys.IsRequired]: [claim?.isRequired],
            [ClaimControlKeys.Searchable]: [{ value: claim?.isSearchable, disabled: !SearchableFieldTypes.includes(claim.valueType) }],
            [ClaimControlKeys.Options]: this.ufb.array((claim.options ?? []).map(this.getOptionControl.bind(this))),
        });
    }

    private async save(saveOption?: SaveOption) {

        this.form.setSubmitted();

        if (this.form.invalid) {
            return;
        }

        let claim = this.mapClaim(this.claim);

        try {
            if (claim.id == null) {
                claim = await this.claimClient.add(claim);
                // update id attribute on form
                this.form.patchValue(claim);
                this.tableManager.reload.next();
            } else {
                claim = await this.claimClient.update(claim);
                this.tableManager.updateItem.next(claim);
            }

            this.unsavedOptions = [];
            this.form.markAsPristine();
            this.form.updateValueAndValidity();
            this.toast.success(`Claim ${claim.type} saved`);
            this.userProvisioningCache.clear();
            await this.userProvisioningCache.init();

            this.breadcrumbs = this.getBreadcrumbs(claim);

            if (saveOption?.id === SaveOptionType.Close) {
                void this.router.navigate(['..'], { relativeTo: this.route });

                return;
            }

            this.buildHeaderConfig(claim);
        } catch (e) {
            const error = ensureUfError(e);

            this.toast.error(error.message);
        }
    }

    private validateExistingIdentifier(identifier: string): boolean {
        return !(this.claims ?? []).some((c) => c.type.toLowerCase() === identifier.toLowerCase());
    }

    private getClaim(id?: string): Promise<UcClaimConfig> {
        if (id) {
            return this.claimClient.get(id);
        }

        // provide empty claim
        return Promise.resolve({
            id: null as any,
            type: '',
            valueType: FieldType.Text,
            label: '',
            options: [],
        });
    }

    private getClaimId(route: ActivatedRoute): string | undefined {
        const id = route.snapshot.params.claimId;

        if (id !== 'new') {
            return id;
        }

        return;
    }

    private getOptionControl(option: { id: string; display: string }): UfControlGroup {
        const displayControl = this.ufb.control(option.display, ValidatorFunctions.required('Label is required'));
        const idControl = this.ufb.control(option.id, ValidatorFunctions.compose([
            ValidatorFunctions.required('Identifier is required'),
            ValidatorFunctions.alphanumeric('Identifier can only contain alphanumeric values'),
        ]));

        return this.ufb.group({
            [ClaimOptionControlKeys.Id]: idControl,
            [ClaimOptionControlKeys.Display]: displayControl,
        });
    }

    private duplicateClaim(claim: UcClaimConfig): UcClaimConfig {
        return {
            id: null as any,
            type: `${claim.type}COPY`,
            valueType: claim.valueType,
            label: `${claim.label} COPY`,
            options: claim.options,
        };
    }

    private mapClaim(claim: UcClaimConfig): UcClaimConfig {
        const { valueType } = claim;

        if (valueType !== FieldType.Choice && valueType !== FieldType.MultiChoice) {
            delete claim.options;
        }

        if (!SearchableFieldTypes.includes(valueType)) {
            delete claim.isSearchable;
        }

        return claim;
    }

    private buildHeaderConfig(claim: UcClaimConfig) {
        this.builderHeaderService.updateConfig({
            lastModifiedAt: claim.lastModifiedAt,
            lastModifiedBy: claim.lastModifiedBy,
            hideSaveButton: false,
            cancelRoute: ['..'],
            saveOptions: [SaveAndClose],
            breadcrumbs: this.breadcrumbs,
        });
    }

}
