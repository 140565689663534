import { Component, HostBinding, Inject } from '@angular/core';
import { DescriptionListItem, Modal, ModalData, ModalRuntime } from '@unifii/library/common';
import { TenantSettings } from '@unifii/sdk';

import { Config } from 'app-config';

@Component({
    selector: 'uc-contact-info-modal',
    templateUrl: './contact-info-modal.html',
})
export class ContactInfoModalComponent implements Modal<TenantSettings, void> {

    @HostBinding('class.uc-card') class = true;
    @HostBinding('class.grid') class1 = true;

    contactDetails: DescriptionListItem[] = [];

    constructor(
        public runtime: ModalRuntime<TenantSettings, void>,
        @Inject(ModalData) public data: TenantSettings,
        @Inject(Config) public config: Config,
    ) {
        if (data.contactName != null) {
            this.contactDetails.push({ term: 'Name', description: data.contactName });
        }

        if (data.contactEmail != null) {
            this.contactDetails.push({ term: 'Email', description: { description: data.contactEmail, href: `mailto:${data.contactEmail}` } });
        }

        if (data.contactPhone != null) {
            this.contactDetails.push({ term: 'Phone', description: { description: data.contactPhone, href: `tel:${data.contactPhone}` } });
        }
    }

    close() {
        this.runtime.close();
    }

}
