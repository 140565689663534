import { ClientGetOptions, ClientPostOptions, ClientPutOptions, amendOptionsParams } from '@unifii/sdk';

import { DefaultPaginationParams } from 'constant';

import { CompoundInfo, UcCompound, UcPage } from './content-models';
import { UcClient } from './uc-client';

export class UcLanguage {

    constructor(
        private client: UcClient,
        private projectId: number,
        private languageCode: string,
    ) { }

    getViews(options?: ClientGetOptions): Promise<CompoundInfo[]> {
        return this.client.get(this.url(['views']), amendOptionsParams(DefaultPaginationParams, options));
    }

    getView(id: number, options?: ClientGetOptions): Promise<UcCompound> {
        return this.client.get(this.url(['views', id]), options);
    }

    saveView(id: number, view: UcCompound, options?: ClientPutOptions): Promise<UcCompound> {
        return this.client.put(this.url(['views', id]), view, options);
    }

    approveView(id: number, options?: ClientPostOptions): Promise<CompoundInfo> {
        return this.client.post(this.url(['views'], ['approved']), amendOptionsParams({ id }, options));
    }

    unapproveView(id: number, options?: ClientPostOptions): Promise<CompoundInfo> {
        return this.client.post(this.url(['views']), amendOptionsParams({ id }, options));
    }

    getPages(options?: ClientGetOptions): Promise<CompoundInfo[]> {
        return this.client.get(this.url(['pages']), amendOptionsParams(DefaultPaginationParams, options));
    }

    getPage(id: number, options?: ClientGetOptions): Promise<UcPage> {
        return this.client.get(this.url(['pages', id]), options);
    }

    savePage(page: UcPage): Promise<UcPage> {
        if (!page.id) {
            return this.client.post(this.url(['pages']), { body: page });
        }

        return this.client.put(this.url(['pages', page.id]), page);
    }

    approvePage(id: number, options?: ClientPostOptions): Promise<CompoundInfo> {
        return this.client.post(this.url(['pages'], ['approved']), amendOptionsParams({ id }, options));
    }

    unapprovePage(id: number, options?: ClientPostOptions): Promise<CompoundInfo> {
        return this.client.post(this.url(['pages']), amendOptionsParams({ id }, options));
    }

    private url(beforeLanguage: (string | number)[] = [], afterLanguage: string[] = []): string {
        const urlParts = ['projects', this.projectId].concat(...beforeLanguage, 'translations', this.languageCode, ...afterLanguage);

        return this.client.buildUrl(urlParts);
    }

}
