<div *ngIf="form && !error" class="header-pane uf-app-bar transparent">
    <uc-builder-header>
        <button *ngIf="form && !typeControl.disabled" (click)="paste()" title="Paste" type="button" class="uf-button"
            header-buttons>
            Paste
        </button>
    </uc-builder-header>
</div>

<uf-panel *ngIf="form && !error" class="content-pane container">
    <div class="uf-container-lg">
        <div class="uf-grid row-gap-lg pad-top">
            <ng-container *ngIf="form" [formGroup]="form">
                <div class="col-12 uf-box">
                    <div class="uf-app-bar accent">
                        <div class="title">Details</div>
                    </div>
                    <div class="pad uf-grid">
                        <ng-container *ngIf="!typeControl.disabled; else descriptionList">
                            <uf-select [formControlName]="claimControlKeys.ValueType" [options]="valueTypeOptions"
                                (valueChange)="valueTypeChange($event)" label="Type" valueProperty="identifier"
                                class="col-12" />
                            <uf-text [formControlName]="claimControlKeys.Label" (valueChange)="changeIdentifier($event)"
                                label="Label" class="col-12" />
                            <uf-text [formControlName]="claimControlKeys.Type" label="Identifier" class="col-12" />
                        </ng-container>
                        <ng-template #descriptionList>
                            <uf-dl class="col-12">
                                <dt>Type:</dt>
                                <dd>{{ valueTypeControl.value | fieldDisplay: fieldType.Choice:valueTypeOptions }}</dd>
                                <uf-text [formControlName]="claimControlKeys.Label"
                                    (valueChange)="changeIdentifier($event)" label="Label" class="col-12" />
                                <dt>Identifier:</dt>
                                <dd>{{ typeControl.value }}</dd>
                            </uf-dl>
                        </ng-template>
                        <uf-checkbox [formControlName]="claimControlKeys.IsRequired" label="Required" class="col-12" />
                        <uf-checkbox *ngIf="!searchableControl.disabled" [formControlName]="claimControlKeys.Searchable"
                            label="Searchable" class="col-12" />
                    </div>
                </div>
                <div *ngIf="claim.valueType === fieldType.Choice || claim.valueType === fieldType.MultiChoice"
                    class="col-12 uf-box gap-top">
                    <div class="uf-app-bar accent">
                        <div class="title">Options</div>
                    </div>
                    <uc-drag-list *ngIf="optionControls.length" [items]="optionControls" canDrop="false"
                        canReorder="true" class="pad">
                        <ng-container *ngFor="let option of optionControls; let i = index">
                            <div dragItem class="uf-box uf-list-fieldset flat draggable">
                                <div [formGroup]="option" class="uf-grid pad">
                                    <uf-text [formControlName]="claimOptionControlKeys.Display"
                                        (valueChange)="optionLabelChange($event, option)" label="Label" class="col-6" />
                                    <uf-text [formControlName]="claimOptionControlKeys.Id" label="Identifier"
                                        class="col-6" />
                                </div>
                                <div class="uf-app-bar flat transparent">
                                    <button (click)="removeOption(i, option)" class="uf-action" type="button"
                                        title="Delete">
                                        <uf-icon name="delete" />
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </uc-drag-list>
                    <div class="row pad">
                        <button (click)="addOption()" type="button" class="uf-button secondary right">Add
                            Option</button>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</uf-panel>

<uc-error-message [error]="error" />