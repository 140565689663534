import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UfControl, UfControlArray, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';
import { DataSeed, Definition, FieldType, SchemaField, SchemaTransition, SpawnFormAction } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { DefinitionInfo, UcDefinition, UcProject } from 'client';
import { UcFormBucketClient } from 'client/form-bucket-client';
import { transitionName } from 'helpers/transition-helper-functions';

const NoSchemaInfoMessage = 'Form must be saved to enable data mapping';

interface ControlValue {
    label: string;
    form: DataSeed;
    transition: SchemaTransition;
    fieldMappings: { parentField: SchemaField; childField: SchemaField }[];
}

interface SchemaFieldOption extends SchemaField {
    optionLabel?: string;
}

@Component({
    selector: 'uc-transition-action-editor',
    templateUrl: './transition-action-editor.html',
})
export class TransitionActionEditorComponent implements OnInit, OnDestroy {

    @Output() valueChange = new EventEmitter<SpawnFormAction>();
    @Output() remove = new EventEmitter<void>();
    @Input() value: SpawnFormAction;
    @Input() parentFormBucket: string;

    protected readonly transitionName = transitionName;

    protected control = new UfControlGroup({
        label: new UfControl(ValidatorFunctions.required('Label is required')),
        form: new UfControl(ValidatorFunctions.required('A target form is required')),
        transition: new UfControl(ValidatorFunctions.required('A target form action is required')),
        fieldMappings: new UfControlArray([]),
    });
    protected dataMappingDisabledWarning: string | undefined;

    // Data lists
    protected transitions: SchemaTransition[] = [];
    protected forms: DataSeed[] = [];
    protected parentFieldOptions: SchemaFieldOption[] = []; // filtered parentSchemaFields
    protected childFieldOptions: SchemaFieldOption[] = []; // filtered childSchemaFields

    private definitionLookup = new Map<number, UcDefinition>();
    private _parentControl: UfControlArray;
    private parentSchemaFields: SchemaField[] = [];
    private childSchemaFields: SchemaField[] = [];
    private subscriptions = new Subscription();

    constructor(
        private ucProject: UcProject,
        private ucFormBucketClient: UcFormBucketClient,
    ) { }

    ngOnInit() {

        this.init(this.parentFormBucket);
        this.subscriptions.add(this.control.valueChanges.subscribe((v) => this.onValueChange(v)));
    }

    ngOnDestroy() {
        const index = this.parentControl.controls.indexOf(this.control);

        this.parentControl.removeAt(index);
        this.subscriptions.unsubscribe();
    }

    async init(parentFormBucket: string) {

        this.parentSchemaFields = await this.getParentSchemaFields(parentFormBucket);

        if (Object.keys(this.value).length) {
            this.setControlValue(this.value);
        }
    }

    @Input() set parentControl(v: UfControlArray) {
        this._parentControl = v;
        this._parentControl.push(this.control);
    }

    get parentControl(): UfControlArray {
        return this._parentControl;
    }

    get fieldMappingDisabled(): boolean {
        return !this.parentFormBucket || !this.parentSchemaFields.length;
    }

    delete() {
        this.remove.emit();
    }

    filterForms(q: string) {
        this.ucProject.getForms({ params: { q, sort: 'name' } }).then((definitions) => {
            this.forms = definitions.map((d) => this.dataSeedMapper(d));
        });
    }

    filterParentFormFields(q: string, dep: SchemaField | undefined) {
        this.parentFieldOptions = this.parentSchemaFields
            .filter((f) => this.schemaFieldFilter(q || '', f, dep));
        this.parentFieldOptions.forEach((f) => this.getSchemaFieldOption(f));
    }

    filterChildFormFields(q: string, dep: SchemaField | undefined) {

        // Create a list of already mapped identifiers
        const identifiers = (this.control.value?.fieldMappings || []).map((v: any) => v?.childField?.identifier);

        this.childFieldOptions = this.childSchemaFields
            .filter((f) => !identifiers.includes(f.identifier) && this.schemaFieldFilter(q || '', f, dep));
        this.childFieldOptions.forEach((f) => this.getSchemaFieldOption(f));
    }

    formChange(seed: DataSeed) {

        if (seed == null) {
            this.control.controls.transition?.reset();
            this.transitions = [];

            return;
        }
        this.updateSchemaInformation(+seed._id);
    }

    addMappingsControl(value?: { parentField: SchemaField; childField: SchemaField }) {

        const parentField = new UfControl(ValidatorFunctions.required(''));
        const childField = new UfControl([
            ValidatorFunctions.required(''),
            ValidatorFunctions.custom((v) => v?.type === parentField?.value?.type, ''),
        ], { deps: [parentField] });

        const formGroup = new UfControlGroup({ parentField, childField });

        if (value != null) {
            formGroup.setValue(value);
        }
        (this.control.controls.fieldMappings as UfControlArray).push(formGroup);
    }

    private async getParentSchemaFields(id: string): Promise<SchemaField[]> {

        try {
            const bucket = await this.ucFormBucketClient.get(id);

            return this.metaSchemaFields.concat(bucket.fields);
        } catch (e) {
            this.dataMappingDisabledWarning = NoSchemaInfoMessage;

            return [];
        }
    }

    private dataSeedMapper(definition: Definition | DefinitionInfo): DataSeed {
        return {
            _id: definition.id as string,
            _display: (definition as Definition)?.label || (definition as DefinitionInfo)?.name,
        };
    }

    private async updateSchemaInformation(definitionId: number): Promise<void> {

        try {
            const definition = await this.ucProject.getForm(definitionId + '');
            const schema = await this.ucFormBucketClient.get(definition.bucket as string);

            this.definitionLookup.set(definitionId, definition);
            this.childSchemaFields = schema.fields;
            this.transitions = schema.transitions;

        } catch (e) {
            this.transitions = [];
        }
    }

    private async setControlValue(data: SpawnFormAction): Promise<void> {

        await this.updateSchemaInformation(data.targetFormId);

        const definition = this.definitionLookup.get(data.targetFormId) as Definition;
        const formValue = this.dataSeedMapper(definition);
        const transitionValue = this.transitions.find((t) => t.source === data.targetFormSource && t.action === data.targetFormAction);

        // Create a copy so it does not get overwritten when mappings control set
        const fieldMappings = [...(data.fieldMappings || [])];

        this.control.setValue({
            label: data.label,
            form: formValue,
            transition: transitionValue,
            fieldMappings: [],
        });

        for (const { parentFieldIdentifier, childFieldIdentifier } of fieldMappings) {
            const parentField = this.parentSchemaFields.find(({ identifier }) => identifier === parentFieldIdentifier);
            const childField = this.childSchemaFields.find(({ identifier }) => identifier === childFieldIdentifier);

            if (parentField && childField) {
                this.addMappingsControl({ parentField: this.getSchemaFieldOption(parentField), childField: this.getSchemaFieldOption(childField) });
            }
        }

    }

    private valueMapper({ label, form, transition, fieldMappings }: ControlValue): SpawnFormAction | undefined {

        if (this.control.invalid) {
            return;
        }

        return {
            label,
            targetFormId: +form._id,
            targetFormIdentifier: this.definitionLookup.get(+form._id)?.identifier as string,
            targetFormAction: transition.action,
            targetFormSource: transition.source,
            fieldMappings: fieldMappings.map((v) => this.fieldMappingsMapper(v)),
        };
    }

    private onValueChange = (v: ControlValue) => {
        Object.assign(this.value, this.valueMapper(v));
    };

    private schemaFieldFilter(q: string, field: SchemaField, depField?: SchemaField): boolean {

        const typeMatch = !depField?.type || field.type === depField?.type;
        const label = field.label.toLocaleLowerCase();

        q = q.toLocaleLowerCase().trim();

        return typeMatch && (!q || label.includes(q));
    }

    private fieldMappingsMapper({ parentField, childField }: { parentField: SchemaField; childField: SchemaField }): { parentFieldIdentifier: string; childFieldIdentifier: string } {
        return {
            parentFieldIdentifier: parentField.identifier,
            childFieldIdentifier: childField.identifier,
        };
    }

    private get metaSchemaFields(): SchemaField[] {
        return [
            { identifier: '_createdBy', label: 'Created by', type: FieldType.Text },
            { identifier: '_lastModifiedBy', label: 'Last modified by', type: FieldType.Text },
        ];
    }

    private getSchemaFieldOption(field: SchemaField): SchemaFieldOption {
        (field as SchemaFieldOption).optionLabel = `${field.label} (${field.identifier})`;

        return field;
    }

}
