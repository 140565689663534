<router-outlet />
<div class="content-pane" ufMasterDetail>
    <uf-panel class="container">
        <ng-template [ngForOf]="projects" ngFor let-project let-p="index">
            <div class="grid--fixed">
                <div class="project-header col-1of1 row center-all space-children">
                    <h3 class="grow">{{project.name}}</h3>
                    <div class="fieldset-item--small">
                        <span>Version</span>
                        <span>{{ project.version }}</span>
                    </div>
                    <div class="fieldset-item--small">
                        <span>Last Published</span>
                        <span>{{ project.publishedAt | date }}</span>
                    </div>
                </div>
                <ng-template [ngForOf]="project.languages" ngFor let-language let-i="index">
                    <div class="col-1of4">
                        <a [routerLink]="['/assets/translations/projects', project.id, language.code]"
                            class="language-card uc-card--centered">
                            <uc-chart *ngIf="project.charts[i] let chartConfig" [config]="chartConfig" />
                            <h4>{{language.name}}</h4>
                        </a>
                    </div>
                </ng-template>
            </div>
        </ng-template>
    </uf-panel>
</div>