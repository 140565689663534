<nav class="col center-all">
    <ng-container *ngIf="!context.language">
        <uc-menu-item *ngFor="let config of menuConfigs" [restrict]="config.restrict" [label]="config.label"
            [icon]="config.icon" [link]="config.link" [showLabel]="true" />
    </ng-container>
    <ng-container *ngIf="projectId && context.language">
        <uc-menu-item [link]="['/assets/translations/projects', projectId, context.language.code, 'collections']"
            restrict="Translator,Publisher" label="Collections" icon="collections" />
        <uc-menu-item [link]="['/assets/translations/projects', projectId, context.language.code, 'views']"
            restrict="Translator,Publisher" label="Views" icon="views" />
    </ng-container>

    <div class="grow"></div>

    <uf-description-list [items]="{ version: config.version }" class="x-small stacked" />

</nav>