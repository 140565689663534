import { Component, HostBinding } from '@angular/core';
import { Language, ProjectInfo, TokenStorage } from '@unifii/sdk';

import { UcClient, UcLanguage } from 'client';
import { ContextService } from 'services/context.service';

export const createLanguage = (client: UcClient, context: ContextService) => new UcLanguage(
    client,
    (context.project as ProjectInfo).id as any as number,
    (context.language as Language).code,
);

@Component({
    selector: 'uc-language',
    templateUrl: './language.html',
    providers: [
        { provide: UcLanguage, useFactory: createLanguage, deps: [UcClient, ContextService, TokenStorage] },
    ],
})
export class LanguageComponent {

    @HostBinding('class.stretch-component') class = true;

}
