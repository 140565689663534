import { AuthProvider } from '@unifii/sdk';
import { LockedConfig, toLockedConfig } from '@unifii/user-provisioning';

import { UcUserAuthProvider, UcUserInfo, UcUsers } from 'client';

export interface UserDataForm {
    lockedConfig: LockedConfig | undefined;
    userAuthProviders: UcUserAuthProvider[] | undefined;
}

export const IsSuperUserControl = 'isSuperUser';

export const buildUserDataForm = async(ucUsers: UcUsers, user: UcUserInfo): Promise<UserDataForm> => {
    
    const result: UserDataForm = { lockedConfig: undefined, userAuthProviders: undefined };

    if (!user.id || !user.isExternal) {
        return result;
    }

    result.userAuthProviders = transformAuthProviders(await ucUsers.getAuthProviders(user.id));
    result.lockedConfig = toLockedConfig(result.userAuthProviders);

    return result;
};

// TODO merge it with Discover similar logic into user-provisioning
const transformAuthProviders = (authProviders: UcUserAuthProvider[]): UcUserAuthProvider[] => {
    return authProviders
    .filter(((uap) => uap.type !== 'Unifii' as AuthProvider))
    .map((a) => {
        a.scimEnabled = true;
        
        return a;
    });
};

