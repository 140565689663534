<div class="uf-app-bar">
    <button (click)="close()" title="Close" type="button" class="uf-action tertiary">
        <uf-icon name="close" />
    </button>
    <h3>Field Settings</h3>
</div>
<div class="uf-grid pad gaps">
    <uf-description-list class="col-12">
        <dt>Label:</dt>
        <dd>{{data.fieldInfo.label}}</dd>
        <dt>Identifier:</dt>
        <dd>
            <div class="row">
                <span>{{data.fieldInfo.path}}</span>
                <button (click)="copyAttribute(data.fieldInfo.path)" type="button"
                    class="uf-action tertiary gap-sm-left small" title="Copy">
                    <uf-icon name="copy" />
                </button>
            </div>
        </dd>
        <dt>Type:</dt>
        <dd>
            <div class="row">
                <uf-icon [name]="data.fieldInfo.icon" /><span>{{data.fieldInfo.type}}</span>
            </div>
        </dd>
    </uf-description-list>

    <uf-checkbox [disabled]="data.isSearchableReadonly" [(value)]="data.fieldSettings.isSearchable"
        label="Is Searchable" class="col-12">
        <uf-help *ngIf="data.isSearchableReadonly && data.fieldSettings.isSearchable == undefined"
            content="Not Compatible" />
        <uf-help *ngIf="data.isSearchableReadonly && data.fieldSettings.isSearchable" content="Cannot be modified" />
    </uf-checkbox>
    <uf-checkbox [disabled]="data.isReportableReadonly" [(value)]="data.fieldSettings.isReportable"
        label="Is Reportable" class="col-12">
        <uf-help *ngIf="data.isReportableReadonly && !data.fieldSettings.isReportable == undefined"
            content="Not Compatible" />
        <uf-help *ngIf="data.isReportableReadonly && data.fieldSettings.isReportable" content="Cannot be modified" />
    </uf-checkbox>

</div>
<div class="uf-form-actions">
    <button (click)="close()" type="button" class="uf-button tertiary right">Cancel</button>
    <button (click)="submit()" type="button" class="uf-button primary">Save</button>
</div>