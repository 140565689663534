import { DefinitionPublishState } from '@unifii/sdk';

export enum TranslationState {
    Missing = 'Missing',
    Draft = 'Draft',
    Approved = 'Approved',
    Published = 'Published'
}

export const TRANSLATION_STATES: { name: string; value: TranslationState }[] = [
    { name: 'Not Translated', value: TranslationState.Missing },
    { name: 'Draft', value: TranslationState.Draft },
    { name: 'Approved', value: TranslationState.Approved },
    { name: 'Published', value: TranslationState.Published },
];

export interface Translationable {
    publishState: string;
    sourcePublishState?: string;
    lastPublishedAt?: string;
}

export class TranslationStateHelper {

    static canApprove(p: Translationable) {
        return p.publishState === TranslationState.Draft && p.sourcePublishState !== DefinitionPublishState.Draft;
    }

    static canUnapprove(p: Translationable) {
        return p.publishState === TranslationState.Approved;
    }

}
