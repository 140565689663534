"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IDBLockManager = exports.LocalStorageLockManager = exports.WebLockManager = void 0;
const functions_1 = require("./functions");
class WebLockManager {
    request(name, callback) {
        // depends on @types/web-locks-api
        return navigator.locks.request(name, callback);
    }
}
exports.WebLockManager = WebLockManager;
class LocalStorageLockManager {
    async request(name, callback) {
        const lockUUID = (0, functions_1.generateUUID)();
        await this.set(name, lockUUID);
        try {
            await callback();
        }
        finally {
            await this.release(name, lockUUID);
        }
    }
    async set(name, requested, retries = 0) {
        let stored = this.getValue(name);
        if (stored == null || retries === 5) {
            this.setValue(name, requested);
            stored = this.getValue(name);
            if (stored === requested) {
                return;
            }
        }
        await this.delay();
        return this.set(name, requested, retries + 1);
    }
    async release(name, id, retries = 0) {
        const stored = this.getValue(name);
        if (stored === id || retries === 5) {
            this.setValue(name, null);
            return;
        }
        await this.delay();
        return this.release(name, id, retries + 1);
    }
    setValue(name, v) {
        if (v == null) {
            localStorage.removeItem(name);
        }
        else {
            localStorage.setItem(name, v);
        }
    }
    getValue(name) {
        return localStorage.getItem(name);
    }
    delay() {
        return new Promise((resolve) => setTimeout(() => { resolve(); }, this.timeout));
    }
    get timeout() {
        return Math.floor(Math.random() * 2000);
    }
}
exports.LocalStorageLockManager = LocalStorageLockManager;
class IDBLockManager {
    constructor() {
        this.dbName = 'RefreshTokenLock';
        this.lockId = `${Math.floor(Math.random() * 1000)}`;
    }
    async request(name, callback) {
        await this.set(this.lockId);
        try {
            await callback();
        }
        finally {
            await this.release(this.lockId);
        }
    }
    async set(requested, retries = 0) {
        let stored = await this.getValue();
        if (stored == null || retries === 5) {
            await this.setValue(requested);
            stored = await this.getValue();
            if (stored === requested) {
                return;
            }
        }
        await this.delay();
        return this.set(requested, retries + 1);
    }
    async release(id, retries = 0) {
        const stored = await this.getValue();
        if (stored === id || retries === 5) {
            await this.setValue(null);
            return;
        }
        await this.delay();
        return this.release(id, retries + 1);
    }
    reset() {
        return this.setValue(null);
    }
    async setValue(v) {
        const openDB = await this.openDB();
        const transaction = openDB.transaction('IDBLockStore', 'readwrite');
        const store = transaction.objectStore('IDBLockStore');
        const request = store.put({ id: v }, 'id');
        return this.promisify(request);
    }
    async getValue() {
        const openDB = await this.openDB();
        const transaction = openDB.transaction('IDBLockStore', 'readwrite');
        const store = transaction.objectStore('IDBLockStore');
        const request = store.get('id');
        /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment*/
        const result = await this.promisify(request);
        if (result != null) {
            /* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access*/
            return result.id;
        }
        return null;
    }
    openDB() {
        const indexedDB = window.indexedDB;
        const openDB = indexedDB.open(this.dbName, 1);
        openDB.onupgradeneeded = () => {
            const store = openDB.result.createObjectStore('IDBLockStore');
            store.createIndex('id', 'id', { unique: true });
        };
        return this.promisify(openDB);
    }
    delay() {
        return new Promise((resolve) => setTimeout(() => { resolve(); }, this.timeout));
    }
    get timeout() {
        return Math.floor(Math.random() * 2000);
    }
    promisify(request) {
        return new Promise((resolve, reject) => {
            request.onerror = reject;
            request.onsuccess = () => { resolve(request.result); };
        });
    }
}
exports.IDBLockManager = IDBLockManager;
