"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isHierarchyUnitFormData = exports.isHierarchyUnitsPath = exports.isHierarchyPath = exports.isHierarchyStep = exports.isFileData = exports.isAddress = exports.isGeoLocation = exports.isZonedDateTime = exports.isCostModelFormat = exports.isDataSeed = void 0;
const type_guard_functions_1 = require("./type-guard-functions");
const isDataSeed = (value) => (0, type_guard_functions_1.isDictionary)(value) &&
    (0, type_guard_functions_1.isStringNotEmpty)(value._id) &&
    (0, type_guard_functions_1.isString)(value._display);
exports.isDataSeed = isDataSeed;
const isCostModelFormat = (value) => (0, type_guard_functions_1.isDictionary)(value) &&
    (0, type_guard_functions_1.isNumber)(value.amount) &&
    (0, type_guard_functions_1.isStringNotEmpty)(value.currency);
exports.isCostModelFormat = isCostModelFormat;
const isZonedDateTime = (value) => (0, type_guard_functions_1.isDictionary)(value) &&
    (0, type_guard_functions_1.isStringNotEmpty)(value.value) &&
    (0, type_guard_functions_1.isStringNotEmpty)(value.tz);
exports.isZonedDateTime = isZonedDateTime;
const isGeoLocation = (value) => (0, type_guard_functions_1.isDictionary)(value) &&
    (0, type_guard_functions_1.isOptionalType)(value.lat, type_guard_functions_1.isNumber) &&
    (0, type_guard_functions_1.isOptionalType)(value.lng, type_guard_functions_1.isNumber) &&
    (0, type_guard_functions_1.isOptionalType)(value.zoom, type_guard_functions_1.isNumber);
exports.isGeoLocation = isGeoLocation;
const isAddress = (value) => (0, type_guard_functions_1.isDictionary)(value) &&
    (0, type_guard_functions_1.isOptionalType)(value.formattedAddress, type_guard_functions_1.isString) &&
    (0, type_guard_functions_1.isOptionalType)(value.room, type_guard_functions_1.isString) &&
    (0, type_guard_functions_1.isOptionalType)(value.venue, type_guard_functions_1.isString) &&
    (0, type_guard_functions_1.isOptionalType)(value.address1, type_guard_functions_1.isString) &&
    (0, type_guard_functions_1.isOptionalType)(value.address2, type_guard_functions_1.isString) &&
    (0, type_guard_functions_1.isOptionalType)(value.suburb, type_guard_functions_1.isString) &&
    (0, type_guard_functions_1.isOptionalType)(value.city, type_guard_functions_1.isString) &&
    (0, type_guard_functions_1.isOptionalType)(value.postcode, type_guard_functions_1.isString) &&
    (0, type_guard_functions_1.isOptionalType)(value.state, type_guard_functions_1.isString) &&
    (0, type_guard_functions_1.isOptionalType)(value.country, type_guard_functions_1.isString) &&
    (0, exports.isGeoLocation)(value);
exports.isAddress = isAddress;
const isFileData = (value) => (0, type_guard_functions_1.isDictionary)(value) &&
    (0, type_guard_functions_1.isString)(value.name) &&
    (0, type_guard_functions_1.isOptionalType)(value.id, type_guard_functions_1.isString);
exports.isFileData = isFileData;
const isHierarchyStep = (value) => (0, type_guard_functions_1.isDictionary)(value) &&
    Object.keys(value).length === 2 &&
    (0, type_guard_functions_1.isStringNotEmpty)(value.id) &&
    (0, type_guard_functions_1.isString)(value.label);
exports.isHierarchyStep = isHierarchyStep;
const isHierarchyPath = (value) => (0, type_guard_functions_1.isArrayOfType)(value, exports.isHierarchyStep) && !!value.length;
exports.isHierarchyPath = isHierarchyPath;
const isHierarchyUnitsPath = (value) => (0, type_guard_functions_1.isArrayOfType)(value, exports.isHierarchyPath) && !!value.length;
exports.isHierarchyUnitsPath = isHierarchyUnitsPath;
const isHierarchyUnitFormData = (value) => (0, type_guard_functions_1.isDictionary)(value) &&
    (0, type_guard_functions_1.isStringNotEmpty)(value.id) &&
    (0, type_guard_functions_1.isString)(value.label) &&
    (0, exports.isHierarchyPath)(value.path);
exports.isHierarchyUnitFormData = isHierarchyUnitFormData;
