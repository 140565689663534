import { Component, Input } from '@angular/core';
import { UfControlArray } from '@unifii/library/common';

import { DialogsService } from 'services/dialogs.service';

import { StructureNodeArgControlKeys } from '../structure-control-keys';
import { StructureFormCtrl } from '../structure-form-ctrl';

@Component({
    selector: 'uc-node-args',
    templateUrl: './node-args.html',
})
export class NodeArgsComponent {

    @Input() control: UfControlArray;

    readonly formKeys = StructureNodeArgControlKeys;

    constructor(
        private sfb: StructureFormCtrl,
        private dialogs: DialogsService,
    ) { }

    addArg() {
        this.control.push(this.sfb.buildArgControl({
            key: '',
            value: { value: null, isExpression: false },
        }));
    }

    async removeArg(index: number) {
        if (!await this.dialogs.confirmDelete()) {
            return;
        }

        this.control.removeAt(index);
    }

}
