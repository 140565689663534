<div class="uf-box gap-top gap-sides">
    <div class="uf-app-bar flat accent">
        <div class="title">
            Settings
        </div>
        <ng-container *ngIf="!isNew">
            <button (click)="deleteClicked.emit()" type="button" class="uf-button small right">Delete</button>
            <button *ngIf="form.get(controlKeys.IsActive)?.value" (click)="deactivateClicked.emit()" type="button"
                class="uf-button small">Deactivate</button>
            <button *ngIf="!form.get(controlKeys.IsActive)?.value" (click)="activateClicked.emit()" type="button"
                class="uf-button small">Activate</button>
            <button (click)="editDetails()" type="button" class="uf-button small">Edit</button>
        </ng-container>
    </div>

    <ng-template [ngIf]="!isNew && !edit" [ngIfElse]="inputs">
        <uf-description-list [items]="descriptionListItem" class="pad" />
    </ng-template>

    <ng-template #inputs>
        <div [formGroup]="form" class="uf-grid pad">
            <uf-text [formControlName]="controlKeys.Tenant" label="Tenant Name" class="col-12" />
            <uf-text [formControlName]="controlKeys.ClientId" label="Client Id" class="col-12" />
            <uf-text *ngIf="showProviderLoginLabel" [formControlName]="controlKeys.ProviderLoginLabel"
                label="Provider Login Label" class="col-12" />

            <ng-container [formGroup]="extrasControl">
                <uf-text *ngIf="showAuthorizationServerId" [formControlName]="controlKeys.AuthorizationServer"
                    label="Authorization Server ID" class="col-12" />
                <uf-text *ngIf="showManagementApiAudience" [formControlName]="controlKeys.Audience"
                    label="Management API Audience" class="col-12" />
                <uc-secret-input *ngIf="showSswsSecret" [formControlName]="controlKeys.SswsSecret" label="SSWS Secret"
                    class="col-12" />
            </ng-container>

            <uc-secret-input *ngIf="showClientSecret" [formControlName]="controlKeys.ClientSecret" label="Client Secret"
                class="col-12" />
            <uc-secret-input *ngIf="showScimToken" [formControlName]="controlKeys.ScimToken" label="SCIM Token"
                class="col-12" />

            <uf-checkbox [formControlName]="controlKeys.SupportProvider" label="Support Provider" class="col-12" />

            <uf-checkbox *ngIf="form.get(controlKeys.AllowInvitations)?.enabled"
                [formControlName]="controlKeys.AllowInvitations" label="Allow Invitations" class="col-12" />
            <uf-checkbox *ngIf="form.get(controlKeys.AllowEditing)?.enabled"
                [formControlName]="controlKeys.AllowEditing" label="Allow Editing" class="col-12" />
            <uf-checkbox *ngIf="form.get(controlKeys.AllowDeletePending)?.enabled"
                [formControlName]="controlKeys.AllowDeletePending" label="Allow Delete Pending" class="col-12" />
        </div>

        <div *ngIf="!isNew" class="uf-form-actions">
            <button (click)="cancelEdit()" type="button" class="uf-button tertiary right">Cancel</button>
            <button (click)="doneEditing()" type="button" class="uf-button primary">Done</button>
        </div>

    </ng-template>

</div>