import { Component, HostBinding } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Breadcrumb } from '@unifii/library/common';
import { UfError, isUfError } from '@unifii/sdk';
import { Observable } from 'rxjs';

import { UcTable } from 'client';
import { EditData, SaveAndApprove, SaveAndClose } from 'components';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { PUBLISH_STATE_CLASS_DICTIONARY } from 'helpers/css-class-helper';
import { BreadcrumbService } from 'services/breadcrumb.service';

import { TableInfo, tableResolver } from './table-resolver';

@Component({
    templateUrl: './table.html',
})
export class TableComponent implements EditData {

    @HostBinding('class.stretch-component') class = true;

    readonly publishStateClassDictionary = PUBLISH_STATE_CLASS_DICTIONARY;

    info: TableInfo;

    protected error?: UfError;

    private breadcrumbs: Breadcrumb[];

    constructor(
        private route: ActivatedRoute,
        private breadcrumbService: BreadcrumbService,
        private builderHeaderService: BuilderHeaderService,
    ) {
        const resolverData = this.route.snapshot.data.info as Exclude<Awaited<ReturnType<typeof tableResolver>>, Observable<unknown>>;

        if (isUfError(resolverData)) {
            this.error = resolverData;

            return;
        } else {
            this.info = resolverData;
        }

        this.builderHeaderService.init();
        this.buildHeaderConfiguration(resolverData.table);
    }

    get edited() {
        return !!this.builderHeaderService.config.edited;
    }

    set edited(v: boolean) {
        this.builderHeaderService.config.edited = v;
    }

    updateTable(table: UcTable) {
        this.info.table = table;

        this.buildHeaderConfiguration(table);
    }

    private buildHeaderConfiguration(table: UcTable) {
        this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [table.title || 'New']);
        this.builderHeaderService.buildConfig({
            ...table,
            edited: this.edited,
            breadcrumbs: this.breadcrumbs,
            saveOptions: [SaveAndClose, SaveAndApprove],
            cancelRoute: ['../'],
        });
    }

}
