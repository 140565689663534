import { Routes } from '@angular/router';
import { RunSeriallyGuard } from '@unifii/library/common';
import { CompoundType } from '@unifii/sdk';
import { userProvisioningCacheGuard } from '@unifii/user-provisioning';

import { canDeactivateEditDataComponent } from 'components/common/edit-data';
import { canDeactivateBuilder } from 'components/compound-builder/builder-basic';
import { PermissionsComponent, resourceGuard } from 'components/permissions';
import { canActivateAccount } from 'guards/account-guard';
import { anonymousGuard } from 'guards/anonymous-guard';
import { canActivateTenant } from 'guards/tenant-guard';
import { LanguageComponent } from 'navigation/language.component';
import { ProjectComponent } from 'navigation/project.component';
import { TenantComponent } from 'navigation/tenant.component';
import { ClaimDetailComponent, ClaimTableComponent } from 'pages';
import { APIKeyDetailsComponent } from 'pages/api-keys/api-key-details.component';
import { APIKeysComponent } from 'pages/api-keys/api-keys.component';
import { AppDetailsComponent } from 'pages/apps/app-details.component';
import { AppDevicesComponent } from 'pages/apps/app-devices.component';
import { AppSendNotificationComponent } from 'pages/apps/app-send-notification.component';
import { AppSettingsComponent } from 'pages/apps/app-settings.component';
import { AppsComponent } from 'pages/apps/apps.component';
import { DeviceDetailComponent } from 'pages/apps/device-detail.component';
import { AssetsComponent } from 'pages/assets/assets.component';
import { AuditLogComponent } from 'pages/audit-log/audit-log.component';
import { CollectionBuilderComponent, CollectionDataComponent, CollectionItemBuilderComponent, CollectionsComponent } from 'pages/content/collections/_index';
import { ContentComponent } from 'pages/content/content.component';
import { PageBuilderComponent, PagesComponent } from 'pages/content/pages/_index';
import { ViewBuilderComponent, ViewsComponent } from 'pages/content/views/_index';
import { UnauthorizedComponent } from 'pages/errors/unauthorized.component';
import { bucketConfigResolver } from 'pages/form-data/bucket-config-resolver';
import { BucketDataComponent } from 'pages/form-data/bucket-data.component';
import { BucketSettingsComponent } from 'pages/form-data/bucket-settings.component';
import { BucketComponent } from 'pages/form-data/bucket.component';
import { BucketsComponent } from 'pages/form-data/buckets.component';
import { formDataComponentResolver } from 'pages/form-data/form-data-component-resolver';
import { FormDataComponent } from 'pages/form-data/form-data.component';
import { FormEditorComponent } from 'pages/form-editor/form-editor.component';
import { FormPreviewComponent } from 'pages/forms/form-preview.component';
import { FormsComponent } from 'pages/forms/forms.component';
import { HierarchyComponent } from 'pages/hierarchy/hierarchy.component';
import { LabDragListComponent, ReactiveFormsComponent } from 'pages/lab';
import { EmptyComponent } from 'pages/lab/empty.component';
import { ErrorHandlingComponent } from 'pages/lab/error-handling.component';
import { IconsComponent } from 'pages/lab/icons.component';
import { LabComponent } from 'pages/lab/lab.component';
import { ListsComponent } from 'pages/lab/lists.component';
import { StyleGuideComponent } from 'pages/lab/style-guide.component';
import { TemplateFormComponent } from 'pages/lab/template-form.component';
import { TemplateTableComponent } from 'pages/lab/template-table.component';
import { TemplateTabsComponent } from 'pages/lab/template-tabs.component';
import { LandingComponent } from 'pages/landing/landing.component';
import { MediaDetailComponent } from 'pages/media/media-detail.component';
import { MediaNewComponent } from 'pages/media/media-new.component';
import { MediaComponent } from 'pages/media/media.component';
import { DataSourcesTableComponent, DataTransactionsTableComponent, ExternalCallDetailsComponent, SettingsComponent, SettingsGeneralComponent, SettingsThemingComponent } from 'pages/project-settings';
import { ProjectOverviewComponent } from 'pages/projects/project-overview.component';
import { ProjectsComponent } from 'pages/projects/projects.component';
import { PublishComponent } from 'pages/publish/publish.component';
import { RoleDetailsComponent } from 'pages/roles/role-details.component';
import { RolesComponent } from 'pages/roles/roles.component';
import { StructureComponent } from 'pages/structure';
import { AuthProvidersComponent } from 'pages/system-settings/identity';
import { AuthProviderComponent } from 'pages/system-settings/identity/auth-provider.component';
import { automaticAuthProviderCreationResolver } from 'pages/system-settings/identity/resolvers/automatic-auth-provider-creation-resolver';
import { manualAuthProviderResolver } from 'pages/system-settings/identity/resolvers/manual-auth-provider-resolver';
import { IntegrationDetailComponent } from 'pages/system-settings/integrations/integration-detail.component';
import { IntegrationFeatureComponent } from 'pages/system-settings/integrations/integration-feature.component';
import { IntegrationFeaturesComponent } from 'pages/system-settings/integrations/integration-features.component';
import { IntegrationComponent } from 'pages/system-settings/integrations/integration.component';
import { integrationResolver } from 'pages/system-settings/integrations/resolvers/integration-resolver';
import { SystemEmailSettingsComponent } from 'pages/system-settings/system-email-settings.component';
import { SystemGeneralComponent } from 'pages/system-settings/system-general.component';
import { SystemIntegrationsComponent } from 'pages/system-settings/system-integrations.component';
import { SystemSmtpComponent } from 'pages/system-settings/system-smtp.component';
import { TenantSettingsComponent as SystemSettingsComponent } from 'pages/system-settings/tenant-settings.component';
import { tableResolver } from 'pages/tables/table-resolver';
import { TableComponent, TableConfigurationComponent, TableDetailComponent, TablesComponent } from 'pages/tables/tables';
import { languageCollectionResolver } from 'pages/translations/language-collection-resolver';
import { LanguageCollectionComponent } from 'pages/translations/language-collection.component';
import { LanguageCompoundComponent } from 'pages/translations/language-compound.component';
import { LanguageOverviewComponent } from 'pages/translations/language-overview.component';
import { LanguageViewComponent } from 'pages/translations/language-view.component';
import { LanguageViewsComponent } from 'pages/translations/language-views.component';
import { TranslationsOverviewComponent } from 'pages/translations/translations-overview.component';
import { LoginComponent } from 'pages/user-access/login.component';
import { UserManagementComponent } from 'pages/user-management/user-management.component';
import { MyAccountComponent } from 'pages/users/my-account.component';
import { UserDetailsPageComponent } from 'pages/users/user-details-page.component';
import { UserDevicesComponent } from 'pages/users/user-devices.component';
import { UserComponent } from 'pages/users/user.component';
import { usersTableDataResolver } from 'pages/users/users-table-data-resolver';
import { UsersComponent } from 'pages/users/users.component';
import { DataForwardersComponent, WorkflowComponent, WorkflowDataForwardersFormComponent, WorkflowFormDataFormComponent, WorkflowNotificationComponent, WorkflowNotificationsComponent, WorkflowRuleFormComponent, WorkflowRulesComponent, WorkflowTimersComponent, WorkflowTimersFormComponent } from 'pages/workflows';
import { WorkflowFormDataComponent } from 'pages/workflows/workflow-form-data.component';
import { defaultProjectResolver } from 'resolvers/default-project-resolver';
import { definitionResolver } from 'resolvers/definition-resolver';
import { projectResolver } from 'resolvers/project-resolver';

export const AppRoutes: Routes = [
    // Routes
    { path: 'login', component: LoginComponent, data: { title: 'Login' }, canActivate: [anonymousGuard] },
    { path: 'sso', component: LoginComponent, data: { title: 'Login' } },
    { path: 'form-preview', component: FormPreviewComponent, data: { title: 'Form Preview' }, canActivate: [canActivateTenant] },
    {
        path: '', component: TenantComponent, canActivate: [RunSeriallyGuard], data: { guards: [canActivateAccount, canActivateTenant] }, resolve: [defaultProjectResolver],
        children: [
            { path: '', component: LandingComponent, pathMatch: 'full' },
            { path: 'unauthorized', component: UnauthorizedComponent, data: { title: 'Unauthorized' } },
            { path: 'my-account', component: MyAccountComponent, data: { title: 'My Account' }, canActivate: [userProvisioningCacheGuard], canDeactivate: [canDeactivateEditDataComponent] },
            {
                path: 'user-management', component: UserManagementComponent, data: { title: 'User Management' }, canActivate: [userProvisioningCacheGuard],
                children: [
                    { path: '', redirectTo: 'users', pathMatch: 'full' },
                    {
                        path: 'users', component: UsersComponent, data: { title: 'Users' }, resolve: { tableData: usersTableDataResolver },
                        children: [
                            {
                                path: ':id', component: UserComponent, children: [
                                    { path: '', component: UserDetailsPageComponent, canDeactivate: [canDeactivateEditDataComponent] },
                                    { path: 'devices', component: UserDevicesComponent, data: { title: 'Devices' } },
                                    { path: 'permissions', component: PermissionsComponent, data: { stretchedContainer: true, title: 'Permissions' }, canActivate: [resourceGuard] },
                                    { path: 'inherited-permissions', component: PermissionsComponent, data: { title: 'Inherited Permissions', inherited: true, stretchedContainer: true }, canActivate: [resourceGuard] },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'roles', component: RolesComponent, data: { title: 'Roles' },
                        children: [
                            { path: ':id', component: RoleDetailsComponent, canActivate: [resourceGuard], canDeactivate: [canDeactivateEditDataComponent] },
                        ],
                    },
                    {
                        path: 'user-claims', component: ClaimTableComponent, data: { title: 'User Claims' }, children: [
                            { path: ':claimId', component: ClaimDetailComponent, data: { title: 'Claim' }, canDeactivate: [canDeactivateEditDataComponent] },
                        ],
                    },
                    {
                        path: 'api-keys', component: APIKeysComponent, data: { title: 'API Keys' }, children: [
                            { path: ':id', component: APIKeyDetailsComponent, canActivate: [resourceGuard] },
                        ],
                    },
                    {
                        path: 'audit-log', component: AuditLogComponent,
                    },

                    {
                        path: 'hierarchy', component: HierarchyComponent, data: { title: 'Hierarchy' },
                    },
                ],
            },
            {
                path: 'assets', component: AssetsComponent, data: { title: 'Assets' }, children: [
                    { path: '', redirectTo: 'media', pathMatch: 'full' },
                    {
                        path: 'media', component: MediaComponent, data: { title: 'Media' }, children: [
                            { path: 'new', component: MediaNewComponent, pathMatch: 'full', data: { title: 'New Media' }, canDeactivate: [canDeactivateEditDataComponent] },
                            { path: ':mediaId', component: MediaDetailComponent, canDeactivate: [canDeactivateEditDataComponent], pathMatch: 'prefix' },
                        ],
                    },
                    {
                        path: 'translations', component: TranslationsOverviewComponent, data: { title: 'Translations' }, children: [{
                            path: 'projects/:projectId/:languageId', component: LanguageComponent, resolve: { project: projectResolver },
                            children: [
                                { path: '', component: LanguageOverviewComponent },
                                {
                                    path: 'views', component: LanguageViewsComponent, data: { title: 'Views' }, children: [
                                        { path: ':id', component: LanguageViewComponent, canDeactivate: [canDeactivateBuilder], data: { title: 'View' } },
                                    ],
                                },
                                {
                                    path: 'collections', component: CollectionsComponent, data: { title: 'Collections', inTranslations: true }, children: [
                                        {
                                            path: ':collectionId', component: LanguageCollectionComponent,
                                            data: { title: 'Collection' }, resolve: { collectionData: languageCollectionResolver }, children: [
                                                { path: ':id', component: LanguageCompoundComponent, canDeactivate: [canDeactivateBuilder], data: { title: 'Collection' } },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        }],
                    }],
            },
            { path: 'projects', component: ProjectsComponent, data: { title: 'Projects' } },
            {
                path: 'projects/:projectId', component: ProjectComponent, resolve: { project: projectResolver }, canActivate: [canActivateTenant],
                children: [
                    { path: '', component: ProjectOverviewComponent },
                    { path: 'structure', component: StructureComponent, canDeactivate: [canDeactivateEditDataComponent], data: { title: 'Structure' } },
                    {
                        path: 'content', component: ContentComponent, data: { title: 'Content' }, children: [
                            { path: '', pathMatch: 'full', redirectTo: 'collections' },
                            {
                                path: 'collections', component: CollectionsComponent, data: { title: 'Collections' }, children: [
                                    {
                                        path: ':id/definition', pathMatch: 'full', component: CollectionBuilderComponent, canDeactivate: [canDeactivateBuilder], data: { title: 'Definition' },
                                    },
                                    {
                                        path: ':id', component: CollectionDataComponent, data: { title: 'Collection' }, children: [
                                            {
                                                path: ':id', component: CollectionItemBuilderComponent, canDeactivate: [canDeactivateBuilder], data: { title: 'Collection' },
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'views', component: ViewsComponent, data: { title: 'Views' }, children: [
                                    { path: ':id', component: ViewBuilderComponent, canDeactivate: [canDeactivateBuilder], data: { title: 'View' } },
                                ],
                            },
                            {
                                path: 'pages', component: PagesComponent, data: { title: 'Pages' }, children: [
                                    { path: ':id', component: PageBuilderComponent, canDeactivate: [canDeactivateBuilder], data: { title: 'Pages' } },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'forms', component: FormsComponent, data: { title: 'Forms' }, children: [
                            {
                                path: ':id', component: FormEditorComponent, data: { type: CompoundType.Form, title: 'Form' },
                                resolve: { definition: definitionResolver }, canDeactivate: [canDeactivateEditDataComponent],
                            },
                        ],
                    },
                    {
                        path: 'form-data', component: BucketsComponent, data: { title: 'Form Data' }, children: [
                            {
                                path: ':id', component: BucketComponent, resolve: { bucketConfig: bucketConfigResolver }, data: { reuse: false }, children: [
                                    { path: '', redirectTo: 'settings', pathMatch: 'full' },
                                    { path: 'settings', component: BucketSettingsComponent, canDeactivate: [canDeactivateEditDataComponent], data: { title: 'Settings' } },
                                    {
                                        path: 'preview', component: BucketDataComponent, data: { title: 'Preview Form Data', preview: true }, children: [
                                            { path: ':id', component: FormDataComponent, resolve: { resolverData: formDataComponentResolver }, data: { title: 'Form Data', preview: true }, runGuardsAndResolvers: 'pathParamsChange' },
                                        ],
                                    },
                                    {
                                        path: 'live', pathMatch: 'prefix', component: BucketDataComponent, data: { title: 'Live Form Data' }, children: [
                                            { path: ':id', component: FormDataComponent, resolve: { resolverData: formDataComponentResolver }, runGuardsAndResolvers: 'pathParamsChange', data: { title: 'Form Data' } },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'workflows', component: WorkflowComponent, data: { title: 'Workflows' }, children: [
                            { path: '', redirectTo: 'rules', pathMatch: 'full' },
                            {
                                path: 'rules', component: WorkflowRulesComponent, data: { title: 'Workflow Rules' }, children: [
                                    { path: ':id', component: WorkflowRuleFormComponent, canDeactivate: [canDeactivateEditDataComponent] },
                                ],
                            },
                            {
                                path: 'timers', component: WorkflowTimersComponent, data: { title: 'Workflow Timers' }, children: [
                                    { path: ':id', component: WorkflowTimersFormComponent, canDeactivate: [canDeactivateEditDataComponent] },
                                ],
                            },
                            {
                                path: 'data-forwarders', component: DataForwardersComponent, data: { title: 'Workflow Data Forwarders' }, children: [
                                    { path: ':id', component: WorkflowDataForwardersFormComponent, canDeactivate: [canDeactivateEditDataComponent] },
                                ],
                            },
                            {
                                path: 'notifications', component: WorkflowNotificationsComponent, data: { title: 'Workflow Notifications' }, children: [
                                    { path: ':id', component: WorkflowNotificationComponent, canDeactivate: [canDeactivateEditDataComponent] },
                                ],
                            },
                            {
                                path: 'form-data', component: WorkflowFormDataComponent, data: { title: 'Form Data' }, children: [
                                    { path: ':id', component: WorkflowFormDataFormComponent, canDeactivate: [canDeactivateEditDataComponent] },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'tables', component: TablesComponent, data: { title: 'Tables' }, children: [
                            {
                                path: ':id', component: TableComponent, resolve: { info: tableResolver }, children: [
                                    { path: '', component: TableConfigurationComponent, canDeactivate: [canDeactivateEditDataComponent] },
                                    { path: 'details-page', component: TableDetailComponent, canDeactivate: [canDeactivateEditDataComponent] },
                                ],
                            },
                        ],
                    },
                    { path: 'publish', component: PublishComponent, data: { title: 'Publish' } },
                    {
                        path: 'settings', component: SettingsComponent, data: { title: 'Project Settings' },
                        children: [
                            { path: '', redirectTo: 'general', pathMatch: 'full' },
                            { path: 'general', component: SettingsGeneralComponent, canDeactivate: [canDeactivateEditDataComponent] },
                            { path: 'theming', component: SettingsThemingComponent, canDeactivate: [canDeactivateEditDataComponent], data: { title: 'Theming' } },
                            {
                                path: 'data-sources', component: DataSourcesTableComponent, data: { title: 'Data Sources' }, children: [
                                    { path: ':id', component: ExternalCallDetailsComponent, canDeactivate: [canDeactivateEditDataComponent] },
                                ],
                            },
                            {
                                path: 'data-transactions', component: DataTransactionsTableComponent, data: { title: 'Data Transactions' }, children: [
                                    { path: ':id', component: ExternalCallDetailsComponent, data: { isDataTransaction: true }, canDeactivate: [canDeactivateEditDataComponent] },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                path: 'system-settings', component: SystemSettingsComponent, data: { title: 'System Settings' },
                children: [
                    { path: '', redirectTo: 'general', pathMatch: 'full' },
                    { path: 'general', component: SystemGeneralComponent, canDeactivate: [canDeactivateEditDataComponent] },
                    {
                        path: 'integrations', component: SystemIntegrationsComponent, data: { title: 'Integrations' }, children: [
                            {
                                path: ':id', component: IntegrationComponent, resolve: { integration: integrationResolver }, canDeactivate: [canDeactivateEditDataComponent], children: [
                                    { path: '', component: IntegrationDetailComponent, canDeactivate: [canDeactivateEditDataComponent] },
                                    {
                                        path: 'features', component: IntegrationFeaturesComponent, canDeactivate: [canDeactivateEditDataComponent], children: [
                                            { path: ':id', component: IntegrationFeatureComponent, canDeactivate: [canDeactivateEditDataComponent] },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'sso', component: AuthProvidersComponent, data: { title: 'Identity' }, canActivate: [userProvisioningCacheGuard], children: [
                            { path: ':type/automatic-registration', component: AuthProviderComponent, resolve: { authProviderData: automaticAuthProviderCreationResolver } },
                            {
                                path: ':type/:id', component: AuthProviderComponent, resolve: { authProviderData: manualAuthProviderResolver }, canDeactivate: [canDeactivateEditDataComponent],
                            },
                        ],
                    },
                    { path: 'email', component: SystemEmailSettingsComponent, canDeactivate: [canDeactivateEditDataComponent] },
                    { path: 'smtp', pathMatch: 'prefix', component: SystemSmtpComponent, canDeactivate: [canDeactivateEditDataComponent] },
                    {
                        path: 'apps', component: AppsComponent, data: { title: 'Apps' }, children: [
                            {
                                path: ':id', component: AppDetailsComponent, data: { title: 'Apps' }, children: [
                                    { path: '', component: AppSettingsComponent, canDeactivate: [canDeactivateEditDataComponent] },
                                    {
                                        path: 'devices', component: AppDevicesComponent, children: [
                                            { path: ':id', component: DeviceDetailComponent, data: { title: 'Apps' } },
                                        ],
                                    },
                                    { path: 'send-notification', component: AppSendNotificationComponent },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                path: 'lab', component: LabComponent, data: { title: 'Lab' }, children: [
                    {
                        path: '', redirectTo: 'style-guide', pathMatch: 'full',
                    },
                    {
                        path: 'style-guide', component: StyleGuideComponent, data: { title: 'Style Guide' },
                    },
                    {
                        path: 'empty', component: EmptyComponent, data: { title: 'Empty' },
                    },
                    {
                        path: 'icons', component: IconsComponent, data: { title: 'Icons' },
                    },
                    {
                        path: 'lists', component: ListsComponent, data: { title: 'Lists' },
                    },
                    {
                        path: 'drag-list', component: LabDragListComponent, data: { title: 'Drag List' },
                    },
                    {
                        path: 'reactive-forms', component: ReactiveFormsComponent, data: { title: 'Reactive Forms' },
                    },
                    {
                        path: 'template-form', component: TemplateFormComponent, data: { title: 'Form' },
                    },
                    {
                        path: 'template-table', component: TemplateTableComponent, data: { title: 'Table' },
                    },
                    {
                        path: 'template-tabs', component: TemplateTabsComponent, data: { title: 'Tabs' }, children: [
                            { path: '', pathMatch: 'prefix', component: TemplateFormComponent },
                            { path: 'table', component: TemplateTableComponent },
                        ],
                    },
                    {
                        path: 'error-handling', component: ErrorHandlingComponent, data: { title: 'Error Handling' },
                    },
                ],
            },
        ],
    },
    { path: '**', redirectTo: '', pathMatch: 'full' },
];
