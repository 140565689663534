import { Component, HostBinding } from '@angular/core';
import { TableContainerManager } from '@unifii/components';
import { Breadcrumb, FilterComponentRegistry, FilterEntries } from '@unifii/library/common';
import { UfError } from '@unifii/sdk';

import { ContextService } from 'services/context.service';

import { LanguageCollectionStore } from './language-collection-store';
import { LanguageCollectionTableManager } from './language-collection-table-manager';
import { LanguageColumnFactory } from './language-column-factory';
import { filterEntries } from './language-filter-entires';
import { LanguageFilterRegistry } from './language-filter-registry';

@Component({
    selector: 'uc-language-collection',
    template: `
        <div *ngIf="!error" ufMasterDetail>
            <uf-table-container ufSyncRoute class="accent list-md">
                <uf-breadcrumbs [breadcrumbs]="breadcrumbs" info class="large"/>
            </uf-table-container>
        </div>
        <router-outlet/>
        <uc-error-message [error]="error" />
    `,
    providers: [
        LanguageCollectionStore,
        LanguageColumnFactory,
        { provide: FilterComponentRegistry, useClass: LanguageFilterRegistry },
        { provide: FilterEntries, useValue: filterEntries },
        { provide: TableContainerManager, useClass: LanguageCollectionTableManager },
    ],
})
export class LanguageCollectionComponent {

    @HostBinding('class.stretch-component') class = true;

    protected breadcrumbs: Breadcrumb[] = [];
    protected error?: UfError;

    constructor(
        private context: ContextService,
        private languageCollectionStore: LanguageCollectionStore,
    ) {
        if (this.languageCollectionStore.error) {
            this.error = this.languageCollectionStore.error;

            return;
        }

        this.breadcrumbs = [
            { urlSegments: ['/translations'], name: 'Translation' },
            { urlSegments: ['../..'], name: this.context.language?.name as string },
            { name: this.languageCollectionStore.definition.label },
        ];
    }

}
