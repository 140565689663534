import { Injectable, inject } from '@angular/core';
import { ClientGetOptions, ProjectContentOptions, ProjectContentOptionsInterface, amendOptionsParams } from '@unifii/sdk';

import { ConsoleDataTransaction, DataTransactionInfo } from './models';
import { UcClient } from './uc-client';

@Injectable({ providedIn: 'root' })
export class UcDataTransactions {

    private client = inject(UcClient);
    private projectOptions = inject(ProjectContentOptions) as ProjectContentOptionsInterface;

    list(q: string | null = null, options?: ClientGetOptions): Promise<DataTransactionInfo[]> {
        return this.client.get(this.url(), amendOptionsParams({ q }, options));
    }

    get(id: string, options?: ClientGetOptions): Promise<ConsoleDataTransaction> {
        return this.client.get(this.url(id), options);
    }

    save(dataTransaction: ConsoleDataTransaction): Promise<ConsoleDataTransaction> {
        if (dataTransaction.id) {
            return this.client.put(this.url(dataTransaction.id), dataTransaction);
        } else {
            return this.client.post(this.url(), { body: dataTransaction });
        }
    }

    private url(...extra: string[]): string {
        const urlParts = ['projects', this.projectOptions.projectId, 'data-transactions', ...extra];

        return this.client.buildUrl(urlParts);
    }

}
