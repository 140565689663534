import { Injectable } from '@angular/core';
import { ContextProvider, mapUserToUserContext } from '@unifii/library/common';

import { ContextService } from './context.service';

@Injectable()
export class UcContextProvider implements ContextProvider {

    constructor(private contextService: ContextService) { }

    get() {
        // TODO find out why contextService is empty when used on the form-preview page
        if (this.contextService.account == null) {
            return {
                user: {
                    username: 'user',
                    roles: [],
                    claims: {},
                },
            };
        }

        const user = mapUserToUserContext(
            Object.assign({},
                this.contextService.account, {
                hasPassword: null as any as boolean,
                isExternal: null as any as boolean,
                isActive: null as any as boolean,
            }));

        return { user };
    }

}
