import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ErrorType, UfError } from '@unifii/sdk';
import { EMPTY, lastValueFrom } from 'rxjs';

import { UcProjectInfo } from 'client';
import { useDefaultErrorMessage } from 'components';
import { ContextService } from 'services/context.service';
import { ProjectService } from 'services/project.service';

export const projectResolver = async(route: ActivatedRouteSnapshot): Promise<UcProjectInfo | UfError> => {

    const context = inject(ContextService);
    const projectService = inject(ProjectService);

    const { languageId, projectId } = route.params;

    if (!projectId) {
        return lastValueFrom(EMPTY);
    }

    try {
        const project = await projectService.setProject(projectId);

        if (!project) {
            return useDefaultErrorMessage(new UfError('Project not found', ErrorType.NotFound));
        }

        context.language = (project.languages ?? []).find((l) => l.code === languageId) ?? null;
        // Register optional translation language
        if (languageId && !context.language) {
            return useDefaultErrorMessage(new UfError('Language not found', ErrorType.NotFound));
        }

        return project;

    } catch (err) {
        return useDefaultErrorMessage(err);
    }
};
