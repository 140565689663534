import { Router } from '@angular/router';
import { UfControlGroup } from '@unifii/library/common';
import { stringsCaseInsensitiveLocalCompare } from '@unifii/library/smart-forms';

import { UcCompound, UcDefinition, UcPage, UcTable, UcView } from 'client';

/** Force reload of the current route */
export const reloadCurrentRoute = (router: Router) => {

    const shouldReuseRoute = router.routeReuseStrategy.shouldReuseRoute;
    const onSameUrlNavigation = router.onSameUrlNavigation;

    router.routeReuseStrategy.shouldReuseRoute = () => false;
    router.onSameUrlNavigation = 'reload';
    const currentUrl = router.url;

    const url = router.url.substring(0, router.url.lastIndexOf('/'));

    router.navigateByUrl(url ?? '/', { skipLocationChange: true }).then(() => router.navigateByUrl(currentUrl)).then(() => {
        router.routeReuseStrategy.shouldReuseRoute = shouldReuseRoute;
        router.onSameUrlNavigation = onSameUrlNavigation;
    });
};

export const cleanDefinitionToBeDuplicated = (data: UcDefinition | UcPage | UcCompound | UcView | UcTable): UcDefinition | UcPage | UcCompound | UcView | UcTable => {
    delete data.id;
    delete data.lastModifiedAt;
    delete data.lastPublishedAt;
    delete data.lastModifiedBy;
    delete data.lastPublishedBy;
    delete data.publishState;

    return data;
};

export const sortGroupControlValue = (group: UfControlGroup, key: string) => {
    const control = group.get(key);

    if (control && Array.isArray(control.value)) {
        control.setValue(control.value.sort(stringsCaseInsensitiveLocalCompare), { emitEvent: false });
    }
};
