<div class="uf-app-bar ">
    <button (click)="close()" type="button" class="uf-action tertiary" title="Close">
        <uf-icon name="close" />
    </button>
    <h3>Data Mapping {{guard ? '*' : ''}}</h3>
</div>
<div class="grow">
    <div [formGroup]="form" class="uf-grid pad gaps">

        <uf-checkbox *ngIf="allowIsExpression" [formControlName]="mappingKeys.IsFromExpression"
            (valueChange)="changedIsExpression($event)" label="Is expression" class="col-12" />

        <uf-text *ngIf="form.get(mappingKeys.IsFromExpression)?.value === true"
            [formControlName]="mappingKeys.FromExpression" label="From expression" class="col-12" />

        <uf-autocomplete *ngIf="form.get(mappingKeys.IsFromExpression)?.value !== true"
            [formControlName]="mappingKeys.From" [options]="filteredProperties"
            (searchChange)="filterProperties($event)" (valueChange)="changedFrom($event)" label="From"
            nameProperty="display" class="col-12" />

        <uf-text [formControlName]="mappingKeys.To" label="To" class="col-12" />

        <uf-text [formControlName]="mappingKeys.Label" label="Label" class="col-12" />

        <uf-checkbox [formControlName]="mappingKeys.IsVisible" (valueChange)="changedVisible($event)" label="Visible"
            class="col-12" />

        <uf-textarea *ngIf="showItemTemplate" [formControlName]="mappingKeys.ItemTemplate" label="Item template"
            class="col-12" />

        <uf-checkbox [formControlName]="mappingKeys.HideEmpty" label="Hide Empty" class="col-12" />

        <uf-description-list [items]="dataTypeDescriptor" class="col-12" />
        <uf-description-list [items]="attributeDescriptor" class="col-12" />

    </div>
</div>
<div class="row space-children pad">
    <button (click)="close()" class="uf-button tertiary right" type="button">Cancel</button>
    <button (click)="submit()" class="uf-button primary" type="button">Save</button>
</div>