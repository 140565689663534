"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasLengthExact = exports.hasLengthAtLeast = exports.isOptionalType = exports.isNotNull = exports.isValueOfNumberEnumType = exports.isValueOfStringEnumType = exports.isArrayOfType = exports.isDictionaryOfType = exports.isJSONObject = exports.isDictionary = exports.isBoolean = exports.isNumber = exports.isStringNotEmpty = exports.isString = exports.isObject = void 0;
const isObject = (value) => typeof value === 'object';
exports.isObject = isObject;
const isString = (value) => typeof value === 'string';
exports.isString = isString;
/** Value is string and not empty '', values like ' ' are valid */
const isStringNotEmpty = (value) => (0, exports.isString)(value) && value.length > 0;
exports.isStringNotEmpty = isStringNotEmpty;
const isNumber = (value) => typeof value === 'number';
exports.isNumber = isNumber;
const isBoolean = (value) => typeof value === 'boolean';
exports.isBoolean = isBoolean;
/** Guard type Dictionary<any> */
const isDictionary = (value) => 
// null is of type object, guard needed
value != null &&
    // object instance
    (0, exports.isObject)(value) &&
    // not an array
    !Array.isArray(value) &&
    // contains only attributes
    !Object.getOwnPropertySymbols(value).length;
exports.isDictionary = isDictionary;
/** Guard type for JSON Object*/
const isJSONObject = (value) => (0, exports.isDictionary)(value) &&
    // value is a pure direct instance of Object
    Object.getPrototypeOf(value) === Object.prototype;
exports.isJSONObject = isJSONObject;
/** Value is a Dictionary of typeGuard => T */
const isDictionaryOfType = (value, typeGuard) => (0, exports.isDictionary)(value) &&
    Object.getOwnPropertyNames(value).every((prop) => typeGuard(value[prop]));
exports.isDictionaryOfType = isDictionaryOfType;
/** Value is an Array of items that satisfy T */
const isArrayOfType = (value, typeGuard) => Array.isArray(value) &&
    value.every((item) => typeGuard(item));
exports.isArrayOfType = isArrayOfType;
/** Value satisfies the string Enum T */
const isValueOfStringEnumType = (enumType) => (enumValue) => Object.values(enumType).includes(enumValue);
exports.isValueOfStringEnumType = isValueOfStringEnumType;
/** Value satisfies the numeric Enum T */
const isValueOfNumberEnumType = (enumType) => (enumValue) => Object.values(enumType).includes(enumValue);
exports.isValueOfNumberEnumType = isValueOfNumberEnumType;
/** Value is not NULL or UNDEFINED */
const isNotNull = (value) => value != null;
exports.isNotNull = isNotNull;
/** Value is UNDEFINED or satisfies T */
const isOptionalType = (value, typeGuard) => {
    return value === undefined || typeGuard(value);
};
exports.isOptionalType = isOptionalType;
/** Value is array with at least length of minLength.
 * Use only in immutable or not mutating scenarios!
*/
const hasLengthAtLeast = (arr, minLength) => arr.length >= minLength;
exports.hasLengthAtLeast = hasLengthAtLeast;
/** Value is array with exact length.
 * Use only in immutable or not mutating scenarios!
*/
const hasLengthExact = (arr, length) => arr.length === length;
exports.hasLengthExact = hasLengthExact;
