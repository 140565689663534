import { Injectable } from '@angular/core';
import { ContentParser, HierarchyUnitProvider, UfControlArray, UfControlGroup, UfFormBuilder, ValidatorFunctions } from '@unifii/library/common';
import { AuthProvider, ClaimConfig, Dictionary, FieldType, HierarchyUnitWithPath, generateUUID, hasLengthAtLeast, isNotNull } from '@unifii/sdk';

import { AuthProviderDetails, AuthProviderMapping, AuthProviderMappingAction, AuthProviderMappingActionType, AuthProviderMappingCondition, AuthProviderMappingConditionType, AuthProviderSourceGroup, FieldMappingData, UcAuthProviders, UcClaimConfig, UcUserClaims } from 'client';

import { AuthProviderMappingActionModel, AuthProviderMappingConditionFormModel, AuthProviderMappingFilter, AuthProviderMappingModel, AuthProviderMappings, AuthProviderMappingsModel, EmailFieldMapping, FieldMappingModel, FilterControlKeys, IgnoreFieldMappingValue, MappingConfig, MappingsControlKeys } from '../models';

interface ClaimActionModel {
    claim: ClaimConfig;
    actions: AuthProviderMappingAction[];
}

@Injectable({ providedIn: 'root' })
export class AuthProviderMappingsController {

    constructor(
        private ufb: UfFormBuilder,
        private hierarchyProvider: HierarchyUnitProvider,
        private ucAuthProviders: UcAuthProviders,
        private ucUserClaims: UcUserClaims,
    ) { }

    buildRoot(model: AuthProviderMappingsModel, config: MappingConfig) {
        return this.ufb.group({
            [MappingsControlKeys.AuthProviderId]: model.authProviderId,
            [MappingsControlKeys.UserFieldsMapping]: this.buildUserMappingControls(config, model.userFieldsMapping),
            [MappingsControlKeys.Mappings]: this.ufb.array(
                model.mappings.map((mapping) => this.buildMapping(mapping)),
            ),
            [MappingsControlKeys.Type]: model.authProviderType,
            [MappingsControlKeys.CustomGroups]: [model.customGroups],
        });
    }

    async toFormModel(authProviderDetails: AuthProviderDetails, config: MappingConfig): Promise<AuthProviderMappingsModel> {
        const mappings = authProviderDetails?.mappings ?? [];
        const units = await this.loadUnits(mappings);
        const groups = this.getGroups(mappings);

        const sourceGroups: AuthProviderSourceGroup[] = [];
        const customGroups: AuthProviderSourceGroup[] = [];

        if (config.sourceGroups && (authProviderDetails.type !== AuthProvider.Okta || !!authProviderDetails.extras?.sswsSecret)) {
            for (const g of groups) {
                try {
                    const group = await this.ucAuthProviders.getAuthProviderGroup(authProviderDetails.id, g);

                    sourceGroups.push(group);
                } catch (e) {
                    customGroups.push({ id: g, name: g });
                }
            }
        } else {
            groups.forEach((g) => {
                customGroups.push({ id: g, name: g });
            });
        }

        const model: AuthProviderMappingsModel = {
            authProviderId: authProviderDetails.id ?? '',
            authProviderType: authProviderDetails.type,
            userFieldsMapping: authProviderDetails.userFieldsMapping ?? {},
            mappings: [],
            customGroups,
        };

        if (!config.hasManager && model.userFieldsMapping) {
            delete model.userFieldsMapping.manager;
        }

        if (model.userFieldsMapping) {
            // eslint-disable-next-line guard-for-in
            for (const key in model.userFieldsMapping) {
                const item = model.userFieldsMapping[key] as FieldMappingModel;

                item.display = item.label ? `${item.source} (${item.label})` : item.source;
            }
        }

        let cachedClaims: UcClaimConfig[] = [];

        if (config.sourceClaims) {
            cachedClaims = await this.ucUserClaims.list({ params: { limit: 1000 } });
        }

        model.mappings = mappings.map((mapping) => {
            const condition = this.toConditionModel(model.authProviderId, sourceGroups, mapping.condition);
            const actions = this.buildActionModel(mapping.actions, units, cachedClaims);

            return {
                id: model.authProviderId,
                condition, actions,
            };
        });

        return model;
    }

    toFormData(model: AuthProviderMappingsModel): AuthProviderMappings {
        const mappings = this.mappingsModelToData(model);

        return { mappings, userFieldsMapping: model.userFieldsMapping };
    }

    buildMapping(mapping: AuthProviderMappingModel) {
        return this.ufb.group({
            [MappingsControlKeys.Condition]: this.buildConditions(mapping.condition),
            [MappingsControlKeys.Actions]: this.buildActions(mapping.actions),
            [MappingsControlKeys.Id]: mapping.id,
        });
    }

    buildConditions(condition?: AuthProviderMappingConditionFormModel[]): UfControlArray {
        return this.ufb.array((condition ?? []).map((c) => this.buildCondition(c)));
    }

    buildCondition(condition?: AuthProviderMappingConditionFormModel): UfControlGroup {
        const group = this.ufb.group({
            [MappingsControlKeys.Type]: condition?.type,
            [MappingsControlKeys.Id]: [{ value: condition?.id ?? generateUUID(), disabled: true }],
        });

        switch (condition?.type) {
            case AuthProviderMappingConditionType.RoleAssignment:
            case AuthProviderMappingConditionType.ClaimFrom:
                group.addControl(MappingsControlKeys.Identifier, this.ufb.control(condition.identifier, ValidatorFunctions.required('Field required')));
                break;
            case AuthProviderMappingConditionType.ClaimValue:
                group.addControl(MappingsControlKeys.Identifier, this.ufb.control(condition.identifier, ValidatorFunctions.required('Field required')));
                group.addControl(MappingsControlKeys.Value, this.ufb.control(condition.value));
                break;
            case AuthProviderMappingConditionType.GroupMembership:
                // group.addControl(MappingsControlKeys.Identifier, this.ufb.control(condition.identifier, ValidatorFunctions.required('Identifier required')));
                group.addControl(MappingsControlKeys.Group, this.ufb.control(condition.group, ValidatorFunctions.required('Field required')));
                break;
            default:
                group.addControl(MappingsControlKeys.Children, this.ufb.array((condition?.children ?? []).map((children) => this.buildCondition(children)),
                    ValidatorFunctions.minLength(2, 'And or Or combinations need at least two conditions'),
                ));
        }

        return group;
    }

    buildActions(actions: AuthProviderMappingActionModel[]): UfControlArray {
        return this.ufb.array(actions.map((action) => this.buildAction(action)),
            ValidatorFunctions.minLength(1, 'At least one action'),
        );
    }

    buildAction(action: AuthProviderMappingActionModel): UfControlGroup {
        const group = this.ufb.group({ [MappingsControlKeys.Type]: action.type });

        switch (action.type) {
            case AuthProviderMappingActionType.AssignUnit:
                group.addControl(MappingsControlKeys.Units, this.ufb.control(action.units, ValidatorFunctions.required('Field required')));
                break;
            case AuthProviderMappingActionType.AssignClaim:
                group.addControl(MappingsControlKeys.Identifier, this.ufb.control(action.identifier));
                group.addControl(MappingsControlKeys.Value, this.ufb.control(action.value, ValidatorFunctions.required('Field required')));
                group.addControl(MappingsControlKeys.Claim, this.ufb.control(action.claim, ValidatorFunctions.required('Field required')));
                break;
            case AuthProviderMappingActionType.AssignClaimFrom:
                group.addControl(MappingsControlKeys.Identifier, this.ufb.control(action.identifier));
                group.addControl(MappingsControlKeys.Claim, this.ufb.control(action.claim, ValidatorFunctions.required('Field required')));
                group.addControl(MappingsControlKeys.ClaimTo, this.ufb.control(action.claimTo, ValidatorFunctions.required('Field required')));
                group.addControl(MappingsControlKeys.Value, this.ufb.control(action.value));
                break;
            case AuthProviderMappingActionType.AssignSystemRole:
                group.addControl(MappingsControlKeys.Roles, this.ufb.control(action.roles, ValidatorFunctions.required('Field required')));
                break;
            case AuthProviderMappingActionType.AssignRole:
                group.addControl(MappingsControlKeys.Roles, this.ufb.control(action.roles, ValidatorFunctions.required('Field required')));
                break;

        }

        return group;
    }

    buildFilter(filter: AuthProviderMappingFilter): UfControlGroup {
        return this.ufb.group({
            [FilterControlKeys.AuthProviderId]: filter.authProviderId,
            [FilterControlKeys.Group]: [filter.group],
            [FilterControlKeys.ConditionClaimIdentifier]: [filter.conditionClaimIdentifier],
            [FilterControlKeys.ConditionClaimValue]: filter.conditionClaimValue,
            [FilterControlKeys.RolesAssigned]: filter.rolesAssigned,
            [FilterControlKeys.HierarchyUnit]: filter.hierarchyUnit,
            [FilterControlKeys.ActionClaimValue]: filter.actionClaimValue,
            [FilterControlKeys.ActionClaimIdentifier]: filter.actionClaimIdentifier,
            [FilterControlKeys.ActionClaimFrom]: filter.actionClaimFrom,
            [FilterControlKeys.ActionClaimTo]: filter.actionClaimTo,
            [FilterControlKeys.ActionRoles]: filter.actionRoles,
            [FilterControlKeys.ActionSystemRoles]: filter.actionSystemRoles,
        });
    }

    private mappingsModelToData(model: AuthProviderMappingsModel): AuthProviderMapping[] {
        return model.mappings.map((mapping) => {
            let condition;

            if (mapping.condition.length > 1) {
                condition = this.toConditionData({
                    type: AuthProviderMappingConditionType.Or,
                    children: mapping.condition,
                });
            } else if (mapping.condition.length === 1 && hasLengthAtLeast(mapping.condition, 1)) {
                condition = this.toConditionData(mapping.condition[0]);
            }
            const mappingData = {
                condition,
                actions: this.buildActionsData(mapping.actions),
            };

            return mappingData as AuthProviderMapping;
        });
    }

    private toConditionModel(authProviderId: string, sourceGroups: AuthProviderSourceGroup[], condition?: AuthProviderMappingCondition): AuthProviderMappingConditionFormModel[] {
        if (!condition) {
            return [];
        }

        // it means that the TOP level is an OR - so we won't show it
        if (condition.children?.length && condition.type === AuthProviderMappingConditionType.Or) {
            return condition.children.map((children) => this.buildConditionModel(children, authProviderId, sourceGroups));
        }

        return [this.buildConditionModel(condition, authProviderId, sourceGroups)];
    }

    private buildConditionModel(condition: AuthProviderMappingCondition, authProviderId: string, sourceGroups: AuthProviderSourceGroup[]): AuthProviderMappingConditionFormModel {
        const model: AuthProviderMappingConditionFormModel = {
            type: condition.type,
            identifier: condition.identifier,
            value: condition.value,
            children: [],
            id: generateUUID(),
        };

        switch (condition.type) {
            case AuthProviderMappingConditionType.GroupMembership:
                if (condition.identifier) {
                    model.group = sourceGroups.find((g) => g.id === condition.identifier) ?? { id: condition.identifier, name: condition.identifier };
                }
                break;
            case AuthProviderMappingConditionType.ClaimValue:
                if (model.value == null) {
                    model.type = AuthProviderMappingConditionType.ClaimFrom;
                }
                break;
            default:
                // And or Or
                model.children = (condition.children ?? []).map((children) => this.buildConditionModel(children, authProviderId, sourceGroups));
        }

        return model;
    }

    private buildActionModel(actions: AuthProviderMappingAction[], units: HierarchyUnitWithPath[], userClaims: UcClaimConfig[]) {
        const actionsModel: AuthProviderMappingActionModel[] = [];
        const claimActions: Dictionary<ClaimActionModel> = {};
        const otherActions: AuthProviderMappingAction[] = [];

        const roleAction = {
            roles: [] as string[],
            type: AuthProviderMappingActionType.AssignRole,
            identifier: '',
        };

        const systemRoleAction = {
            roles: [] as string[],
            type: AuthProviderMappingActionType.AssignSystemRole,
            identifier: '',
        };

        const unitAction = {
            units: [] as HierarchyUnitWithPath[],
            type: AuthProviderMappingActionType.AssignUnit,
            identifier: '',
        };

        const contentParser = new ContentParser();

        for (const action of actions) {
            switch (action.type) {
                case AuthProviderMappingActionType.AssignClaim: {
                    const claim = userClaims.find((c) => c.type === action.identifier);

                    if (claim && [FieldType.TextArray, FieldType.MultiChoice].includes(claim.valueType)) {
                        if (claimActions[claim.type]) {
                            claimActions[claim.type]?.actions.push(action);
                        } else {
                            claimActions[claim.type] = {
                                claim: { ...claim, label: 'Value' },
                                actions: [action],
                            };
                        }
                    } else {
                        otherActions.push({ ...action, value: contentParser.parse(action.value, { type: claim?.valueType ?? FieldType.Text }) as string });
                    }
                    break;
                }
                case AuthProviderMappingActionType.AssignRole:
                    roleAction.roles.push(action.identifier);
                    break;
                case AuthProviderMappingActionType.AssignSystemRole:
                    systemRoleAction.roles.push(action.identifier);
                    break;
                case AuthProviderMappingActionType.AssignUnit: {
                    const foundUnit = units.find((u) => u.id === action.identifier);

                    if (foundUnit) {
                        unitAction.units.push(foundUnit);
                    }
                    break;
                }
                default:
                    otherActions.push(action);
            }
        }

        const claimActionsModel = this.buildClaimActionModel(claimActions);

        if (claimActionsModel.length > 0) {
            actionsModel.push(...claimActionsModel);
        }

        if (roleAction.roles.length > 0) {
            actionsModel.push(roleAction);
        }

        if (systemRoleAction.roles.length > 0) {
            actionsModel.push(systemRoleAction);
        }

        if (unitAction.units.length > 0) {
            actionsModel.push(unitAction);
        }

        const otherActionsModel = otherActions.map((action) => this.toActionModel(action, userClaims));

        actionsModel.push(...otherActionsModel);

        return actionsModel;
    }

    private toActionModel(action: AuthProviderMappingAction, cachedClaims: UcClaimConfig[]): AuthProviderMappingActionModel {

        const model: AuthProviderMappingActionModel = {
            type: action.type,
            identifier: action.identifier,
            value: action.value, // for AssignClaim type of action
        };

        switch (action.type) {
            case AuthProviderMappingActionType.AssignUnit:
                break;
            case AuthProviderMappingActionType.AssignClaim:
                model.claim = cachedClaims.find((c) => c.type === action.identifier) ?? {
                    id: '',
                    label: 'Value',
                    type: action.identifier ?? '',
                    valueType: FieldType.Text,
                };
                break;
            case AuthProviderMappingActionType.AssignClaimFrom:
                model.claimTo = cachedClaims.find((c) => c.type === action.identifier) ?? {
                    id: '',
                    label: action.identifier ?? '',
                    type: action.identifier ?? '',
                    valueType: FieldType.Text,
                };
                model.claim = cachedClaims.find((c) => c.type === action.value) ?? {
                    id: '',
                    label: action.value ?? '',
                    type: action.value ?? '',
                    valueType: FieldType.Text,
                };
        }

        return model;
    }

    private loadUnits(mappings: AuthProviderMapping[]) {
        const allUnits = mappings.reduce((ids: string[], mapping: AuthProviderMapping) => {
            mapping.actions.forEach((action) => {
                if (action.type === AuthProviderMappingActionType.AssignUnit) {
                    ids.push(action.identifier);
                }
            });

            return ids;
        }, []);
        const distinctUnits = new Set(allUnits);

        return this.hierarchyProvider.getUnits(Array.from(distinctUnits));
    }

    private toConditionData(condition: AuthProviderMappingConditionFormModel): AuthProviderMappingCondition {
        const data: AuthProviderMappingCondition = {
            type: condition.type,
            children: (condition.children ?? []).map((c) => this.toConditionData(c)),
            identifier: condition.identifier,
            value: condition.value,
        };

        if (condition.type === AuthProviderMappingConditionType.GroupMembership && condition.group) {
            data.identifier = condition.group.id;
        } else if (condition.type === AuthProviderMappingConditionType.ClaimFrom) {
            data.type = AuthProviderMappingConditionType.ClaimValue;
        }

        return data;
    }

    private buildActionsData(actions: AuthProviderMappingActionModel[]): AuthProviderMappingAction[] {
        const actionsData: AuthProviderMappingAction[] = [];

        for (const action of actions) {
            switch (action.type) {
                case AuthProviderMappingActionType.AssignRole:
                case AuthProviderMappingActionType.AssignSystemRole:
                    for (const identifier of action.roles ?? []) {
                        actionsData.push(this.toActionData({ ...action, identifier }));
                    }
                    break;
                case AuthProviderMappingActionType.AssignUnit:
                    for (const unit of action.units ?? []) {
                        actionsData.push(this.toActionData({ ...action, identifier: unit.id }));
                    }
                    break;
                case AuthProviderMappingActionType.AssignClaim:
                    if (action.claim && [FieldType.TextArray, FieldType.MultiChoice].includes(action.claim.valueType)) {
                        const values = FieldType.MultiChoice === action.claim.valueType ? new Set(action.value ?? []) : action.value;

                        for (const value of (values ?? [])) {
                            actionsData.push(this.toActionData({ ...action, value }));
                        }
                    } else {
                        actionsData.push(this.toActionData(action));
                    }
                    break;
                default:
                    actionsData.push(this.toActionData(action));
            }
        }

        return actionsData;
    }

    private toActionData(action: AuthProviderMappingActionModel): AuthProviderMappingAction {
        const value = action.value != null ? action.value + '' : undefined; // casting it to string
        const data: AuthProviderMappingAction = {
            value,
            type: action.type,
            identifier: action.identifier,
        };

        switch (action.type) {
            case AuthProviderMappingActionType.AssignClaimFrom:
                data.value = action.claim?.type ?? '';
                data.identifier = action.claimTo?.type ?? '';
                break;
            case AuthProviderMappingActionType.AssignClaim:
                data.identifier = action.claim?.type ?? '';
                break;
        }

        return data;
    }

    private buildUserMappingControls(config: MappingConfig, userMappings?: FieldMappingData): UfControlGroup {
        const group = this.ufb.group({});

        if (!userMappings) {
            return group;
        }
        const requiredValidation = ValidatorFunctions.required('Field required');
        const emailValidation = ValidatorFunctions.compose([
            requiredValidation,
            ValidatorFunctions.custom((v) => !config.isUserEmailRequired || v?.source !== IgnoreFieldMappingValue, `Email can't be ignored`),
        ]);

        // eslint-disable-next-line guard-for-in
        for (const key in userMappings) {
            group.addControl(key, this.ufb.control(userMappings[key], key === EmailFieldMapping ? emailValidation : requiredValidation));
        }

        group.addValidators(
            ValidatorFunctions.custom((v) => Object.keys(v).every((key) => v[key] != null), 'There are required fields missing'),
        );

        if (config.isUserEmailRequired) {
            group.addValidators(
                ValidatorFunctions.custom((v) => v.email?.source !== IgnoreFieldMappingValue, 'Required, please complete'),
            );
        }

        return group;
    }

    private getGroups(mappings: AuthProviderMapping[]): string[] {
        const claims = new Set();
        const groups = new Set();

        for (const mapping of mappings) {
            for (const action of mapping.actions) {
                if ([AuthProviderMappingActionType.AssignClaim, AuthProviderMappingActionType.AssignClaimFrom].includes(action.type)) {
                    claims.add(action.identifier);
                }
            }

            if (mapping.condition) {
                for (const condition of this.conditionIterator([mapping.condition])) {
                    if (condition.type === AuthProviderMappingConditionType.ClaimValue) {
                        claims.add(condition.identifier);
                    }

                    if (condition.type === AuthProviderMappingConditionType.GroupMembership) {
                        groups.add(condition.identifier);
                    }
                }
            }
        }

        return Array.from(groups) as string[];
    }

    private *conditionIterator(conditions: AuthProviderMappingCondition[]): Iterable<AuthProviderMappingCondition> {

        for (const condition of conditions) {
            yield condition;

            if (condition.children) {
                yield *this.conditionIterator(condition.children);
            }
        }
    }

    private buildClaimActionModel(claimActions: Dictionary<ClaimActionModel>): AuthProviderMappingActionModel[] {
        const actionModels: AuthProviderMappingActionModel[] = [];

        const keys = Object.keys(claimActions);

        for (const key of keys) {
            const claimAction = claimActions[key];
            let value: string[];

            if (claimAction?.claim.valueType === FieldType.MultiChoice) {
                value = Array.from(new Set(claimAction.actions.map((action) => action.value).filter(isNotNull)));
            } else {
                value = claimAction?.actions.map((action) => action.value).filter(isNotNull) ?? [];
            }

            if (claimAction?.actions.length) {
                actionModels.push({
                    identifier: key,
                    claim: claimAction.claim,
                    type: AuthProviderMappingActionType.AssignClaim,
                    value,
                });
            }
        }

        return actionModels;
    }

}
