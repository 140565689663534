import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DataLoaderFactory, DataPropertyDescriptor, UfControlArray, UfControlGroup } from '@unifii/library/common';
import { AstNode, NodeType, generateUUID } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { DialogsService } from 'services/dialogs.service';

import { FilterEditorFormCtrl, FilterEditorNode } from './filter-editor-form-ctrl';

@Component({
    selector: 'uc-filter-editor',
    templateUrl: './filter-editor.html',
    providers: [FilterEditorFormCtrl, DataLoaderFactory],
})
export class FilterEditorComponent implements OnInit, OnDestroy {

    @Input() heading: string;
    @Input() parentControl: UfControlGroup;
    @Input() filter: AstNode | undefined;
    @Input() dataProperties: DataPropertyDescriptor[] = [];

    @Output() filterChange = new EventEmitter<AstNode | undefined>();

    control: UfControlArray;
    invalid: boolean;

    private readonly controlUUID = `FilterEditorComponent-${generateUUID()}`;
    private readonly emptyFilter = { type: NodeType.Combinator, op: 'and', args: [] };
    private controlChangesSub?: Subscription;

    constructor(
        private fb: FilterEditorFormCtrl,
        private dialogs: DialogsService,
    ) { }

    async ngOnInit() {

        // Normalize filter input
        const valorizedFilter = this.filter ?? JSON.parse(JSON.stringify(this.emptyFilter)) as AstNode;

        // Verify validity
        if (!this.fb.isValid(valorizedFilter)) {
            this.invalid = true;

            return;
        }

        await this.applyFilter(valorizedFilter, true);
    }

    ngOnDestroy() {
        this.parentControl?.removeControl(this.controlUUID);
        this.controlChangesSub?.unsubscribe();
    }

    reset() {
        this.parentControl?.removeControl(this.controlUUID);
        this.applyFilter(JSON.parse(JSON.stringify(this.emptyFilter)));
        this.emitChange();
    }

    async add() {
        const node = await this.fb.mapAstNodeToFilterNode({ type: NodeType.Operator }, this.dataProperties);

        this.control.push(this.fb.buildNodeControl(node));
    }

    async remove(i: number) {
        if (!await this.dialogs.confirmDelete()) {
            return;
        }
        this.control.removeAt(i);
    }

    private async applyFilter(filter: AstNode, init = false) {

        this.controlChangesSub?.unsubscribe();

        const nodes = await this.fb.mapFilterToFilterNodes(filter, this.dataProperties);

        this.control = this.fb.buildRootControl(nodes);
        this.parentControl?.addControl(this.controlUUID, this.control, { emitEvent: !init });

        this.controlChangesSub = this.control.valueChanges.subscribe(() => {
            this.emitChange();
        });

        this.invalid = false;
    }

    private emitChange() {

        if (this.control.invalid) {
            return;
        }

        if (this.control.length === 0) {
            this.filterChange.emit();
        }

        const filter = this.fb.mapFilterNodesToFilter(this.control.getRawValue() as FilterEditorNode[]);

        this.filterChange.emit(filter);
    }

}
