import { Inject, Injectable, Injector, OnDestroy, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableInputManager, TableInputs, TableManagerFunctions } from '@unifii/components';
import { CellDisplayDescriptor, FilterEntries, FilterEntry, FilterValue, HierarchyUnitProvider, TableConfig } from '@unifii/library/common';
import { CellTemplateType, DefinitionPublishState, Dictionary, MessageColour } from '@unifii/sdk';
import { Subject, Subscription } from 'rxjs';

import { DefinitionInfo, SystemRole } from 'client';
import { TABLE_SEARCH_MIN_LENGTH } from 'constant';
import { ContextService } from 'services/context.service';
import { LimitService } from 'services/limit.service';
import { InfoTableDataSource } from 'services/table/info-table-data-source';
import { Info, InfoLoader, InfoType, UcTableManager } from 'services/table/models';

import { InfoActionFactory } from './info-action-factory';
import { InfoColumnFactory } from './info-column-factory';

@Injectable()
export class InfoTableManager implements UcTableManager<Info>, OnDestroy {

    tableConfig: TableConfig<Info>;
    showSearch = true;
    searchMinLength = TABLE_SEARCH_MIN_LENGTH;
    defaultSort: string;
    customColumns: CellDisplayDescriptor[] = [{
        name: 'publishState',
        variations: [
            {
                condition: `$self === '${DefinitionPublishState.Published}'`,
                template: {
                    type: CellTemplateType.Lozenge,
                    colour: MessageColour.Success,
                },
            },
            {
                condition: `$self === '${DefinitionPublishState.Approved}'`,
                template: {
                    type: CellTemplateType.Lozenge,
                    colour: MessageColour.Warning,
                },
            },
            {
                condition: `$self === '${DefinitionPublishState.Draft}'`,
                template: {
                    type: CellTemplateType.Lozenge,
                    colour: MessageColour.Info,
                },
            },
        ],
        defaultTemplate: {
            type: CellTemplateType.Lozenge,
        },
    }];

    reload = new Subject<void>();
    update = new Subject<TableInputs<FilterValue>>();
    updateItem = new Subject<Info | { item: Info; trackBy: keyof Info }>();

    inputManager: TableInputManager<FilterValue, FilterEntry>;

    private items: Info[] = [];
    private connection: Subscription | null;

    constructor(
        injector: Injector,
        @Inject(InfoLoader) private loader: InfoLoader,
        private limitService: LimitService,
        private infoColumnFactory: InfoColumnFactory,
        private route: ActivatedRoute,
        private router: Router,
        private context: ContextService,
        @Inject(FilterEntries) entries: FilterEntry[],
    ) {
        this.inputManager = new TableInputManager(entries, inject(HierarchyUnitProvider), null, null);
        this.defaultSort = this.getDefaultSort(loader.type);

        const infoActionFactory = new InfoActionFactory(this, injector);

        const id = `${loader.type}_info_table`;
        const columns = this.infoColumnFactory.create(loader.type);
        const tableConfig = TableManagerFunctions.createTableConfig(columns, id);

        tableConfig.actions = infoActionFactory.create();
        tableConfig.selectable = true;
        tableConfig.row = { link: (item: Info) => this.loader.type === InfoType.Collection ? [(item as DefinitionInfo).identifier] : item.id };

        this.tableConfig = tableConfig;
    }

    get addActionConfig(): boolean {
        switch (this.loader.type) {
            case InfoType.Collection:
                return this.context.checkRoles(SystemRole.ProjectManager);
            case InfoType.Page:
            case InfoType.View:
                return this.context.checkRoles(SystemRole.ContentEditor);
            case InfoType.Form:
                return this.context.checkRoles(SystemRole.FormDesigner);
            default: return true;
        }
    }

    ngOnDestroy() {
        this.connection?.unsubscribe();
    }

    createDataSource(inputs: TableInputs<FilterValue>) {
        let params: Dictionary<any> | undefined;

        if (inputs != null) {
            params = this.inputManager.serializeInputs(inputs);
        }

        const dataSource = new InfoTableDataSource(this.loader, params);

        this.connection?.unsubscribe();

        this.items = [];
        this.connection = dataSource.connect().subscribe((items) => this.items.push(...(items?.data ?? [])));

        return dataSource;
    }

    // TODO remove string when possible - check comment on CompoundInfo interface
    getNextItem(id?: string | number): Info | undefined {
        const itemIndex = this.items.findIndex((item) => item.id === id);

        if (itemIndex < 0) {
            return;
        }

        return this.items[itemIndex + 1];
    }

    addActionCallback = async() => {
        const canAdd = await this.canAdd();

        if (!canAdd) {
            return;
        }
        const route = ['./new'];

        if (this.loader.type === InfoType.Collection) {
            route.push('definition');
        }

        this.router.navigate(route, { relativeTo: this.route });
    };

    private getDefaultSort(type: InfoType): string {
        switch (type) {
            case InfoType.Form: return 'name';
            case InfoType.Table: return 'title';
            default: return '_title';
        }
    }

    private canAdd(): Promise<boolean> {
        switch (this.loader.type) {
            case InfoType.Form: return this.limitService.canAddForms();
            case InfoType.Page: return this.limitService.canAddPages();
            case InfoType.View: return this.limitService.canAddViews();
            default: return Promise.resolve(true);
        }
    }

}
