<uf-expander class="uf-box flat gap-sm-bottom">
    <div expanderHeader class="uf-app-bar flat">
        <div class="title primary">Tables</div>
        <uf-icon *ngIf="control.invalid" name="error" class="error" />
        <uc-expander-controls *ngIf="control.length" [container]="tablesWrap" />
    </div>

    <div #tablesWrap expanderBody class="pad small">
        <uf-spinner *ngIf="!tables" />
        <ng-template [ngIf]="tables">
            <span *ngIf="!tables.length" class="not-published">
                Please define Tables before adding them to a Dashboard
            </span>

            <ng-template [ngIf]="tables.length">

                <uf-search-result *ngIf="!control.disabled" [options]="searchResults" [nameProperty]="mapDescription"
                    (valueChange)="add($event)" (searchChange)="search($event)" label="Tables" placeholder="Search Tables"
                    class="col-12 pad-bottom" />

                <uc-drag-list *ngIf="control.length" [items]="control.controls" canDrop="false" canReorder="true">

                    <ng-template [ngForOf]="control.controls | asUfControlsGroups" ngFor let-table let-i="index">
                        <uf-expander [dragDisabled]="table.disabled" dragItem
                            class="uf-box flat draggable gap-sm-bottom">
                            <div expanderHeader dragHandle class="uf-app-bar flat">
                                <div class="title primary">
                                    {{getLabel(table)}}
                                </div>
                                <uf-icon *ngIf="table.invalid" name="error" class="error" />
                                <a *ngIf="getTableLink(table)" [routerLink]="getTableLink(table)" target="_blank"
                                    class="uf-action tertiary" title="Open">
                                    <uf-icon name="open" />
                                </a>
                                <button *ngIf="!table.disabled" (click)="remove(i)" type="button" title="Delete"
                                    class="uf-action tertiary">
                                    <uf-icon name="delete" />
                                </button>
                            </div>
                            <div [formGroup]="table" expanderBody class="uf-grid pad">
                                <ng-template [ngIf]="findTableDefinition(table)" [ngIfElse]="tableNotFound">
                                    <uf-description-list [items]="getTableDescription(table)" class="col-12" />
                                    <uf-number [formControlName]="formKeys.PageSize" label="Rows" precision="0"
                                        class="col-12" />
                                </ng-template>
                                <ng-template #tableNotFound>
                                    <uf-message [content]="'Table \''+getTableIdentifier(table) + '\' not found'"
                                        class="error col-12" />
                                </ng-template>

                            </div>
                        </uf-expander>
                    </ng-template>

                </uc-drag-list>

            </ng-template>
        </ng-template>
    </div>
</uf-expander>
