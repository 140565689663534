<div class="pad-sides uf-grid">

    <div class="col-6">
        <label class="x-small">Show on</label>
        <div class="row gap-sm-top">
            <uf-checkbox *ngIf="control.controls.showOnMobile" [control]="control.controls.showOnMobile | asUfControl"
                label="Mobile" class="grow" />
            <uf-checkbox *ngIf="control.controls.showOnDesktop" [control]="control.controls.showOnDesktop | asUfControl"
                label="Desktop" class="grow" />
        </div>
    </div>
    <div class="col-6 gap-top uf-grid center-all">
        <ng-template [ngIf]="!isCustomColumn">
            <a (click)="copyIdentifier()" title="Copy" class="uf-action tertiary">
                <uf-icon name="copy" />
            </a>
            <uf-description-list [items]="[{term: 'Identifier', description: column.identifier}]" class="col-10" />
        </ng-template>
    </div>

    <uf-text *ngIf="headingControl.enabled" [control]="headingControl" label="Column Heading" class="col-6" />
    <uf-text *ngIf="defaultCellValueControl.enabled" [control]="defaultCellValueControl" label="Default Cell Value"
        class="col-6" />
    <uf-select *ngIf="control.controls.defaultTemplate" [control]="control.controls.defaultTemplate | asUfControl"
        [options]="templateOptions" label="Cell Template" nameProperty="_display" valueProperty="_id" class="col-6" />

    <ng-template [ngIf]="!!control.get('defaultTemplate')?.value">
        <uf-select *ngIf="control.controls.colour" [control]="control.controls.colour | asUfControl"
            [options]="colourOptions" label="Colour" nameProperty="_display" valueProperty="_id"
            placeholder="Select template" class="col-6" />
    </ng-template>

    <uf-autocomplete *ngIf="formatOptions && control.controls.defaultFormat"
        [control]="control.controls.defaultFormat | asUfControl" [allowCustom]="true" [options]="formatOptions"
        [placeholder]="formatPlaceholder" label="Format" class="col-6" />

    <uf-textarea *ngIf="itemTemplateControl.enabled" [control]="itemTemplateControl" label="Item Template"
        class="col-12" />

    <div class="col-12">
        <uc-drag-list *ngIf="control.controls.variations"
            [items]="(control.controls.variations | asUfControlArray).controls" (moved)="emit()" canDrop="false"
            canReorder="true">
            <ng-container
                *ngFor="let control of (control.controls.variations | asUfControlArray).controls | asUfControlsGroups; let i = index">
                <uf-expander [isExpanded]="true" dragItem class="uf-box draggable flat gap-sm-top">
                    <div expanderHeader dragHandle class="uf-app-bar">
                        <div class="title primary">
                            <div class="ellipsis">{{control.get('condition')?.value}}</div>
                        </div>
                        <div>
                            <button (click)="copyVariation(i)" type="button" title="Copy" class="uf-action tertiary">
                                <uf-icon name="copy" />
                            </button>
                        </div>
                        <div>
                            <button (click)="removeVariation(i)" type="button" title="Delete"
                                class="uf-action tertiary right">
                                <uf-icon name="delete" />
                            </button>
                        </div>
                    </div>
                    <ng-container expanderBody>
                        <div class="grow uf-grid pad">
                            <uf-textarea [control]="control.controls.condition | asUfControl"
                                *ngIf="control.controls.condition" label="Condition" class="col-12" />
                            <uf-text *ngIf="control.controls.value" [control]="control.controls.value | asUfControl"
                                label="Value" class="col-12" />
                            <uf-select *ngIf="control.controls.template" [options]="templateOptions"
                                [control]="control.controls.template | asUfControl" label="Template"
                                nameProperty="_display" valueProperty="_id" class="col-6" />
                            <uf-select [control]="control.controls.colour | asUfControl"
                                *ngIf="!!control.controls.template?.value && control.controls.colour"
                                [options]="colourOptions" label="Colour" placeholder="Select colour"
                                nameProperty="_display" valueProperty="_id" class="col-6" />
                        </div>
                    </ng-container>
                </uf-expander>
            </ng-container>
        </uc-drag-list>
    </div>

    <div class="col-12 pad-bottom space-children row">
        <button (click)="pasteVariation()" type="button" class="uf-button x-small right">
            Paste Variation
        </button>
        <button (click)="addVariation()" type="button" class="uf-button x-small">
            Add Variation
        </button>
    </div>
</div>