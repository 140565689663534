import { Component, Input, OnInit, inject } from '@angular/core';
import { DescriptionListItem, UfControl, UfControlGroup } from '@unifii/library/common';
import { DataSeed, Manager, UserInfo } from '@unifii/sdk';
import { UserKeys } from '@unifii/user-provisioning';

import { UcUserInfo, UcUsers } from 'client';

@Component({
    selector: 'uc-user-manager',
    templateUrl: 'user-manager.html',
})

export class UserManagerComponent implements OnInit {

    @Input({ required: true }) form: UfControlGroup;
    @Input() cssClasses: string | string[] | undefined;

    protected managerControl: UfControl;
    protected managerInfo: DescriptionListItem[] | undefined;
    protected managerOptions: DataSeed[] = [];

    private userLookup = new Map<DataSeed, UcUserInfo>();

    private ucUsers = inject(UcUsers);

    ngOnInit() {
        this.managerControl = this.form.get(UserKeys.Manager) as UfControl;

        const user = this.form.getRawValue() as UcUserInfo;

        if (user.manager) {
            this.managerInfo = this.buildManagerInfo(user.manager);
        }
    }

    protected selectManager(seed?: DataSeed) {
        let user: UcUserInfo | undefined;

        this.managerInfo = undefined;

        if (seed != null) {
            user = this.userLookup.get(seed);
            this.managerInfo = this.buildManagerInfo(user);
        }

        this.managerControl.setValue(user);
    }

    protected async searchUsers(q: string) {
        try {
            const users = await this.ucUsers.get(q.trim());

            this.managerOptions = (users ?? [])
                .filter((user) => user.id != null)
                .map(this.mapUserDataSeed.bind(this));
        } catch (e) {
            console.error('User Manager Component - selecting manager method', e);
        }
    }

    private buildManagerInfo(manager?: UcUserInfo | Manager): DescriptionListItem[] | undefined {
        if (manager == null) {
            return;
        }

        return [
            { term: 'Username', description: { description: manager.username, routerLink: ['/', 'user-management', 'users', `${manager.id}`] } },
            { term: 'First Name', description: { description: manager.firstName } },
            { term: 'Last Name', description: { description: manager.lastName } },
            { term: 'Email', description: { description: manager.email, href: `mailto:${manager.email}` } },
            { term: 'Phone', description: { description: manager.phone, href: `tel:${manager.phone}` } },
        ].filter((descriptionItem) => !!descriptionItem.description.description);
    }

    private mapUserDataSeed(user: UserInfo) {
        const seed: DataSeed = {
            _display: `${user.firstName} ${user.lastName} (${user.username})`,
            _id: user.id as string,
        };

        if (!this.userLookup.get(seed)) {
            this.userLookup.set(seed, user);
        }

        return seed;
    }

}
