<uf-expander *ngIf="field && compound && translation" class="uc-group col-1of1">
    <div expanderHeader class="uf-app-bar flat">
        <div class="title">
            {{label}} ({{field.identifier}})
        </div>
        <button *ngIf="translatable" (click)="toggle()" class="uf-action tertiary" type="button" title="Enable">
            <uf-icon name="{{enabled ? 'checkboxTick' : 'checkbox'}}" />
        </button>
        <button *ngIf="translatable" (click)="copy()" class="uf-action tertiary" type="button" title="Copy">
            <uf-icon name="copy" />
        </button>
        <button *ngIf="translatable" [disabled]="!enabled" (click)="clear()" type="button" class="uf-action tertiary"
            title="Clear">
            <uf-icon name="backspace" />
        </button>
    </div>
    <div expanderBody class="row">
        <div class="uc-group-content grow grid--no-padding">
            <uf-field [field]="field" [content]="compound[$any(field.identifier)]" [control]="disabledControl"
                class="col-1of1" />
        </div>
        <div [ngClass]="{'inactive': !enabled, 'grid--no-padding': enabled }" class="uc-group-content grow">
            <uf-field *ngIf="enabled" [field]="field" [(content)]="translation[$any(field.identifier)]"
                class="col-1of1" />
            <span *ngIf="!translatable">No translation required</span>
            <span *ngIf="translatable && !enabled">Using source</span>
        </div>
    </div>
</uf-expander>