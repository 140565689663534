<div class="uf-box flat row">
    <div class="uf-grid grow pad-top pad-left pad-bottom">
        <uf-text *ngIf="control.controls.label" [control]="control.controls.label | asUfControl" label="Label"
            class="col-6" />
        <uf-autocomplete *ngIf="control.controls.form" [control]="control.controls.form | asUfControl"
            [options]="forms" (searchChange)="filterForms($event)" (valueChange)="formChange($event)"
            label="Form" nameProperty="_display" class="col-6" />
        <uf-select *ngIf="control.controls.transition" [control]="control.controls.transition | asUfControl"
            [options]="transitions" [nameProperty]="transitionName" label="Transition" placeholder="Select transition"
            class="col-12" />
        <div *ngIf="$any(control.controls.fieldMappings).controls.length" class="col-12">
            <h4>Data Mapping</h4>
            <table class="uf-table inputs accent">
                <thead>
                    <tr>
                        <th>Field in Primary Form</th>
                        <th>Field in Triggered Form</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let c of $any(control.controls.fieldMappings).controls; index as i">
                        <td class="input-cell">
                            <uf-autocomplete
                                [options]="parentFieldOptions"
                                [control]="c.controls.parentField" (searchChange)="filterParentFormFields($event, c.controls.childField.value)" class="table-cell"
                                nameProperty="optionLabel" />
                        </td>
                        <td class="input-cell">
                            <uf-autocomplete
                                [options]="childFieldOptions"
                                [control]="c.controls.childField" (searchChange)="filterChildFormFields($event, c.controls.parentField.value)" class="table-cell"
                                nameProperty="optionLabel" />
                        </td>
                        <td class="input-cell">
                            <button (click)="$any(control.controls.fieldMappings).removeAt(i)" type="button"
                                class="uf-action tertiary right gap-sm-right">
                                <uf-icon name="delete" />
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <uf-message *ngIf="dataMappingDisabledWarning" [content]="dataMappingDisabledWarning" class="col-12 warning"
            icon="warning" icon="warning" />
        <div class="col-12">
            <button (click)="addMappingsControl()" type="button" class="uf-button right">
                Add Data
            </button>
        </div>
    </div>
    <a (click)="delete()" class="uf-action tertiary gap-sm-top gap-sm-right">
        <uf-icon name="delete" />
    </a>
</div>