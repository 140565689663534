import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { ModalService, RuntimeDefinition, RuntimeDefinitionAdapter, ToastService } from '@unifii/library/common';
import { ComponentRegistry, FormSettings } from '@unifii/library/smart-forms';
import { CompoundType, Definition, UfError } from '@unifii/sdk';

import { CompoundInfo, UcCompound, UcLanguage, UcProject } from 'client';
import { ContentSettings, SaveAndClose, SaveAndNext, SaveOption, useDefaultErrorMessage } from 'components';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { BuilderBasic } from 'components/compound-builder/builder-basic';
import { BuilderCompoundSubjects } from 'components/compound-builder/builder-models';
import { BuilderEventInfo, BuilderService } from 'components/compound-builder/builder.service';
import { ContentEditorRegistry } from 'components/content-editor-registry';
import { LanguageViewsComponent } from 'pages/translations/language-views.component';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';

import { LanguageViewsTableManager } from './language-views-table-manager';

@Component({
    templateUrl: './language-view.html',
    styleUrls: ['./../../styles/pages/builder.less'],
    providers: [
        BuilderService,
        { provide: FormSettings, useValue: {} },
        { provide: ComponentRegistry, useClass: ContentEditorRegistry },
        { provide: ContentSettings, useValue: { mode: 'DataEntry' } },
    ],
})
export class LanguageViewComponent extends BuilderBasic implements OnInit, OnDestroy {

    type = CompoundType.View;
    subject = BuilderCompoundSubjects.TRANSLATION;

    protected ready: boolean;
    protected compound: UcCompound;
    protected translation: UcCompound;
    protected showUntranslated: boolean;
    protected error?: UfError;
    protected definition?: RuntimeDefinition;

    constructor(
        builderService: BuilderService,
        modalService: ModalService,
        protected parent: LanguageViewsComponent,
        protected override router: Router,
        protected override route: ActivatedRoute,
        @Inject(TableContainerManager) protected override tableManager: LanguageViewsTableManager,
        private ucProject: UcProject,
        private ucLanguage: UcLanguage,
        private toastService: ToastService,
        private context: ContextService,
        private breadcrumbService: BreadcrumbService,
        private builderHeaderService: BuilderHeaderService,
        private runtimeDefinitionAdapter: RuntimeDefinitionAdapter,
    ) {
        super(builderService, modalService, route, tableManager);
    }

    private get id(): number {
        return +this.route.snapshot.params.id;
    }

    async ngOnInit() {
        // Fix save options
        this.saveOptions = [SaveAndClose, SaveAndNext];

        try {
            // Load View
            const data = await this.ucProject.getView(this.id);
            const definition = data._definition as Definition;

            this.compound = data;

            try {
            // Load View Translation
            const translationRaw = await this.ucLanguage.getView(this.id);

            this.translation = this.builderService.filterDefinedFields(definition.fields, translationRaw);
            } catch (e) {
                this.translation = {};
            }

            this.definition = await this.runtimeDefinitionAdapter.transform(definition);

            // Register event handlers
            this.addSubscribers();

            // Init builder service
            this.builderService.init(this, definition, this.translation);

            this.builderHeaderService.init();
            this.subscriptions.add(this.builderHeaderService.saveClicked.subscribe((saveOption) => this.save(saveOption)));
            this.buildHeaderConfig(this.compound);
        } catch (e) {
            this.translation = {};
            this.error = useDefaultErrorMessage(e);

            return;
        }
    }

    private addSubscribers() {
        this.subscriptions.add(this.builderService.ready.subscribe(() => {
            this.saveStatus();
            this.builderService.memento.edited = false;
            this.ready = true;
        }));

        this.subscriptions.add(this.builderService.fieldEdit.subscribe((i) => {
            this.saveStatus(i);
            this.builderService.fieldEdited.next(i);
        }));
    }

    private async save(saveOption?: SaveOption) {
        try {
            this.builderService.busy.next(true);
            this.translation = await this.ucLanguage.saveView(this.id, this.translation);
            this.toastService.success('Translation saved');
            /**
             * The deal with the misaligned interfaces between the table and language component
             * (CompoundInfo and UcCompound) the table item has to be updated with
             * Find the source item from the table manager and merge with updated compound,
             */
            const item = this.tableManager.items.find((i) => +i.id === this.id) as CompoundInfo;
            const updated = Object.assign({}, item, this.translation);

            this.saved(updated, saveOption);
            this.builderService.init(this, this.definition, this.translation);
            this.buildHeaderConfig(updated);
        } finally {
            this.builderService.busy.next(false);
        }
    }

    private saveStatus(i: BuilderEventInfo = { subject: null, atomic: true }) {
        this.builderService.memento.save(this.translation, i.atomic);
        this.builderService.memento.edited = true;

        if (this.ready) {
            this.builderHeaderService.config.edited = true;
        }
    }

    private buildHeaderConfig(translation: UcCompound) {
        const currentTranslationName = [this.context.project?.name, this.context.language?.name].filter((s) => s != null).join(' ');

        this.builderHeaderService.buildConfig({
            ...translation,
            title: translation._title,
            publishState: this.builderService.compound?.publishState || translation.publishState,
            saveOptions: this.saveOptions,
            cancelRoute: ['../'],
            restrictSave: 'Translator',
            breadcrumbs: this.breadcrumbService.getBreadcrumbs(this.route, [currentTranslationName, translation._title]),
        });
    }

}
