import { AuthProvider } from '@unifii/sdk';

export const getAuthProviderUrlSegment = (type: AuthProvider): string => {
    switch (type) {
        case AuthProvider.Auth0: return 'auth0';
        case AuthProvider.Azure: return 'azure';
        case AuthProvider.Okta: return 'okta';
        case AuthProvider.UnifiiIdentity: return 'unifii-identity';
    }
};

export const parseAuthProviderUrlSegment = (urlSegment: string) => {
    switch (urlSegment) {
        case 'auth0': return AuthProvider.Auth0;
        case 'azure': return AuthProvider.Azure;
        case 'okta': return AuthProvider.Okta;
        case 'unifii-identity': return AuthProvider.UnifiiIdentity;
        default: throw new Error(`parseAuthProviderUrlSegment: urlSegment ${urlSegment} not found`);
    }
};

export const getAuthProviderImagePath = (type: AuthProvider): string => {
    const basePath = 'assets/svg';

    switch (type) {
        case AuthProvider.Auth0: return `${basePath}/auth0.svg`;
        case AuthProvider.Azure: return `${basePath}/azure.svg`;
        case AuthProvider.Okta: return `${basePath}/okta.svg`;
        case AuthProvider.UnifiiIdentity: return `${basePath}/unifii-identity.svg`;
    }
};

export const getAuthProviderLabel = (type: AuthProvider): string => {
    switch (type) {
        case AuthProvider.Auth0: return 'Auth0';
        case AuthProvider.Azure: return 'Azure';
        case AuthProvider.Okta: return 'Okta';
        case AuthProvider.UnifiiIdentity: return 'Unifii Identity';
    }
};
