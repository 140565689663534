import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Language, UfError } from '@unifii/sdk';

import { UcCollection, UcCollectionTranslation, UcDefinition, UcProject } from 'client';
import { useDefaultErrorMessage } from 'components';
import { ContextService } from 'services/context.service';

export interface LanguageCollectionData {
    definition: UcDefinition;
    error?: UfError;
    ucCollection: UcCollection;
    ucCollectionTranslation: UcCollectionTranslation;
}

export const languageCollectionResolver = async(route: ActivatedRouteSnapshot) => {
    const ucProject = inject(UcProject);
    const context = inject(ContextService);
    const collectionId = route.params.collectionId;
    const ucCollection = ucProject.collection(collectionId);
    const ucCollectionTranslation = ucCollection.translation((context.language as Language).code);

    try {
        const definition = await ucCollection.getDefinition();

        return { definition, ucCollection, ucCollectionTranslation };
    } catch (e) {
        const error = useDefaultErrorMessage(e);

        return { error };
    }
};
