import { Component, HostBinding, Inject } from '@angular/core';
import { DescriptionListItem, Modal, ModalData, ModalRuntime } from '@unifii/library/common';
import { CompoundType } from '@unifii/sdk';

export interface FileConflictError {
  links: FileConflictLink[];
  projectName: string;
  projectId: number;
}

interface FileConflictLink {
  id: string | number;
  name: string;
  compoundType: CompoundType;
  title?: string;
}
@Component({
  selector: 'uc-file-conflict-modal',
  templateUrl: './file-conflict-modal.html',
})
export class FileConflictModalComponent implements Modal<FileConflictError[], void> {

  @HostBinding('class.uf-form-card') classes = true;

  content: {
    name: string;
    data: DescriptionListItem[];
  }[];

  constructor(
    public runtime: ModalRuntime<FileConflictError[], void>,
    @Inject(ModalData) public data: FileConflictError[],
  ) {
    this.content = this.data.map((error) => ({
      name: error.projectName,
      data: error.links
        .filter((link) => link != null)
        .sort((link1, link2) => link1.compoundType > link2.compoundType ? 1 : -1)
        .map((link) => ({
          term: link.compoundType === 'Collection' ? `${link.name} Collection` : link.compoundType,
          description: link.compoundType === 'Collection' ? link.title : `${link.title} (${link.name})`,
        })),
    }));
  }

  close() {
    this.runtime.close();
  }

}
