import { Injectable } from '@angular/core';
import { Language } from '@unifii/sdk';

import { MyAccount, TenantSettings, UcProjectInfo } from 'client';

/**
 * Do NOT put random stuff on this service. This should be your very last resort.
 * I know it's tempting, but you might as well declare a global variable.
 *
 * Please think twice before introducing extra values here.
 */
@Injectable({ providedIn: 'root' })
export class ContextService {

    tenantSettings: TenantSettings | null;
    account: MyAccount | null;
    project: UcProjectInfo | null;
    bucketId: string | undefined;
    language: Language | null;

    checkRoles(...roles: string[]): boolean {
        return !!this.account?.roles.some((r) => roles.includes(r));
    }

    clear() {
        this.project = null;
        this.account = null;
        this.bucketId = undefined;
    }

}
