import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DescriptionListItem, ModalService, UfControl, UfControlArray, UfControlGroup, UfFormControl } from '@unifii/library/common';
import { isNotNull } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { UcDefinition } from 'client';
import { ArrayHelper } from 'helpers/helpers';

import { FORM_EDITOR_CONSTANTS } from './form-editor-constants';
import { DefinitionControlKeys, DefinitionSettingsControlKeys, FieldControlKeys } from './form-editor-control-keys';
import { FormEditorField } from './form-editor-model';
import { FormEditorStatus } from './form-editor-status';
import { FormEditorService } from './form-editor.service';
import { FormMetadataModalComponent } from './form-metadata-modal.component';

@Component({
    selector: 'uc-form-metadata',
    templateUrl: './form-metadata.html',
})
export class FormMetadataComponent implements OnInit, OnDestroy {

    @Input({ required: true }) control: UfControlGroup;

    protected readonly definitionKeys = DefinitionControlKeys;
    protected readonly settingsKeys = DefinitionSettingsControlKeys;
    protected readonly identifierMaxLength = this.status.identifiersMaxLength.definition;
    protected readonly reportableMetaFields = FORM_EDITOR_CONSTANTS.REPORTABLE_METADATA_FIELDS;

    protected reportableMetaFieldsResults: string[];
    protected tagsResults: string[];
    protected formDataRepositoryInfo: DescriptionListItem[] | null;
    protected showIdentifierWarning: boolean;
    protected showBucketWarning: boolean;

    private formControls: UfFormControl[];
    private formDataRepositoryControls: UfFormControl[];
    private identifierSub: Subscription | undefined;

    constructor(
        private status: FormEditorStatus,
        private service: FormEditorService,
        private modalService: ModalService,
    ) { }

    protected get isFormInvalid(): boolean {
        return this.formControls.find((c) => c.invalid) != null;
    }

    protected get isFormDataRepositoryInvalid(): boolean {
        return this.formDataRepositoryControls.find((c) => c.invalid) != null;
    }

    protected get fieldsControl(): UfControlArray {
        return this.control.get(FieldControlKeys.Fields) as UfControlArray;
    }

    protected get descriptionControl(): UfControl {
        return this.control.get(DefinitionControlKeys.Description) as UfControl;
    }

    private get bucketControl(): UfControl {
        return this.control.get(DefinitionControlKeys.Bucket) as UfControl;
    }

    private get identifierControl(): UfControl {
        return this.control.get(DefinitionControlKeys.Identifier) as UfControl;
    }

    ngOnInit() {
        this.formControls = FORM_EDITOR_CONSTANTS.SECTION_FORM
            .map((k) => this.control.get(k) as UfFormControl | null)
            .filter(isNotNull);

        this.formDataRepositoryControls = FORM_EDITOR_CONSTANTS.SECTION_FORM_DATA_REPOSITORY
            .map((k) => this.control.get(k) as UfFormControl | null)
            .filter(isNotNull);

        this.identifierSub = this.identifierControl.valueChanges.subscribe(this.checkIdentifierWarning.bind(this));
        this.checkIdentifierWarning();
        this.checkBucketWarning();
        this.updateFormDataRepositoryInfo();
    }

    ngOnDestroy() {
        this.identifierSub?.unsubscribe();
    }

    protected searchReportableMetaFields(query: string) {

        if (!query) {
            this.reportableMetaFieldsResults = [...this.reportableMetaFields];

            return;
        }

        this.reportableMetaFieldsResults = this.reportableMetaFields.filter((i) =>
            i.toLocaleLowerCase().includes(query.toLowerCase()),
        );
    }

    protected searchTags(query: string | null) {
        this.tagsResults = ArrayHelper.filterList([...this.status.tags], query ?? undefined);
    }

    protected copyFields() {
        void this.service.copyFields(this.fieldsControl.getRawValue() as FormEditorField[]);
    }

    protected pasteFields() {
        void this.service.pasteFields(this.control);
    }

    protected async editFormDataRepository() {

        const partialDefinition = {
            lastPublishedAt: this.control.get(DefinitionControlKeys.LastPublishedAt)?.value,
            bucket: this.control.get(DefinitionControlKeys.Bucket)?.value,
            hasRollingVersion: this.control.get(DefinitionControlKeys.HasRollingVersion)?.value,
        } as any as UcDefinition;

        const result = await this.modalService.openMedium(FormMetadataModalComponent, { definition: partialDefinition });

        if (!result) {
            return;
        }

        this.control.get(DefinitionControlKeys.Bucket)?.setValue(result.bucket);
        this.control.get(DefinitionControlKeys.HasRollingVersion)?.setValue(result.hasRollingVersion);
        this.updateFormDataRepositoryInfo();
        this.checkBucketWarning();
    }

    private updateFormDataRepositoryInfo() {
        const hasRollingVersion: boolean = this.control.get(DefinitionControlKeys.HasRollingVersion)?.value ?? false;

        this.formDataRepositoryInfo = [];
        this.formDataRepositoryInfo.push({ term: 'Form Data Repository', description: this.control.get(DefinitionControlKeys.Bucket)?.value });
        this.formDataRepositoryInfo.push({ term: 'Has Rolling Version', description: hasRollingVersion ? 'true' : 'false' });
    }

    private checkIdentifierWarning() {
        this.showIdentifierWarning = (this.identifierControl.value ?? '').length > FORM_EDITOR_CONSTANTS.FIELD_IDENTIFIER_WARNING_LENGTH &&
            !this.identifierControl.showError;
    }

    private checkBucketWarning() {
        this.showBucketWarning = (this.bucketControl.value ?? '').length > FORM_EDITOR_CONSTANTS.FIELD_IDENTIFIER_WARNING_LENGTH &&
            !this.bucketControl.showError;
    }

}
