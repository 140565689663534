<div [formGroup]="form" class="uf-box">
    <div class="uf-app-bar flat accent">
        <div class="title">
            Details
        </div>
    </div>
    <div class="uf-grid pad-sides pad-top">
        <uf-message *ngIf="error" [content]="error.message" icon="errorSolid" class="error col-12" />
        <uf-user-description *ngIf="!isNew" [userInfo]="user" [showOnly]="userDescriptionFields" class="col-12" />

        <uf-text *ngIf="usernameControl && usernameControl.enabled" [label]="commonTK.UsernameLabel | translate"
            [control]="usernameControl" class="col-12 col-md-6">
            <span *ngIf="usernameRequired" class="suffix">*</span>
        </uf-text>
        <uf-text *ngIf="firstNameControl && firstNameControl.enabled" [label]="commonTK.FirstNameLabel | translate"
            [control]="firstNameControl" class="col-12 col-md-6">
            <span *ngIf="firstNameRequired" class="suffix">*</span>
        </uf-text>
        <uf-text *ngIf="lastNameControl && lastNameControl.enabled" [label]="commonTK.LastNameLabel | translate"
            [control]="lastNameControl" class="col-12 col-md-6">
            <span *ngIf="lastNameRequired" class="suffix">*</span>
        </uf-text>
        <uf-text *ngIf="emailControl && emailControl.enabled" [label]="commonTK.EmailLabel | translate"
            [control]="emailControl" class="col-12 col-md-6">
            <span *ngIf="emailRequired" class="suffix">*</span>
        </uf-text>
        <uf-text *ngIf="phoneControl && phoneControl.enabled" [label]="commonTK.PhoneLabel | translate"
            [control]="phoneControl" class="col-12 col-md-6">
            <span *ngIf="phoneRequired" class="suffix">*</span>
        </uf-text>
        <uf-company-input *ngIf="companyControl && companyControl.enabled" [control]="companyControl"
            [label]="companyLabel" class="col-12 col-md-6" />
    </div>

    <uf-user-description *ngIf="!isNew && claimDescriptionFields.length && claimsControl?.disabled" [userInfo]="user"
        [showOnly]="claimDescriptionFields" class="pad border-bottom" />

    <uf-claim-editor *ngIf="claimsControl && claimsControl?.enabled" [control]="claimsControl"
        [width]="fieldWidth.Half" />

    <div class="uf-grid pad-bottom">
        <uf-password-change *ngIf="passwordControl" [user]="user" [control]="form" [hideToggle]="isNew"
            class="col-12" />

        <uf-status-change *ngIf="lastActivationReasonControl && isActiveControl"
            [lastActivationReasonControl]="lastActivationReasonControl" [isActiveControl]="isActiveControl"
            [user]="user" class="col-12" />
    </div>
</div>