import { Injectable, Optional } from '@angular/core';
import { CellDisplayDescriptor, FromNowPipe, TableAction, TableConfigColumn, TableRowContext, ToastService } from '@unifii/library/common';
import { CellTemplateType, CompoundType, DefinitionPublishState, MessageColour } from '@unifii/sdk';
import { Subject } from 'rxjs';

import { CompoundInfo, SystemRole, UcLanguage } from 'client';
import { TranslationStateHelper } from 'helpers/translation-states-helper';
import { ContextService } from 'services/context.service';

import { LanguageCollectionStore } from './language-collection-store';

@Injectable()
export class LanguageColumnFactory {

    constructor(
        private fromNowPipe: FromNowPipe,
        private toastService: ToastService,
        private context: ContextService,
        private ucLanguage: UcLanguage,
        @Optional() private store?: LanguageCollectionStore,
    ) { }

    create(): TableConfigColumn<CompoundInfo>[] {
        return [{
            name: 'name',
            label: 'Name',
            sortable: true,
        }, {
            name: 'sourcePublishState',
            label: 'Source State',
        }, {
            name: 'lastModifiedAt',
            label: 'Last Modified',
            sortable: true,
            value: (item) => this.fromNowPipe.transform(item.lastModifiedAt),
        }, {
            name: 'lastPublishedAt',
            label: 'Last Published',
            sortable: true,
            value: (item) => this.fromNowPipe.transform(item.lastPublishedAt),
        }, {
            name: 'publishState',
            label: 'Translation State',
        }];
    }

    createActions(type: CompoundType, callback: Subject<void>): TableAction<CompoundInfo>[] {
        return [{
            label: 'Approve',
            action: (rows) => {
                const items = (rows as TableRowContext<CompoundInfo>[]).map((row) => row.$implicit);

                this.approve(items, type, callback);
            },
            predicate: (row) => this.context.checkRoles(SystemRole.Translator) && TranslationStateHelper.canApprove(row.$implicit),
        },
        {
            label: 'Unapprove',
            action: (rows) => {
                const items = (rows as TableRowContext<CompoundInfo>[]).map((row) => row.$implicit);

                this.unapprove(items, type, callback);
            },
            predicate: (row) => this.context.checkRoles(SystemRole.Translator) && TranslationStateHelper.canUnapprove(row.$implicit),
        }];

    }

    createCustom(): CellDisplayDescriptor[] {
        return [{
            name: 'sourcePublishState',
            variations: [{
                condition: `sourcePublishState === '${DefinitionPublishState.Published}'`,
                template: {
                    type: CellTemplateType.Lozenge,
                    colour: MessageColour.Success,
                },
            }, {
                condition: `sourcePublishState === '${DefinitionPublishState.Approved}'`,
                template: {
                    type: CellTemplateType.Lozenge,
                    colour: MessageColour.Warning,
                },
            }],
            defaultTemplate: {
                type: CellTemplateType.Lozenge,
            },
        }, {
            name: 'publishState',
            variations: [{
                condition: `publishState === '${DefinitionPublishState.Published}'`,
                template: {
                    type: CellTemplateType.Lozenge,
                    colour: MessageColour.Success,
                },
            }, {
                condition: `publishState === '${DefinitionPublishState.Approved}'`,
                template: {
                    type: CellTemplateType.Lozenge,
                    colour: MessageColour.Warning,
                },
            }, {
                condition: `publishState === '${DefinitionPublishState.Draft}'`,
                template: {
                    type: CellTemplateType.Lozenge,
                    colour: MessageColour.Info,
                },
            }, {
                condition: `publishState == null`,
                template: {
                    type: CellTemplateType.Lozenge,
                },
                value: 'Not Translated',
            }],
            defaultTemplate: {
                type: CellTemplateType.Lozenge,
            },
        }];
    }

    private async approve(items: CompoundInfo[], type: CompoundType, callback: Subject<void>) {
        const label = type === CompoundType.View ? `View/s` : `Collection/s`;

        try {
            for (const { id } of items) {
                if (type === CompoundType.View) {
                    await this.ucLanguage.approveView(+id);
                } else {
                    await this.store?.ucCollectionTranslation.approve(+id);
                }
            }
            this.toastService.success(`${label} approved`);
        } catch (e) {
            this.toastService.error('Failed to approve');
        } finally {
            callback.next();
        }
    }

    private async unapprove(items: CompoundInfo[], type: CompoundType, callback: Subject<void>) {
        const label = type === CompoundType.View ? `View/s` : `Collection/s`;

        try {
            for (const { id } of items) {
                if (type === CompoundType.View) {
                    await this.ucLanguage.unapproveView(+id);
                } else {
                    await this.store?.ucCollectionTranslation.unapprove(+id);
                }
            }
            this.toastService.success(`${label} unapproved`);
        } catch (e) {
            this.toastService.error('Failed to unapprove');
        } finally {
            callback.next();
        }
    }

}
