<uf-form #formComponent *ngIf="definition" [config]="{hideLabel: true}" [definition]="definition"
    [(formData)]="integration.config" (edited)="edited = $event">
    <div header class="pad gap-bottom">
        <div class="uf-box">
            <div class="uf-app-bar flat accent medium">
                <div class="title small">Integration Configuration</div>
            </div>
            <div class="content uf-grid pad condensed">
                <uf-description-list class="col-10 small-label">
                    <dt>Provider Name</dt>
                    <dd>{{ integration.provider.name }}</dd>
                </uf-description-list>
                <div class="col-2 row">
                    <img *ngIf="integration.provider?.logo" [src]="integration.provider.logo | bypassSanitizeUrl"
                        class="intergration-logo right" />
                    <img *ngIf="!integration.provider?.logo" src="/assets/svg/icon-integrations.svg"
                        class="intergration-logo right" />
                </div>
                <uf-text [control]="nameControl" [(value)]="integration.name" label="Integration Name" class="col-12" />
                <div class="col-12 pad-top">
                    <button (click)="testConnection()" type="button" class="uf-button right">
                        Test Connection
                    </button>
                </div>
            </div>
        </div>
    </div>
</uf-form>