import { Component, HostBinding } from '@angular/core';
import { ChartConfiguration } from 'chart.js';

import { ProjectTranslations, UcClient } from 'client';

interface ProjectTranslationsChart extends ProjectTranslations {
    charts: ChartConfiguration[];
}

@Component({
    templateUrl: './translations-overview.html',
    styleUrls: ['./translations-overview.less'],
})
export class TranslationsOverviewComponent {

    @HostBinding('class.stretch-component') class = true;

    loading: boolean;
    projects: ProjectTranslationsChart[] = [];

    constructor(
        private client: UcClient,
    ) {
        this.init();
    }

    getProjectTranslationChart(pt: ProjectTranslations): ProjectTranslationsChart {

        const charts = pt.languages.map((language) => {

            const notTranslated = language.total - (language.approved + language.draft);
            const labels: string[] = [];
            const data: number[] = [];
            const backgroundColor: string[] = [];

            if (notTranslated) {
                labels.push('Not Translated');
                data.push(notTranslated);
                backgroundColor.push('#d0d0d0');
            }
            if (language.draft) {
                labels.push('Draft');
                data.push(language.draft);
                backgroundColor.push('#325786');
            }
            if (language.approved) {
                labels.push('Approved');
                data.push(language.approved);
                backgroundColor.push('#017E3C');
            }

            const chart: ChartConfiguration = {
                type: 'pie',
                data: {
                    labels,
                    datasets: [{
                        label: 'Set',
                        data,
                        backgroundColor,
                    }],
                },
                options: {
                    aspectRatio: 1.5,
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                },
            };

            return chart;
        });

        return Object.assign(pt, { charts });
    }

    private async init() {
        this.loading = true;

        const translations = await this.client.getProjectsTranslations();

        this.projects = translations
            .filter((t) => t.languages.length)
            .map((t) => this.getProjectTranslationChart(t));

        this.loading = false;
    }

}
