import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UfError } from '@unifii/sdk';

import { UcCollection, UcCollectionTranslation, UcDefinition } from 'client';

import { LanguageCollectionData } from './language-collection-resolver';

@Injectable()
export class LanguageCollectionStore {

    definition: UcDefinition;
    error?: UfError;
    ucCollection: UcCollection;
    ucCollectionTranslation: UcCollectionTranslation;

    constructor(
        route: ActivatedRoute,
    ) {
        const { definition, ucCollection, ucCollectionTranslation, error } = route.snapshot.data.collectionData as LanguageCollectionData;

        this.definition = definition;
        this.error = error;
        this.ucCollection = ucCollection;
        this.ucCollectionTranslation = ucCollectionTranslation;
    }

}
