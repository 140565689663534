"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUserStatusInfoFlags = exports.getUserStatus = void 0;
const client_types_1 = require("../client-types");
exports.getUserStatus = ((userInfo) => {
    if (!userInfo) {
        return undefined;
    }
    if (userInfo.isActive) {
        return client_types_1.UserStatus.Active;
    }
    return userInfo.lastActivationReason == null ? client_types_1.UserStatus.Pending : client_types_1.UserStatus.Inactive;
});
const getUserStatusInfoFlags = (status) => {
    switch (status) {
        case client_types_1.UserStatus.Active:
            return { isActive: true };
        case client_types_1.UserStatus.Pending:
            return { isActive: false };
        case client_types_1.UserStatus.Inactive:
            return { isActive: false, lastActivationReason: '' };
    }
};
exports.getUserStatusInfoFlags = getUserStatusInfoFlags;
