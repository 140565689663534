<div uc-header-warning class="header-pane uf-app-bar transparent">
    <uf-breadcrumbs [breadcrumbs]="config.breadcrumbs" class="large">
        <span *ngIf="edited">*</span>
    </uf-breadcrumbs>
    <ng-content select="[header]" />

    <uf-dl class="x-small small-label row-gap-sm left gap-sm-right desktop-only">
        <ng-container *ngIf="config.lastModifiedAt">
            <dt>Last Modified:</dt>
            <dd class="value">{{lastModified}}</dd>
        </ng-container>
        <ng-container *ngIf="config.lastPublishedAt">
            <dt>Last Published:</dt>
            <dd class="value">{{lastPublished}}
            </dd>
        </ng-container>
    </uf-dl>

    <button *ngIf="showInfo" (click)="openInfoModal()" type="button" class="uf-action small mobile-only">
        <uf-icon name="info" />
    </button>

    <div *ngIf="publishState" [ngClass]="publishStateClassDictionary[publishState]"
        class="uf-lozenge state-lozenge gap-sm-left x-small-md">
        {{publishState}}
    </div>

    <div class="row header-buttons right">
        <a *ngIf="config.cancelRoute" (click)="cancel()" class="uf-button small tertiary">Cancel</a>

        <ng-content select="[header-buttons]" />

        <ng-container *ngIf="templateRef">
            <ng-container *ngTemplateOutlet="templateRef" />
        </ng-container>

        <button *ngIf="!showSaveOptions && !config.hideSaveButton" [disabled]="!!config.disabledSave || builderHeaderService.busy"
            [restrict]="config.restrictSave" (click)="onSave()"
            type="button" class="uf-button primary small">Save</button>
        <uc-save-options *ngIf="showSaveOptions" [restrict]="config.restrictSave" [options]="saveOptions"
            [disabled]="!!config.disabledSave || builderHeaderService.busy" (onSave)="onSave($event)" />

    </div>
</div>