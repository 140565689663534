<div class="uf-app-bar">
    <button (click)="close()" title="Close" type="button" class="uf-action tertiary">
        <uf-icon name="close" />
    </button>
    <h3>Data Mapping</h3>
</div>
<div [formGroup]="formGroup" class="uf-grid pad gaps">

    <uf-key-value *ngIf="sourceField" class="col-12">
        <dt>{{sourceFieldLabel}}:</dt>
        <dd class="value">
            <uf-icon [name]="getFieldTypeIcon(sourceField.type)" />
            {{ namePropertyFunc(sourceField) }}
        </dd>
    </uf-key-value>

    <uf-key-value *ngIf="targetField" class="col-12">
        <dt>{{targetFieldLabel}}:</dt>
        <dd class="value">
            <uf-icon [name]="getFieldTypeIcon(targetField.type)" />
            {{ namePropertyFunc(targetField) }}
        </dd>
    </uf-key-value>

    <uf-checkbox *ngIf="sourceExpressionKey && formGroup.get(sourceExpressionKey)" [(value)]="useSourceExpression"
        (valueChange)="sourceChange($event)" label="Use Source Expression" class="col-12" />

    <ng-template [ngIf]="!sourceField">
        <uf-text *ngIf="sourceExpressionKey && useSourceExpression" [formControlName]="sourceExpressionKey"
            [label]="sourceExpressionLabel" class="col-12" />

        <uf-autocomplete *ngIf="!useSourceExpression" [formControlName]="sourceKey"
            [options]="sourceFields" [label]="sourceFieldLabel" [nameProperty]="namePropertyFunc"
            (searchChange)="sourceSearch($event)" class="col-12" />
    </ng-template>

    <uf-checkbox *ngIf="targetExpressionKey && formGroup.get(targetExpressionKey)" [(value)]="useTargetExpression"
        (valueChange)="targetChange($event)" label="Use Target Expression" class="col-12" />

    <ng-template [ngIf]="!targetField">
        <uf-text *ngIf="targetExpressionKey && useTargetExpression" [formControlName]="targetExpressionKey"
            [label]="targetExpressionLabel" class="col-12" />

        <uf-autocomplete *ngIf="!useTargetExpression" [formControlName]="targetKey"
            [options]="targetFields" [label]="targetFieldLabel" [nameProperty]="namePropertyFunc"
            (searchChange)="targetSearch($event)" class="col-12" />
    </ng-template>

</div>
<div class="row space-children pad">
    <button (click)="close()" type="button" class="uf-button tertiary right">Cancel</button>
    <button (click)="submit()" type="button" class="uf-button primary">Save</button>
</div>