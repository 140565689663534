import { Component, Input, TemplateRef, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Breadcrumb, FromNowPipe, ModalService } from '@unifii/library/common';
import { DefinitionPublishState, UserReference } from '@unifii/sdk';

import { SaveOption } from 'components/common/save-options/save-options.component';
import { PUBLISH_STATE_CLASS_DICTIONARY } from 'helpers/css-class-helper';

import { BuilderHeaderService } from './builder-header.service';
import { PublishInfoComponent } from './publish-info.component';

export interface HeaderConfig {
    breadcrumbs?: Breadcrumb[];
    saveOptions?: SaveOption[];
    cancelRoute?: any[];
    restrictSave?: string;
    edited?: boolean;
    title?: string;
    publishState?: DefinitionPublishState;
    disabledSave?: boolean;
    hideSaveButton?: boolean;
    lastModifiedAt?: string;
    lastModifiedBy?: UserReference;
    lastPublishedAt?: string;
    lastPublishedBy?: UserReference;
}

@Component({
    selector: 'uc-builder-header',
    templateUrl: './builder-header.html',
    styleUrls: ['./builder-header.less'],
})
export class BuilderHeaderComponent {

    @Input() templateRef?: TemplateRef<any>;

    readonly publishStateClassDictionary = PUBLISH_STATE_CLASS_DICTIONARY;

    protected builderHeaderService = inject(BuilderHeaderService);

    private modalService = inject(ModalService);
    private fromNowPipe = inject(FromNowPipe);
    private router = inject(Router);
    private route = inject(ActivatedRoute);

    get saveOptions() {
        return this.config.saveOptions ?? [];
    }

    get showSaveOptions() {
        return this.saveOptions.length > 0;
    }

    get config() {
        return this.builderHeaderService.config;
    }

    get showInfo(): boolean {
        return !!(this.config.publishState ?? this.config.lastModifiedAt ?? this.config.lastPublishedAt);
    }

    get publishState() {
        return this.config.publishState;
    }

    get title() {
        return this.config.title;
    }

    get lastModified(): string | null {
        return this.mapLastModifiedPublished(this.config.lastModifiedAt, this.config.lastModifiedBy);
    }

    get lastPublished(): string | null {
        return this.mapLastModifiedPublished(this.config.lastPublishedAt, this.config.lastPublishedBy);
    }

    get edited() {
        return this.config.edited;
    }

    protected onSave(saveOption?: SaveOption) {
        this.builderHeaderService.saveClicked.emit(saveOption);
    }

    protected openInfoModal() {
        this.modalService.openFit(PublishInfoComponent, {
            publishState: this.publishState,
            lastModified: this.lastModified,
            lastPublished: this.lastPublished,
            title: this.config.title,
        });
    }

    protected cancel() {
        if (this.config.cancelRoute) {
            this.router.navigate(this.config.cancelRoute, { relativeTo: this.route });
        }
    }

    private mapLastModifiedPublished(at?: string, by?: UserReference): string | null {
        if (!at) {
            return null;
        }

        const lastModifiedPublished = this.fromNowPipe.transform(at);

        if (by) {
            return `${lastModifiedPublished}, by ${by.username}`;
        }

        return lastModifiedPublished;
    }

}
