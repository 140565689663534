<uf-panel *ngIf="ready && definition && !error" class="container">

    <div class="header-pane uf-app-bar transparent">
        <uc-builder-header />
    </div>

    <uf-panel class="content-pane">
        <div class="grid--fixed">
            <ng-template [ngForOf]="definition.fields" ngFor let-field let-i="index">
                <uc-language-field [field]="field" [compound]="compound" [translation]="translation" class="col-1of1" />
            </ng-template>
        </div>
    </uf-panel>

</uf-panel>

<uc-error-message [error]="error" />