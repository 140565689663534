"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TenantClient = void 0;
const constants_1 = require("../constants");
const functions_1 = require("../functions");
/** Client for v0 APIs */
class TenantClient {
    constructor(client) {
        this.client = client;
    }
    getSettings() {
        return this.client.get(this.url(constants_1.APIPath.Settings), { anonymous: true });
    }
    getAllowedFileTypes() {
        return this.client.get(this.url(constants_1.APIPath.Settings, constants_1.APIPath.SettingsAllowedFileTypes));
    }
    getProjects() {
        return this.client.get(this.url(constants_1.APIPath.Projects));
    }
    getProject(id) {
        return this.client.get(this.url(constants_1.APIPath.Projects, id));
    }
    getOIDCState(returnUrl, config) {
        return this.client.post(this.url(constants_1.APIPath.Oidc, constants_1.APIPath.OidcStates), { body: { returnUrl, ...config }, anonymous: true });
    }
    getGeoRegions(projectId) {
        return this.client.get(this.url(constants_1.APIPath.Projects, projectId, constants_1.APIPath.GeoRegions));
    }
    getBeaconRegions(projectId) {
        return this.client.get(this.url(constants_1.APIPath.Projects, projectId, constants_1.APIPath.BeaconRegions));
    }
    getAsset(id) {
        return this.client.get(this.url(constants_1.APIPath.Assets, id));
    }
    queryAsset(query) {
        return this.client.get(this.url(constants_1.APIPath.Assets), { query: (0, functions_1.stringifyQuery)(query) });
    }
    getRoles(options) {
        return this.client.get(this.url(constants_1.APIPath.Roles), options);
    }
    getUserClaims() {
        return this.client.get(this.url(constants_1.APIPath.UserClaims));
    }
    getUserClaim(claimId) {
        return this.client.get(this.url(constants_1.APIPath.UserClaims, claimId));
    }
    url(...extra) {
        return this.client.buildUrl(...extra);
    }
}
exports.TenantClient = TenantClient;
