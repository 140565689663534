import { FilterEntry, FilterType } from '@unifii/library/common';
import { DefinitionPublishState } from '@unifii/sdk';

import { CustomChoiceFilterEntry } from 'components';

export const filterEntries: (FilterEntry | CustomChoiceFilterEntry)[] = [
    {
        label: 'Translated State',
        identifier: 'status',
        type: FilterType.Choice,
        emptyOption: { name: 'All', identifier: 'all' },
        options: [
            { name: 'Not Translated', identifier: 'Missing' },
            { name: 'Draft', identifier: DefinitionPublishState.Draft },
            { name: 'Approved', identifier: DefinitionPublishState.Approved },
            { name: 'Published', identifier: DefinitionPublishState.Published },
        ],
    },
];

