<uf-panel *ngIf="ready" class="content-pane container">
    <div class="uf-container-lg">
        <div class="uf-grid row-gap-lg gap-top">
            <!-- Type block -->
            <div class="uf-box col-12">
                <div class="uf-app-bar flat accent">
                    <div class="title">
                        Details Page
                    </div>
                </div>
                <div class="uc-group-content grid">
                    <!-- // TODO UNIFII-4422 - Remove conditional disabled -->
                    <uf-select [options]="tableDetailTypeOptions" [(value)]="tableDetailType"
                        (valueChange)="toggleTableDetailType()" label="Type" valueProperty="value" class="col-1of1" />
                    <uf-description-list *ngIf="table.sourceType === sourceTypes.Bucket" class="col-1of1">
                        <dt>Form Data Repository:</dt>
                        <dd>{{table.source}}</dd>
                    </uf-description-list>
                    <uf-text *ngIf="tableDetail && cRoot.controls.label" [control]="cRoot.controls.label | asUfControl"
                        [(value)]="tableDetail.title" label="Title" class="col-1of1" />
                    <uf-select *ngIf="tableDetail && cRoot.controls.detailTemplate"
                        [control]="cRoot.controls.detailTemplate | asUfControl" [options]="templateOptions"
                        (valueChange)="tableDetail.emptyMessage = undefined" label="Template" valueProperty="identifier"
                        class="col-1of1" />
                    <uf-text *ngIf="tableDetail && cRoot.controls.emptyMessage && cRoot.controls.emptyMessage.enabled"
                        [control]="cRoot.controls.emptyMessage | asUfControl" [(value)]="tableDetail.emptyMessage"
                        label="Empty message" class="col-1of1" />
                </div>
            </div>
            <ng-template [ngIf]="tableDetail">
                <!-- Data block -->
                <uf-expander class="uf-box col-12 gap-top-sm">
                    <div expanderHeader class="uf-app-bar flat accent">
                        <div *ngIf="table.sourceType === sourceTypes.Bucket" class="title">
                            Form Data
                        </div>
                        <div *ngIf="table.sourceType === sourceTypes.Users" class="title">
                            User Data
                        </div>
                        <div *ngIf="table.sourceType === sourceTypes.Company" class="title">
                            Company Data
                        </div>
                    </div>
                    <div expanderBody class="uf-grid pad">
                        <uc-drag-list *ngIf="fields.length" [items]="fields" (moved)="edited = true" canDrop="false"
                            canReorder="true" class="col-12">
                            <ng-template [ngForOf]="fields" let-field ngFor let-i="index">
                                <uf-expander [isExpanded]="false" dragItem class="uf-box draggable flat gap-sm-top">
                                    <div expanderHeader dragHandle class="uf-app-bar">
                                        <uf-icon [name]="field._icon" />
                                        <div class="grow title primary">
                                            {{field._label}}
                                        </div>
                                        <span *ngIf="!field._exists" class="uf-lozenge error">Missing</span>
                                        <button (click)="removeField(i)" type="button" title="Delete"
                                            class="uf-action tertiary">
                                            <uf-icon name="delete" />
                                        </button>
                                    </div>
                                    <ng-container expanderBody>
                                        <div class="pad uf-grid">
                                            <uf-text *ngIf="field.descriptor.type === 'Field'"
                                                [(value)]="field.descriptor.label" (valueChange)="onFieldChange()"
                                                label="Label" placeholder="Override default label" class="col-12" />
                                            <uf-textarea
                                                *ngIf="field._itemTemplate && field.descriptor.type === 'Field'"
                                                [(value)]="field.descriptor.itemTemplate"
                                                (valueChange)="onFieldChange()" label="Item Template" class="col-12" />
                                            <uf-text *ngIf="field.descriptor.type === 'Heading'"
                                                [(value)]="field.descriptor.value" (valueChange)="onFieldChange()"
                                                label="Value" class="col-12" />
                                        </div>
                                    </ng-container>
                                </uf-expander>
                            </ng-template>
                        </uc-drag-list>

                        <uf-search-result [options]="visibleFields" (valueChange)="addField($event.identifier)"
                            (searchChange)="filterFields($event)" label="Fields" placeholder="Search field"
                            nameProperty="display" class="col-12" />

                        <div class="col-12 gap-top">
                            <button (click)="addField()" type="button" class="uf-button right">
                                Add Heading
                            </button>
                        </div>
                    </div>
                </uf-expander>

                <div class="uf-box col-12">

                    <div class="uf-app-bar flat accent">
                        <div class="title">Modules</div>
                        <uc-expander-controls *ngIf="tableDetail.modules.length" [container]="modulesWrap" />
                    </div>

                    <div #modulesWrap class="uc-group-content pad-sides pad-bottom pad-sm-top">
                        <uc-drag-list [items]="tableDetail.modules" (moved)="moveModule($event)" canDrop="false"
                            canReorder="true">
                            <ng-template [ngForOf]="tableDetail.modules" let-module ngFor let-i="index">
                                <uf-expander
                                    [isExpanded]="!!(cModules.at(i) | asUfControlGroup).controls.expanded?.value"
                                    dragItem class="uf-box draggable flat gap-sm-top">
                                    <div expanderHeader dragHandle class="uf-app-bar">
                                        <div class="grow title primary">
                                            {{module.type}} - {{module.identifier}}
                                            <uf-icon *ngIf="cModules.at(i).invalid" title="This module is invalid"
                                                name="error" class="error" />
                                        </div>
                                        <button (click)="removeModule(i)" title="Delete" type="button"
                                            class="uf-action tertiary">
                                            <uf-icon name="delete" />
                                        </button>
                                    </div>
                                    <ng-container expanderBody>
                                        <div class="pad">
                                            <uc-table-module-table *ngIf="module.type === moduleTypes.Table"
                                                [template]="cRoot.controls.detailTemplate?.value" [module]="module"
                                                [parentControl]="cModules.at(i) | asUfControlGroup" />
                                        </div>
                                    </ng-container>
                                </uf-expander>
                            </ng-template>
                        </uc-drag-list>
                        <div class="col-12 gap-top">
                            <button (click)="addModule()" type="button" class="uf-button right">
                                Add Module
                            </button>
                        </div>
                    </div>
                </div>

            </ng-template>
        </div>
    </div>
</uf-panel>