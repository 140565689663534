import { Component, HostBinding } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { Breadcrumb, FilterComponentRegistry, FilterEntries } from '@unifii/library/common';

import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';

import { LanguageColumnFactory } from './language-column-factory';
import { filterEntries } from './language-filter-entires';
import { LanguageFilterRegistry } from './language-filter-registry';
import { LanguageViewsTableManager } from './language-views-table-manager';

@Component({
    selector: 'uc-language-views',
    template: `
        <div ufMasterDetail>
            <uf-table-container ufSyncRoute  class="accent list-md">
                <uf-breadcrumbs [breadcrumbs]="breadcrumbs" info class="large" />
            </uf-table-container>
        </div>
        <router-outlet />
    `,
    providers: [
        LanguageColumnFactory,
        { provide: FilterComponentRegistry, useClass: LanguageFilterRegistry },
        { provide: FilterEntries, useValue: filterEntries },
        { provide: TableContainerManager, useClass: LanguageViewsTableManager },
    ],
})
export class LanguageViewsComponent {

    @HostBinding('class.stretch-component') class = true;

    breadcrumbs: Breadcrumb[] = [];

    constructor(
        private context: ContextService,
        private route: ActivatedRoute,
        private breadcrumbService: BreadcrumbService,
    ) {
        const currentName = [this.context.project?.name, this.context.language?.name].filter((s) => s != null).join(' ');

        this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [currentName, undefined]);
    }

}
