import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { AuthProvider } from '@unifii/sdk';

import { AuthProviderDetails, NewAuthProviderDetails, UcAuthProviders } from 'client';

import { parseAuthProviderUrlSegment } from '../auth-provider-functions';

export interface AuthProviderResolverData {
    authProvider?: AuthProviderDetails | NewAuthProviderDetails;
    error?: string;
}

export const automaticAuthProviderCreationResolver: ResolveFn<AuthProviderResolverData> = async(route: ActivatedRouteSnapshot) => {

    const ucAuthProviders = inject(UcAuthProviders);
    const type = parseAuthProviderUrlSegment(route.params.type);

    if (type !== AuthProvider.Azure) {
        return { error: 'Invalid Authorization Provider type' };
    }

    const { tenant } = route.queryParams;

    if (!tenant) {
        return { error: 'Tenant not found' };
    }

    try {
        const authProvider = await createAutomaticAzureProvider(ucAuthProviders, tenant);

        return { authProvider };
    } catch (e) {
        return { error: `Provider with tenant - ${tenant} already exists` };
    }
};

const createAutomaticAzureProvider = (ucAuthProviders: UcAuthProviders, tenant: string) => {
    const details: NewAuthProviderDetails = {
        isActive: true,
        manual: false,
        extras: {
            manualRegistration: false,
        },
        type: AuthProvider.Azure,
        tenant,
    };

    return ucAuthProviders.save(details);
};
