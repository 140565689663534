import { Component, HostBinding } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Breadcrumb } from '@unifii/library/common';
import { UfError, isUfError } from '@unifii/sdk';

import { projectResolver } from 'resolvers/project-resolver';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';

@Component({
    templateUrl: './language-overview.html',
})
export class LanguageOverviewComponent {

    @HostBinding('class.stretch-component') class = true;

    protected error?: UfError;

    protected items: {
        label: string;
        route: any[];
        roles: string;
        image: string;
    }[];

    protected breadcrumbs: Breadcrumb[];

    constructor(
        public context: ContextService,
        private route: ActivatedRoute,
        private breadcrumbService: BreadcrumbService,
    ) {
        const resolverData = this.route.snapshot.data.project as Awaited<ReturnType<typeof projectResolver>>;
        
        if (isUfError(resolverData)) {
            this.error = resolverData;

            return;
        }
        
        if (this.route.snapshot.data.project.error) {
            this.error = this.route.snapshot.data.project.error;
        }
        
        this.items = [{
            label: 'Collections',
            route: ['collections'],
            roles: 'Translator,Publisher',
            image: '/assets/svg/new-icons/collections.svg',
        }, {
            label: 'Views',
            route: ['views'],
            roles: 'Translator,Publisher',
            image: '/assets/svg/new-icons/views.svg',
        }, /* , {
            label: 'Pages',
            route: ['pages'],
            roles: 'Translator,Publisher',
            image: '/assets/svg/icon-pages.svg'
        }*/];
        const currentName = [context.project?.name, context.language?.name].filter((s) => s != null).join(' ');

        this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [currentName]);
    }

}
