import { Component, HostBinding, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { Breadcrumb, FilterEntries } from '@unifii/library/common';

import { UcProject } from 'client';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';
import { CollectionsInfoLoader } from 'services/table/info-loaders';
import { InfoTableManager } from 'services/table/info-table-manager';
import { InfoLoader } from 'services/table/models';

const createLoader = (ucProject: UcProject) => new CollectionsInfoLoader(ucProject);

@Component({
    providers: [
        { provide: FilterEntries, useValue: [] },
        { provide: InfoLoader, useFactory: createLoader, deps: [UcProject] },
        { provide: TableContainerManager, useClass: InfoTableManager },
    ],
    template: `
    <div ufMasterDetail>
        <uf-table-container [ngClass]="{'pad-none': !inTranslations}" (addItem)="addItem()" ufSyncRoute class="accent list-md" >
            <uf-breadcrumbs *ngIf="inTranslations"  [breadcrumbs]="breadcrumbs" title class="large"/>
        </uf-table-container>
    </div>
    <router-outlet/>
`,
})
export class CollectionsComponent {

    @HostBinding('class.stretch-component') class = true;

    inTranslations = false;
    breadcrumbs: Breadcrumb[] = [];

    constructor(
        @Inject(TableContainerManager) private manager: InfoTableManager,
        private route: ActivatedRoute,
        private context: ContextService,
        private breadcrumbService: BreadcrumbService,
    ) {
        this.inTranslations = this.route.snapshot.data.inTranslations;

        const currentName = [this.context.project?.name, this.context.language?.name].filter((s) => s != null).join(' ');

        this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [currentName, undefined]);
    }

    addItem() {
        this.manager.addActionCallback();
    }

}
