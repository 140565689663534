<ng-template [ngIf]="form">

    <div class="uf-app-bar default flat transparent pad-sides">
        <uc-builder-header />
    </div>

    <div [class.can-edit]="!form.disabled" class="builder-main">

        <uf-panel *ngIf="!form.disabled" class="scrollable">
            <uc-item-picker [groups]="itemGroups" />
        </uf-panel>

        <uf-panel class="dropzone uf-box flat">
            <div #nodelist>
                <uc-node-field [control]="form" (expandAll)="toggleExpanders($event, nodelist)" class="top-node" />

                <uc-drag-list [parent]="children" [items]="children.controls" [canDrop]="service.canDrop"
                    [convertAdded]="service.addConverter" [drop]="service.drop" [entries]="children.controls"
                    [current]="status.selected" [filter]="filterStructureNodeControls" (next)="service.selectNode($event)"
                    keySelect class="pad-sm-left pad-xs-top">

                    <div *ngIf="children.length === 0" class="pad col center-all">
                        <img src="/assets/svg/icon-structure.svg" alt="" />
                        <p class="small text-center">
                            Your structure is empty, drag an item from the left to start creating it!
                        </p>
                    </div>

                    <uc-node-field *ngFor="let child of children.controls | asUfControlsGroups" [control]="child"
                        [dragDisabled]="child.disabled" [nestable]="true" dragItem />

                </uc-drag-list>
            </div>
        </uf-panel>

        <uf-panel class="scrollable">
            <uc-node-details *ngIf="status.selected" [control]="status.selected" />
        </uf-panel>

    </div>

</ng-template>