import { Component, Input, OnInit } from '@angular/core';
import { DataLoaderFactory, DataPropertyDescriptor, DataSourceLoader, UfControl, UserInfoIdentifiers } from '@unifii/library/common';
import { stringsCaseInsensitiveLocalCompare } from '@unifii/library/smart-forms';
import { DataSeed, DataSourceType, FieldType, OperatorComparison, Option } from '@unifii/sdk';

@Component({
    selector: 'uc-filter-value',
    templateUrl: './filter-value.html',
    providers: [DataLoaderFactory],
    styles: [`uf-checkbox { margin-top: 1rem; }`],
})
export class FilterValueComponent implements OnInit {

    @Input() field: DataPropertyDescriptor;
    @Input() operator: OperatorComparison;
    @Input() control: UfControl;
    @Input() cssClass: string | string[];

    protected readonly types = FieldType;

    protected dataSourceLoader: DataSourceLoader | undefined;
    protected options: DataSeed[];
    protected results: DataSeed[] = [];

    constructor(
        private dataLoaderFactory: DataLoaderFactory,
    ) { }

    ngOnInit() {

        if (this.field.sourceConfig && this.field.sourceConfig.type !== DataSourceType.Named) {
            // 1) Datasource (excluded Named that cannot be queried in Console)
            this.dataSourceLoader = this.dataLoaderFactory.create(this.field.sourceConfig);
        } else if (this.field.options) {
            // 2) Static Option[]
            this.options = this.field.options.map((o) => this.optionToSeed(o, this.field));
        }

        // UNIFII-6086 - Roles need to be sorted
        if (this.field.identifier === UserInfoIdentifiers.Roles && Array.isArray(this.control.value)) {
            this.control.setValue(this.control.value.sort(stringsCaseInsensitiveLocalCompare), { emitEvent: false });
        }
    }

    protected async query(query: string | null) {

        if (this.dataSourceLoader != null) {
            this.results = await this.dataSourceLoader.search(query ?? undefined);

            return;
        }

        if (query == null) {
            this.results = [...this.options];
            
            return;
        }

        const lowerCaseQuery = query.toLowerCase();

        this.results = this.options.filter((seed) => seed._display.toLowerCase().includes(lowerCaseQuery));
    }

    private optionToSeed(option: Option, field: DataPropertyDescriptor): DataSeed {

        if (field.type === FieldType.Bool) {
            return { _id: (option.identifier === 'true' ? true : false) as any, _display: option.name };
        }
        
        return { _id: option.identifier, _display: option.name };
    }

}
