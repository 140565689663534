import { Injectable, inject } from '@angular/core';
import { ClientDeleteOptions, ClientGetOptions, mergeParams } from '@unifii/sdk';

import { DefaultPaginationParams } from 'constant';

import { PermissionPrincipalType, UcRole } from './models';
import { UcPermissionsClient } from './permissions-client';
import { UcClient } from './uc-client';

@Injectable({ providedIn: 'root' })
export class UcRoles {

    private client = inject(UcClient);

    get(q?: string, sort?: string, options?: ClientGetOptions): Promise<UcRole[]> {
        const params = mergeParams(DefaultPaginationParams, options?.params, { q, sort });

        return this.client.get(this.url(), { ...options, params });
    }

    getDetails(id: string, options?: ClientGetOptions): Promise<UcRole> {
        return this.client.get(this.url(id), options);
    }

    save(role: UcRole): Promise<UcRole> {
        return role.id ? this.client.put(this.url(role.id), role) : this.client.post(this.url(), { body: role });
    }

    delete(id: string, options?: ClientDeleteOptions): Promise<void> {
        return this.client.delete(this.url(id), options);
    }

    permissions(id: string): UcPermissionsClient {
        return new UcPermissionsClient(this.client, PermissionPrincipalType.Role, id);
    }

    private url(...extra: string[]): string {
        const urlParts = ['roles', ...extra];

        return this.client.buildUrl(urlParts);
    }

}
