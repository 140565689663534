import { Injector, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionMultiplicity, ClipboardService, TableAction, ToastService } from '@unifii/library/common';
import { TableSourceType } from '@unifii/sdk';

import { DefinitionInfo, SystemRole, TableInfo } from 'client';
import { PublishStateHelper } from 'helpers/publish-states-helper';
import { ContextService } from 'services/context.service';
import { DialogsService } from 'services/dialogs.service';

import { InfoTableManager } from './info-table-manager';
import { Info, InfoLoader, InfoType } from './models';

export class InfoActionFactory {

    private loader: InfoLoader;
    private context: ContextService;
    private route: ActivatedRoute;
    private router: Router;
    private dialogs: DialogsService;
    private clipboard: ClipboardService;
    private toastService: ToastService;
    private ngZone: NgZone;

    constructor(
        private tableManager: InfoTableManager,
        injector: Injector,
    ) {
        this.loader = injector.get(InfoLoader);
        this.context = injector.get(ContextService);
        this.route = injector.get(ActivatedRoute);
        this.router = injector.get(Router);
        this.dialogs = injector.get(DialogsService);
        this.clipboard = injector.get(ClipboardService);
        this.toastService = injector.get(ToastService);
        this.ngZone = injector.get(NgZone);
    }

    create(): TableAction<Info>[] {
        const actions: TableAction<Info>[] = [];

        if ([InfoType.Form, InfoType.Page, InfoType.Table, InfoType.View, InfoType.CollectionData].includes(this.loader.type)) {
            actions.push(...([{
                label: 'Approve',
                predicate: (row) => this.context.checkRoles(SystemRole.Publisher) && PublishStateHelper.canApprove(row.$implicit),
                action: (rows) => this.approve(rows.map((row) => row.$implicit)),
            },
            {
                label: 'Unapprove',
                predicate: (row) => this.context.checkRoles(SystemRole.Publisher) && PublishStateHelper.canUnapprove(row.$implicit),
                action: (rows) => this.revert(rows.map((row) => row.$implicit), 'Unapprove'),
            },
            {
                label: 'Archive',
                predicate: (row) => this.context.checkRoles(SystemRole.Publisher) && PublishStateHelper.canArchive(row.$implicit),
                action: (rows) => this.archive(rows.map((row) => row.$implicit)),
            },
            {
                label: 'Unarchive',
                predicate: (row) => this.context.checkRoles(SystemRole.Publisher) && PublishStateHelper.canUnarchive(row.$implicit),
                action: (rows) => this.revert(rows.map((row) => row.$implicit), 'Unarchive'),
            },
            {
                label: 'Duplicate',
                multiplicity: ActionMultiplicity.Single,
                predicate: () => this.context.checkRoles(SystemRole.ContentEditor),
                action: (row) => this.duplicate(row.$implicit.id),
            },
            {
                label: 'Delete',
                predicate: (row) => this.context.checkRoles(SystemRole.ContentEditor, SystemRole.Publisher) && PublishStateHelper.canDelete(row.$implicit),
                action: (rows) => this.delete(rows.map((row) => row.$implicit)),
            }] as TableAction<Info>[]));
        }

        if (this.loader.type === InfoType.Table) {
            actions.push({
                label: 'Copy',
                multiplicity: ActionMultiplicity.Single,
                predicate: (row) =>
                    this.context.checkRoles(SystemRole.FormDesigner) &&
                    (row.$implicit as TableInfo).sourceType !== TableSourceType.Company,
                action: (row) => this.copy(row.$implicit),
            });
        }

        if (this.loader.type === InfoType.Collection) {
            actions.push({
                label: 'Edit Definition',
                multiplicity: ActionMultiplicity.Single,
                action: (row) => this.editCollectionDefinition(row.$implicit as DefinitionInfo),
            });
        }

        return actions;
    }

    private duplicate(id: any) {
        this.router.navigate([id, { duplicate: 'true' }], { relativeTo: this.route });
    }

    private async delete(items: Info[]) {
        if (!this.loader.delete || !items.length) {
            return;
        }

        // TODO add message from actions table class
        if (!await this.dialogs.confirmDelete()) {
            return;
        }

        try {
            for (const item of items) {
                await this.loader.delete(+item.id);
            }

            this.tableManager.reload.next();
            this.toastService.success('Delete successful');
        } catch (e) {
            console.error(e);
            this.toastService.error((e as Error).message ?? 'Delete failed');
        }
    }

    private async approve(items: Info[]) {
        if (!this.loader.approve) {
            return;
        }

        for (const item of items) {
            try {
                const modified = await this.loader.approve(+item.id);

                this.tableManager.updateItem.next(modified);
                this.toastService.success(`Approval succeeded`);
            } catch (e) {
                console.error(e);
                this.toastService.error(`Approval failed`);
            }
        }
    }

    private async revert(items: Info[], label: string) {
        if (!this.loader.revert) {
            return;
        }

        for (const item of items) {
            try {
                const modified = await this.loader.revert(+item.id);

                this.tableManager.updateItem.next(modified);
                this.toastService.success(`${label} succeeded`);
            } catch (e) {
                console.error(e);
                this.toastService.error(`${label} failed`);
            }
        }
    }

    private async archive(items: Info[]) {
        if (!this.loader.archive) {
            return;
        }

        for (const item of items) {
            try {
                const modified = await this.loader.archive(+item.id);

                this.tableManager.updateItem.next(modified);
                this.toastService.success(`Archive succeeded`);
            } catch (e) {
                console.error(e);
                this.toastService.error(`Archive failed`);
            }
        }
    }

    private async copy(item: Info) {
        if (!this.loader.get) {
            return;
        }

        const table = await this.loader.get(item.id as any as number);
        const text = JSON.stringify(table);

        this.clipboard.setText(text);
    }

    private editCollectionDefinition(item: DefinitionInfo) {
        this.ngZone.run(() =>
            this.router.navigate([item.identifier, 'definition'], { relativeTo: this.route }),
        );
    }

}
