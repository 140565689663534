<uf-expander *ngIf="control" class="uf-box flat">
    <div expanderHeader class="uf-app-bar flat">
        <div class="title primary">{{contentTypeLabel}}</div>
        <uf-icon *ngIf="unpublishedWarningMessage" [title]="unpublishedWarningMessage" name="unpublished"
            class="icon-warning" />
        <a *ngIf="canCopy" (click)="copy()" class="uf-action tertiary" title="Copy">
            <uf-icon name="copy" />
        </a>
        <a *ngIf="contentLink" [routerLink]="contentLink" target="_blank" class="uf-action tertiary" title="Open">
            <uf-icon name="open" />
        </a>
    </div>
    <div [formGroup]="control" expanderBody>
        <!-- TODO add loading skeleton -->
        <ng-container *ngIf="ready">
            <uf-blockquote *ngIf="unpublishedWarningMessage" [content]="unpublishedWarningMessage" icon="unpublished"
                class="warning" />
            <uf-description-list [items]="contentInfo" class="small-label pad" />
        </ng-container>
    </div>
</uf-expander>